import { useState } from "react";
import ReactImageZoom from "react-image-zoom";

const DelayHoverImage = ({viewImage, LargeSmallProps}) => {
  const [displayBoolean, setdisplayBoolean] = useState(false);

  setTimeout(() => {
    setdisplayBoolean(true)
  }, 3000);

  return (
    <>
      {
        displayBoolean
          ?
          <ReactImageZoom {...LargeSmallProps} />
          :
          <div style={{ position: "relative" }}>
            <img src={viewImage} alt="" />
          </div>
      }
    </>
  )
}

export default DelayHoverImage