import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import TextFieldInput from "../../components/common/input/TextField";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import { IoIosMail } from "react-icons/io";
import Button from "../../components/common/button/Button";
import Icons from "../../assets/icons/index";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import useDictionary from "../../hooks/lang/useDictionary";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux/util/dispatchStore";
import SendMessageSuccess from "./SendMessageSuccess";
import callApi from "../../services/api/apiClient";

const data = [
  {
    id: 1,
    name: "contact-us",
  },
];

const contactSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [phones, setPhones] = useState([]);
  const { translate } = useDictionary();
  const navigate = useNavigate();
  const contactInfo = useSelector(
    (state) => state?.others?.getContactInfo?.data
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: contactSchema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setIsSuccess(true);
      setTimeout(() => {
        callApi("others/contactUs")
          .withBody(values)
          .loadingGroup("contact")
          .executeDispatch();
        setIsLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
        setIsSuccess(false);
      }, 2000);
    },
  });

  useEffect(() => {
    if (contactInfo) {
      setPhones(contactInfo?.branches[0]?.phone?.split(", "));
    }
  }, [contactInfo]);

  return (
    <div className="bg-black-0 min-h-[50vh]">
      <Breadcrumb title={"contact-us"} item={data} />
      <div className="container mx-auto py-[15px] md:py-[60px]">
        <div className=" border-t-[27px] border-pink rounded-[5px] p-[20px] sm:p-[50px] lg:px-[100px] lg:py-[50px] bg-white relative mb-[50px]">
          <img
            src={Icons.PetLeg}
            alt=""
            className=" absolute bottom-[5%] right-[3%] w-[80px] sm:w-auto"
          />
          <h4 className="mb-[20px]">
            {translate("fill-the-form", "Fill the form")}
          </h4>
          <div className="flex flex-col lg:flex-row gap-[30px] lg:gap-0">
            <div className=" lg:w-[45%]">
              <h6>{translate("contact-us", "Contact Us")}</h6>
              <form onSubmit={formik.handleSubmit}>
                <div className="mt-[30px]">
                  <TextFieldInput
                    name={"name"}
                    label={translate("name", "Name") + "*"}
                    type={"text"}
                    className={"w-full"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <span className=" text-sm text-red-2">
                      {translate("name-require-error", formik.errors.name)}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[30px]">
                  <TextFieldInput
                    name={"phone"}
                    label={translate("phone", "Phone") + "*"}
                    type={"tel"}
                    className={"w-full"}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <span className=" text-sm text-red-2">
                      {translate("mobile-require-error", formik.errors.phone)}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[30px]">
                  <TextFieldInput
                    name={"email"}
                    label={translate("email", "Email")}
                    type={"email"}
                    className={"w-full"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="mt-[30px]">
                  <TextFieldInput
                    name={"message"}
                    label={translate("message", "Message") + "*"}
                    type={"text"}
                    className={"w-full"}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <span className=" text-sm text-red-2">
                      {translate("message-is-required", formik.errors.message)}
                    </span>
                  ) : null}
                </div>
                <Button
                  type={"submit"}
                  className={
                    "p-[15px] w-[190px] h-[53px] flex items-center justify-center bg-red-4 text-white rounded-[8px] mt-[20px] hover:bg-red-6 transition-all"
                  }
                >
                  {formik.isSubmitting || isLoading ? (
                    <BeatLoader color="#ffffff" size={13} />
                  ) : (
                    translate("send-a-message", "Send a Message")
                  )}
                </Button>
              </form>
            </div>
            <div className=" lg:w-[10%]">
              <div className="w-[1px] bg-black-4 lg:h-[calc(100%-15%)] mx-auto"></div>
            </div>
            <div className=" lg:w-[45%]">
              <h4>{translate("contact-information", "Contact Information")}</h4>
              <div className="flex items-center gap-[20px] mt-[30px]">
                <FaMapMarkerAlt className=" text-[30px] lg:text-[25px]" />
                <span className="body">
                  {contactInfo?.branches[0]?.address}
                </span>
              </div>
              <div className="flex items-center gap-[20px] mt-[30px]">
                <HiPhone size={23} />
                <div className="flex gap-3">
                  {phones.map((phone) => (
                    <a
                      href={`tel:09${phone?.split("-")[1]}`}
                      className="body"
                      key={phone}
                    >
                      {phone}
                    </a>
                  ))}
                </div>
              </div>
              <div className="flex items-center gap-[20px] mt-[30px]">
                <IoIosMail size={27} />
                <a
                  href={`mailto:${contactInfo?.branches[0]?.email}`}
                  className="body"
                >
                  {contactInfo?.branches[0]?.email}
                </a>
              </div>
              <div className="flex gap-[30px] mt-[30px]">
                {contactInfo?.social_links?.map((social, id) => (
                  <a href={social?.link} key={id}>
                    <img
                      src={social?.social_image}
                      alt={social?.platform}
                      title={social?.platform}
                    />
                  </a>
                ))}
              </div>
              <div className="mt-[40px]">
                <span
                  href="#"
                  className="underline text-sky_blue body cursor-pointer"
                  onClick={() => {
                    navigate("/branches");
                  }}
                >
                  {translate("view-all-branches", "View All Branches")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSuccess && (
        <SendMessageSuccess onClick={() => navigate("/contact-us")} />
      )}
    </div>
  );
};

export default ContactUs;
