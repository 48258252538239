import React from "react";
import AuthWrapper from "../../components/common/auth/AuthWrapper";
import styled from "./password.module.css";
import Icons from "../../assets/icons/index";
import TextFieldInput from "../../components/common/input/TextField";
import Button from "../../components/common/button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import callApi from "../../services/api/apiClient";
import useAuth from "../../hooks/lang/useAuth";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import useDictionary from "../../hooks/lang/useDictionary";
import { dispatch } from "../../redux/util/dispatchStore";
import { slice } from "../../redux/reducers/reducer";
import Logout from "../../redux/util/logoutFunction";

const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(1, "Old password must be at least 1 characters long")
    .required("Old password is required"),
  new_password: Yup.string()
    .min(1, "New password must be at least 1 characters long")
    .required("New password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const ChangePasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const token = useAuth();
  const { translate } = useDictionary();

  const handleLogout = () => {
    Logout()
    // localStorage.removeItem("persist:root");
    // setTimeout(() => {
    //   navigate("/login");
    // }, 1000);
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setTimeout(() => {
        callApi("auth/changePassword")
          .withBody({
            old_password: values.old_password,
            new_password: values.new_password,
          })
          .loadingGroup(1)
          .execute()
          .then((res) => {
            if (res?.status === "success") {
              handleLogout();
              navigate("/change-success");
            }
          })
          .catch((err) => console.log("error", err));

        setIsLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
      }, 1000);
    },
  });

  return (
    <AuthWrapper backBtnLink={-1}>
      <div className={styled.box}>
        <div className=" absolute left-0 right-0 top-0">
          <div className={`${styled.img_box}`}>
            <img src={Icons.AuthLogo} alt="" className="w-[70px] 2xl:w-auto" />
          </div>
        </div>
        <h3 className={styled.heading}>
          {translate("change-password", "Change Password")}
        </h3>
        <form action="" onSubmit={formik.handleSubmit}>
          <div className="">
            <TextFieldInput
              name={"old_password"}
              type={"password"}
              label={translate("old-password", "Old password ")}
              className={"w-full"}
              value={formik.values.old_password}
              onChange={formik.handleChange}
            />
            {formik?.touched.old_password && formik?.errors.old_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "old-password-is-required",
                  formik.errors.old_password
                )}
              </span>
            ) : null}
          </div>
          <div className=" mt-[10px]">
            <TextFieldInput
              name={"new_password"}
              type={"password"}
              label={translate("new-password", "New password ")}
              className={"w-full"}
              value={formik.values.new_password}
              onChange={formik.handleChange}
            />
            {formik?.touched.new_password && formik?.errors.new_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "new-password-is-required",
                  formik.errors.new_password
                )}
              </span>
            ) : null}
          </div>
          <div className=" mt-[10px]">
            <TextFieldInput
              name={"confirm_password"}
              type={"password"}
              label={translate("confirm-password", "Confirm password ")}
              className={"w-full"}
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
            />
            {formik?.touched.confirm_password &&
            !formik?.values?.confirm_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "confirm_password_required",
                  formik.errors.confirm_password
                )}
              </span>
            ) : formik?.touched?.confirm_password &&
              formik?.values?.new_password !==
                formik?.values?.confirm_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "newpassword_and_confirmpassword_not_equal",
                  "New password and confirm password must be same."
                )}
              </span>
            ) : null}
          </div>
          <div className="flex justify-center">
            <Button
              type={"submit"}
              className=" bg-red-3 text-white h-[47px] w-[180px] 2xl:w-[230px] p-[8px] 2xl:p-[12px] rounded-[8px] lg:text-md 2xl:text-lg flex items-center justify-center  mt-[20px] lg:mt-[30px]"
              disabled={formik.isSubmitting || isLoading}
            >
              {formik.isSubmitting || isLoading ? (
                <BeatLoader color="#ffffff" size={13} />
              ) : (
                translate("save", "Save")
              )}
            </Button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ChangePasswordPage;
