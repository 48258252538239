import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../button/Button";
import useDictionary from "../../../hooks/lang/useDictionary";
import LoadingComponent from "../../loading/LoadingComponent";
import { CircularProgress } from "@mui/material";

const ConfirmPopup = ({ onClick, close, type }) => {
  const { translate } = useDictionary();
  let showText;
  if (type == "address") {
    showText = translate(
      "are-you-sure-you-want-to-delete-this-shipping-address",
      "sure-to-delete-address"
    );
  }
  if (type == "removeAll") {
    showText = translate(
      "sure-to-delete-items",
      "Are you sure you want to delete this items?"
    );
  }
  if (type == "cart") {
    showText = translate(
      "sure-to-delete-items",
      "Are you sure you want to delete this items?"
    );
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;

    return () => {
      document.body.style.overflow = "unset";
      document.body.style.position = "";
      document.body.style.top = "";
    };
  }, []);

  return (
    <div className=" fixed w-full h-[100vh] overflow-y-hidden bg-[#333333e5] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className=" w-[90%] md:w-[450px] min-h-[150px] bg-white border-t-[10px] border-red-1 rounded-[5px] p-[20px] md:p-[40px] relative">
        <div className="">
          <p className="title2 flex justify-center mt-[10px]">{showText}</p>
        </div>
        <div className="flex justify-between mt-[30px] gap-[30px]">
          <Button
            className={
              "w-full p-[12px] rounded-[10px] bg-black-1 hover:bg-black-2"
            }
            onClick={close}
          >
            {translate("cancel-delete", "Cancel")}
          </Button>
          <Button
            className={
              "w-full p-[12px] rounded-[10px] bg-red-3 hover:bg-red-4 text-white"
            }
            onClick={onClick}
          >
            <LoadingComponent
              loadingGroup={"removeCart"}
              loadingDesign={<CircularProgress color="inherit" size={20} />}
            >
              {translate("delete", "Delete")}
            </LoadingComponent>
          </Button>
        </div>
        <Button className=" absolute top-2 right-3" onClick={close}>
          <CloseIcon style={{ fontSize: 18 }} />
        </Button>
      </div>
    </div>
  );
};

export default ConfirmPopup;
