import React, { useState, useEffect } from "react";
import Icons from "../../assets/icons/index";
import styled from "./profile.module.css";
import ProfileEdit from "./ProfileEdit";
import { useNavigate } from "react-router-dom";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import moment from "moment";

const Profile = ({ userInfo }) => {
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const { translate } = useDictionary();
  const lang = useLanguage();
  const memberType = useSelector(
    (state) => state?.auth?.memberType?.data || null
  );
  let BadageImg =
    memberType?.find((obj) => obj?.id == userInfo?.member_type?.id)
      ?.icon_image || Icons?.vipLogo;

  let Badage;
  if (userInfo?.member_type?.id == 1) {
    Badage = Icons.vipLogo;
  } else if (userInfo?.member_type?.id == 2) {
    Badage = Icons.silverLogo;
  } else if (userInfo?.member_type?.id == 3) {
    Badage = Icons.goldLogo;
  } else if (userInfo?.member_type?.id == 4) {
    Badage = Icons.rubyLogo;
  } else if (userInfo?.member_type?.id == 5) {
    Badage = Icons.diamondLogo;
  }
  useEffect(() => {
    callApi("auth/memberType").loadingGroup("memberType").executeDispatch();
  }, []);

  return (
    <div className={styled.right_box}>
      {isEdit && (
        <ProfileEdit userInfo={userInfo} onClick={() => setIsEdit(false)} />
      )}
      {userInfo?.user_type === "b2c" && (
        <div className="flex items-center gap-[5px] mb-[15px]">
          <img src={Icons.BellLogo} alt="" />
          <span className=" text-noti">{userInfo?.reminder}</span>
        </div>
      )}
      <div className={styled.second_main_box}>
        <div className=" w-full md:w-[40%] relative">
          <h5>{translate("profile", "Profile")}</h5>
          <div className="mt-[20px]">
            <span className=" text-xs block font-muli">
              {translate("name", "Name")}
            </span>
            <span className=" body block">{userInfo?.name || "Thandar"}</span>
          </div>
          <div className="mt-[20px]">
            <span className=" text-xs block font-muli">
              {translate("phone_no", "Phone No.")}
            </span>
            <span className=" body block">
              {userInfo?.mobile || "09123456789"}
            </span>
          </div>
          {userInfo?.email && (
            <div className="mt-[20px]">
              <span className=" text-xs block font-muli">
                {translate("email", "Email")}
              </span>
              <span className=" body block">{userInfo?.email}</span>
            </div>
          )}
          {userInfo?.gender && (
            <div className="mt-[20px]">
              <span className=" text-xs block font-muli">
                {translate("gender", "Gender")}
              </span>
              <span className=" body block">{`${
                userInfo?.gender === "male" ? "Male" : "Female"
              }`}</span>
            </div>
          )}
          {userInfo?.dob && (
            <div className="mt-[20px]">
              <span className=" text-xs block font-muli">
                {translate("dob", "Date of Birth")}
              </span>
              <span className=" body block">
                {moment(userInfo?.dob).format("DD-MM-YYYY")}
              </span>
            </div>
          )}
          {userInfo?.user_type && (
            <div className="mt-[20px]">
              <span className=" text-xs block font-muli">
                {translate("user_type", "User Type")}
              </span>
              <span className=" body block">
                {userInfo?.user_type.toUpperCase()}
              </span>
            </div>
          )}

          <div
            className="mt-[20px] cursor-pointer text-sky_blue font-muli"
            onClick={() => setIsEdit(true)}
          >
            {translate("edit_profile_mb", "Edit Profile")}
          </div>
          <div
            className="mt-[10px] lg:hidden cursor-pointer text-sky_blue font-muli"
            onClick={() => navigate("/change")}
          >
            {translate("change-password", "Change Password")}
          </div>
          {userInfo?.user_type === "b2c" && (
            <img
              src={BadageImg}
              alt=""
              className=" absolute top-0 right-[10px] md:right-[30px]"
            />
          )}
        </div>
        {
          userInfo?.user_type == "b2c" ?
          <div className="md:w-[5%]">
          <div className="h-full w-[1px] bg-black-1"></div>
        </div> : null
        }
        
        {userInfo?.user_type !== "b2b" && (
          <div className="w-full md:w-[55%]">
            <span className=" font-righteous block">
              {translate(
                "member-discount-information",
                "Member Discount Information"
              )}
            </span>
            <table className=" w-full mt-[10px] border">
              <thead>
                <tr className=" bg-black-1">
                  <td className="secondary-body ps-2">
                    {translate("member-type", "Member Type")}
                  </td>
                  <td className="secondary-body">
                    {translate("discount", "Discount")}
                  </td>
                  <td className="secondary-body w-[50%] ps-[30px]">
                    {translate("description", "Description")}
                  </td>
                </tr>
              </thead>
              <tbody>
                {memberType?.map((eachMember, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img
                          src={eachMember?.icon_image}
                          alt=""
                          className=" w-[40px] ms-2 my-2"
                        />
                      </td>
                      <td className="title1">
                        {lang === "en"
                          ? eachMember?.member_discount + "%"
                          : eachMember?.member_discount + " ရာခိုင်နှုန်း"}
                      </td>
                      {index == 0 && (
                        <td
                          rowSpan={memberType?.length}
                          className="secondary-body"
                        >
                          Member type lifetime will be given 6 months. Member
                          type lifetime can be extended depending on the order
                          amount purchased within 6 months.
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
