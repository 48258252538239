import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import LoadingComponent from "../../loading/LoadingComponent";

const FavButton = ({ onClick, checked = false }) => {
  return (
    <LoadingComponent loadingGroup={"wishlist"} loadingDesign={<FavLoading />}>
      <IconButton
        variant="solid"
        style={{ backgroundColor: "#EEEEEE", borderRadius: "8px" }}
        onClick={onClick}
      >
        {checked ? (
          <Favorite className=" text-red-4" sx={{ fontSize: 25 }} />
        ) : (
          <FavoriteBorder className=" text-red-4" sx={{ fontSize: 25 }} />
        )}
      </IconButton>
    </LoadingComponent>
  );
};

function FavLoading() {
  return (
    <IconButton
      variant="solid"
      style={{ backgroundColor: "#EEEEEE", borderRadius: "8px" }}
    >
      <CircularProgress color="inherit" size={25} />
    </IconButton>
  );
}

export default FavButton;
