import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import Button from "../../components/common/button/Button";
import Icons from "../../assets/icons/index";
import styled from "./profile.module.css";
import Profile from "./Profile";
import ShippingAddress from "./ShippingAddress";
import WishList from "./WishList";
import OrderHistory from "./OrderHistory";
import OrderDetail from "./OrderDetail";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import useAuth from "../../hooks/lang/useAuth";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
import Logout from "../../redux/util/logoutFunction";

const items = [
  {
    id: 1,
    name: "profile",
  },
];

const ProfilePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { translate } = useDictionary();
  const params = new URLSearchParams(location.search);
  const category = params.get("category");
  const [nav, setNav] = useState("profile");
  const token = useAuth();
  const userInfo = useSelector((state) => state?.auth?.getUser?.data || null);
  const editProfile = useSelector(
    (state) => state?.auth?.editProfile?.data || null
  );

  useEffect(() => {
    if (category) {
      setNav(category);
    }
  }, [location]);

  useEffect(() => {
    callApi("auth/getUser").loadingGroup("u").executeDispatch();
  }, [token, editProfile]);

  const handleLogout = () => {
    Logout()
    // localStorage.clear();
    // setTimeout(() => {
    //   navigate("/login");
    // }, 1000);
    // if (token) {
    //   window.location.reload();
    // }
  };

  return (
    <LoadingComponent loadingGroup={"u"}>
      <div className=" bg-silver">
        <Breadcrumb title={"profile"} item={items} />
        <div className="container mx-auto py-[60px]">
          <div className={styled.main_box}>
            <div className={styled.left_box}>
              <div className=" bg-white p-[20px] rounded-[8px]">
                <div className="flex flex-col gap-[30px] border-b-2">
                  <div
                    className={`title1 cursor-pointer`}
                    style={{
                      color: `${nav === "profile" ? "#C8040B" : "#252525"}`,
                    }}
                    onClick={() => navigate("/profile?category=profile")}
                  >
                    {translate("profile", "Profile")}
                  </div>
                  <div
                    className={`title1 cursor-pointer`}
                    style={{
                      color: `${
                        nav === "shipping-address" ? "#C8040B" : "#252525"
                      }`,
                    }}
                    onClick={() =>
                      navigate("/profile?category=shipping-address")
                    }
                  >
                    {translate("shipping-address", "Shipping Address")}
                  </div>
                  <div
                    className={`title1 cursor-pointer`}
                    style={{
                      color: `${nav === "wishlist" ? "#C8040B" : "#252525"}`,
                    }}
                    onClick={() => navigate("/profile?category=wishlist")}
                  >
                    {translate("wishlist", "Wish List")}
                  </div>
                  <div
                    className={`title1 cursor-pointer mb-[30px]`}
                    style={{
                      color: `${
                        nav === "order-history" ? "#C8040B" : "#252525"
                      }`,
                    }}
                    onClick={() => navigate("/profile?category=order-history")}
                  >
                    {translate("order-history", "Order History")}
                  </div>
                </div>
                <Button
                  className={
                    "w-full p-[12px] bg-sky_blue hover:bg-[#19c1b8] text-white rounded-[8px] my-[20px] transition-all duration-150 active:scale-95"
                  }
                  onClick={() => navigate("/change")}
                >
                  {translate("change-password", "Change Password")}
                </Button>
                <Button
                  className={
                    "w-full p-[12px] bg-black-1 hover:bg-black-2 rounded-[10px] h-[47px] transition-all duration-150 active:scale-95"
                  }
                  onClick={handleLogout}
                >
                  {translate("logout", "Logout")}
                </Button>
              </div>
            </div>
            {nav === "profile" && <Profile userInfo={userInfo} />}
            {nav === "shipping-address" && <ShippingAddress />}
            {nav === "wishlist" && <WishList />}
            {nav === "order-history" && <OrderHistory />}
            {nav === "order-history-detail" && <OrderDetail />}
            
          </div>
        </div>
      </div>
    </LoadingComponent>
  );
};

export default ProfilePage;
