import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const TextFieldInput = ({ type, onChange, className, value, label,name,required}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  if (type === "password") {
    return (
      <FormControl variant="standard" className={`${className}`}>
        <InputLabel htmlFor={label}>{label}</InputLabel>
        <Input
          id={label}
          name={name}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? (
                  <VisibilityOff sx={{ fontSize: 20 }} />
                ) : (
                  <Visibility sx={{ fontSize: 20 }}/>
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );
  }
  return (
    <>
      <div className="relative z-0">
        <TextField
          id={label}
          name={name}
          label={label}
          variant="standard"
          value={value}
          onChange={onChange}
          type={type}
          className={`${className}`}
          required={required}
          
        />
      </div>
    </>
  );
};

export default TextFieldInput;
