import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "../product/productListing.module.css";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ProductCard from "../../components/common/card/ProductCard";
// import Pagination from "../../components/common/pagination/pagination";
import { BiFilterAlt } from "react-icons/bi";
import FilterProduct from "../../components/common/filter/filterProduct";
import { Modal, Ripple, initTE } from "tw-elements";
import callApi from "../../services/api/apiClient";
import useLanguage from "../../hooks/lang/useLanguage";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
import Icons from "../../assets/icons";
import { Pagination } from "@mui/material";

initTE({ Modal, Ripple });

const BrandCategory = (result) => {
  // const { brandId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { translate } = useDictionary();
  const brandName = params.get("name");
  const brandID = params.get("brand_id");
  const language = useLanguage();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState("PRICEFROMLOW");
  // console.log(result, "resultresult")

  const item = [result];

  // const item = [
  //   {
  //     name: "brand",
  //   },
  //   {
  //     name: brandName || "",
  //   },
  // ];

  const datas = {
    page_number: page,
    per_page: perPage,
    brand_id: brandID || "",
    lang: language,
    sort_by: sort,
  };

  const products = useSelector(
    (state) => state?.products?.getAllProducts?.data
  );

  const brandLists = useSelector(
    (state) => state?.products?.getBrandsList?.data
  );

  // console.log(brandLists);

  useEffect(() => {
    callApi("products/getAllProducts")
      .withParam(datas)
      .loadingGroup("b")
      .executeDispatch();
    callApi("products/getBrandsList")
      .withParam({ lang: language })
      .loadingGroup("b")
      .executeDispatch();
  }, [language, brandID, sort]);

  const handleChange = (event) => {
    setSort(event.target.value);
  };
  const [showModal, setShowModal] = useState(false);

  const Modal = () => {
    return (
      <>
        <div
          style={{ background: "rgba(0,0,0,0.4)" }}
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto container">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <FilterProduct item={brandLists} type="brand" />
              {/*footer*/}
              <div className="flex items-center justify-end px-5 md:px-10 pt-5 pb-6 gap-3 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="w-[150px] h-[40px] rounded-[5px] bg-black-1 hover:shadow-sm hover:text-red-4 cursor-pointer text-lg font-muli text-black-4 flex items-center justify-center"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {translate("close", "Close")}
                </button>
                <button
                  className="w-[150px] h-[40px] rounded-[5px] bg-green hover:shadow-sm cursor-pointer text-lg font-muli text-white flex items-center justify-center"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {translate("save", "Save")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  };

  return (
    <>
      <LoadingComponent loadingGroup={"b"}>
        <Breadcrumb item={item} title="brand" />
        <div className=" bg-black-0">
          <div className="container-con sub-category">
            <div
              className={
                window.innerWidth > 991 ? styled.subContainer : "container"
              }
            >
              <div className="flex lg:flex-row gap-y-[40px]">
                {window.innerWidth > 991 ? (
                  <div className="basis-2/5 xl:basis-1/4 mr-[20px]">
                    <FilterProduct item={brandLists} type="brand" />
                  </div>
                ) : (
                  showModal && <Modal />
                )}

                <div
                  className={
                    window.innerWidth > 991
                      ? "basis-3/5 xl:basis-3/4"
                      : "w-full"
                  }
                >
                  <div className="flex flex-wrap items-center justify-between lg:ml-[20px] gap-y-3">
                    <h4>{brandName}</h4>
                    <div
                      className={
                        window.innerWidth > 460
                          ? "flex items-center gap-2 md:gap-4"
                          : "w-full flex items-center gap-2 md:gap-4"
                      }
                    >
                      <p className="title2">
                        {translate("sort-by", "Sort By")}
                      </p>
                      <FormControl
                        sx={{
                          minWidth:
                            window.innerWidth > 600
                              ? 200
                              : window.innerWidth < 461
                                ? "calc(100% - 105px)"
                                : 160,
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "red",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            height: window.innerWidth > 460 ? 50 : 45,
                            top: window.innerWidth > 460 ? -4 : -1,
                          },
                          "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                          {
                            fontFamily: "MuliRegular",
                          },
                          "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                          {
                            fontFamily: "MuliRegular",
                          },
                        }}
                      >
                        <Select value={sort} onChange={handleChange}>
                          <MenuItem value={"PRICEFROMLOW"} className="body">
                            {translate("low-to-hight", "Price Low to High")}
                          </MenuItem>
                          <MenuItem value={"PRICEFROMHIGH"} className="body">
                            {translate("hight-to-low", "Price High to Low")}
                          </MenuItem>
                          <MenuItem value={"NEWTOOLD"} className="body">
                            {translate("new-to-old", "New to Old")}
                          </MenuItem>
                          <MenuItem value={"OLDTONEW"} className="body">
                            {translate("old-to-new", "Old to New")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {window.innerWidth < 992 && (
                        <button
                          className="w-[30px] h-[30px] md:w-[50px] md:h-[50px] flex items-center justify-center cursor-pointer"
                          type="button"
                          onClick={() => setShowModal(true)}
                        >
                          <BiFilterAlt
                            size={window.innerWidth > 768 ? 30 : 24}
                            color="#555555"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  {window.innerWidth < 992 && (
                    <div className="flex flex-col gap-1">
                      <p className="title1">
                        {translate("filter-by", "Filter By")}
                      </p>
                      <p className="font-muli text-[14px] text-black-3">
                        {translate("brand-name", "Brand Name")} :{" "}
                        {
                          brandLists?.filter(
                            (b) => b.ID.toString() === brandID
                          )[0].name
                        }
                      </p>
                    </div>
                  )}
                  {products?.total_products ? (
                    <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 py-[30px] lg:ml-[20px]">
                      {products?.product_list?.map((data, index) => (
                        <ProductCard item={data} key={index} />
                      ))}
                    </div>
                  ) : (
                    <div className=" min-h-[30vh] flex justify-center items-center">
                      <span className="text-black-3 text-center">
                        <img
                          className="mx-auto"
                          src={Icons.emptyCartIcon}
                          alt=""
                        />
                        <span>{translate("no-product", "Empty Products")}</span>
                      </span>
                    </div>
                  )}
                  {products?.total_products >= 10 && (
                    <div className="lg:ml-[20px]">
                      {/* <Pagination /> */}
                      <Pagination
                        page={page}
                        onChange={(event, value) => {
                          window.scrollTo({ top: 0, left: 0 })
                          setTimeout(() => {
                            setPage(value)
                          }, 700);
                        }}
                        count={Math.ceil(products?.total_products / 12)}
                        variant="outlined"
                        shape="rounded"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingComponent>
    </>
  );
};
export default BrandCategory;
