import React, { useState } from "react";
import styled from "./wishlist_card.module.css";
import Button from "../button/Button";
import { MdShoppingCart } from "react-icons/md";
import Icons from "../../../assets/icons/index";
import Images from "../../../assets/images/index";
import callApi from "../../../services/api/apiClient";
import ConfirmPopup from "../popup/ConfirmPopup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDictionary from "../../../hooks/lang/useDictionary";
import CartSuccess from "../popup/CartSuccess";
import { useEffect } from "react";
import useLanguage from "../../../hooks/lang/useLanguage";
import { formatNumber, smartClick } from "../../../utils/generals";

const WishlistCard = ({ item }) => {
  // console.log(item);
  const [qty, setQty] = useState(1);
  const [isShow, setIsShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { translate } = useDictionary();
  const lang = useLanguage();
  const userInfo = useSelector((state) => state?.auth?.getUser?.data) || "";
  const orderInfo =
    useSelector((state) => state?.persistSlice?.addToCart?.data) || "";
  const addToCartStatus =
    useSelector((state) => state?.persistSlice?.addToCart?.frame_status) || "";

  const checkQty = () => {
    const datas = {
      product_id: Number(item?.product_template_id),
      quantity: qty,
    };

    if (item?.variant_id) {
      datas.variant_id = item?.variant_id;
    }

    callApi("products/checkQuantity")
      .withBody(datas)
      .loadingGroup("checkqty")
      .execute()
      .then((res) => {
        if (res?.error !== false) {
          setIsError(res?.error);
          setQty(res?.available_base_qty);
        }
      });
  };

  useEffect(() => {
    if (isTrue && userInfo?.user_type !== "b2b") {
      smartClick(() => {
        checkQty();
      });
    }
  }, [qty]);

  useEffect(() => {
    if (
      addToCartStatus == "success" &&
      orderInfo?.status == "success" &&
      isAdd
    ) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 1500);
    }
  }, [addToCartStatus]);

  const handleInputChange = (value) => {
    if (value) {
      value >= 1 && item?.status !== "out_of_stock" && setQty(Number(value));
    }
  };

  const decreaseQty = () => {
    setIsTrue(true);
    setQty((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const increaseQty = () => {
    setIsTrue(true);
    setQty((prev) => Number(prev) + 1);
  };

  const handleDelete = () => {
    callApi("wishList/deleteWishList")
      .withBody({ wishlist_id: item?.id })
      .loadingGroup("removeCart")
      .executeDispatch();
  };

  const handleAddToCard = () => {
    const time = new Date().toLocaleDateString("de-DE");
    const isOrderExpire = time === orderInfo?.order_valid_date;

    let datas = {
      product_id: Number(item.product_template_id),
      quantity: qty,
      // uom_id: 1,
      variant_id: item.variant_id,
      source: "web",
    };
    if (orderInfo?.id) {
      datas.order_id = isOrderExpire ? orderInfo?.id : "";
    }

    if (userInfo?.user_type === "b2b" ? true : item?.status === "in_stock") {
      callApi("persistSlice/addToCart")
        .withBody(datas)
        .loadingGroup("c")
        .executeDispatch();
      // navigate("/checkout");
      setIsAdd(true);
      setTimeout(() => {
        setIsAdd(false);
      }, 2000);
    } else {
      // setBuy(!buy);
    }
  };

  return (
    <>
      {isShow && (
        <ConfirmPopup
          close={() => setIsShow(false)}
          onClick={handleDelete}
          type={"cart"}
        />
      )}
      {isSuccess && (
        <CartSuccess
          onClick={() => {
            navigate("/add-to-card");
            setIsSuccess(false);
          }}
        />
      )}
      <div className={styled.main}>
        <div
          className={`${styled.img_box}`}
          onClick={() => {
            navigate(`/product/detail/${item?.product_template_id}`);
          }}
        >
          <img
            src={item?.image || Images.defaultImg}
            alt=""
            className=" w-full mt-2"
          />
          {item.promotion[0]?.promotion_type === "discount" && (
            <div
              className={`bg-[url('./assets/icons/promo_icon.svg')] ${styled.promo_icon}`}
            >
              <p className=" text-white text-sm md:text-base">
                {formatNumber(item?.promotion[0]?.discount_percent, lang)} %{" "}
              </p>
              {/* <p className=" text-white uppercase text-sm">
                  {translate("off", "off")}
                </p> */}
            </div>
          )}
          {item?.status === "out_of_stock" && userInfo?.user_type !== "b2b" && (
            <div className=" absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[#8f8d8dbd]">
              <span className=" bg-red-2 text-xs md:text-base text-white py-1 px-3 rounded-full">
                {translate("out-of-stock", "out of stock")}
              </span>
            </div>
          )}
          {item.get_one && (
            <span className=" bg-white text-sm sm:text-base px-1 font-medium absolute bottom-2 left-2">
              {formatNumber(item.get_one_price, lang)}
              {translate("ks", "Ks")}
            </span>
          )}
          {item.promotion[0]?.promotion_type === "product" && (
            <div
              className={`bg-[url('./assets/icons/promo_icon.svg')] ${styled.promo_icon}`}
            >
              <span
                className={` text-white text-center leading-4 ${
                  lang === "en" ? "text-[13px]" : "text-[12px]"
                }`}
              >
                {lang === "en"
                  ? `Buy ${item?.promotion[0]?.buy_product[0]?.min_qty}`
                  : `${formatNumber(
                      item?.promotion[0]?.buy_product[0]?.min_qty,
                      lang
                    )}ခုဝယ်`}
              </span>
              <span
                className={`text-white text-center leading-4 ${
                  lang === "en" ? "text-[13px]" : "text-[12px]"
                }`}
              >
                {lang === "en"
                  ? `Get ${item?.promotion[0]?.reward_product[0].reward_qty}`
                  : `${formatNumber(
                      item?.promotion[0]?.reward_product[0].reward_qty,
                      lang
                    )}ခု လက်ဆောင်`}
              </span>
              {lang === "en" ? (
                <p
                  className={`text-white uppercase ${
                    lang === "en" ? "text-[13px]" : "text-[12px]"
                  } leading-4`}
                >
                  {translate("free", "FREE")}
                </p>
              ) : null}
            </div>
          )}
        </div>

        <div className=" p-2 md:pb-[12px] md:px-[15px] w-[55%] md:w-full">
          <h3 className=" mb-[5px] pt-[12px]">{item?.name}</h3>
          <div className="flex gap-2 sm:gap-3 items-end">
            {item?.promotion[0]?.promotion_type === "discount" ? (
              <span className=" font-medium title1">
                {formatNumber(
                  item.list_price -
                    (item.list_price * item.promotion?.[0]?.discount_percent) /
                      100,
                  lang
                )}{" "}
                {translate("ks", "Ks")}
              </span>
            ) : (
              <span className=" font-medium title1">
                {formatNumber(item.list_price, lang)}
                {translate("ks", "Ks")}
              </span>
            )}
            {
              item?.promotion[0]?.promotion_type === "discount" && (
                <span className="text-xs sm:text-sm line-through">
                  {formatNumber(item.list_price, lang)}
                  {translate("ks", "Ks")}
                </span>
              )
              // :
              // (
              //   <span className="text-xs sm:text-sm line-through">
              //     {formatNumber(item.list_price, lang)}
              //     {translate("ks", "Ks")}
              //   </span>
              // )
            }
          </div>
          <div className={"flex flex-col md:flex-row md:items-end md:gap-3"}>
            <div className="flex items-center mt-2 md:mt-0">
              <button
                className=" rounded-s-lg bg-black-0 p-[3px] border-[1px] h-[40px] w-[35px] px-3 text-sm md:text-base"
                onClick={decreaseQty}
              >
                -
              </button>
              <input
                type="text"
                value={qty}
                onChange={(e) => handleInputChange(e.target.value)}
                className="bg-black-0 p-[3px] text-center border-[1px] h-[40px] w-[40px] focus:outline-none text-sm md:text-base"
              />
              <button
                className=" rounded-e-lg bg-black-0 p-[3px] border-[1px] h-[40px] w-[35px] px-3 text-sm md:text-base"
                onClick={increaseQty}
                disabled={
                  userInfo?.user_type === "b2b"
                    ? false
                    : item?.status === "out_of_stock"
                }
              >
                +
              </button>
            </div>
            <div className={styled.btn_group2}>
              <Button
                className={
                  "bg-red-4 rounded-[12px] text-white px-[5px] py-[3px] h-[40px] w-[45px] grid place-items-center"
                }
                onClick={() => handleAddToCard()}
              >
                <MdShoppingCart size={25} className="" />
              </Button>
              <Button
                className={
                  " bg-black-1 rounded-[12px] text-sm sm:text-base text-white py-[3px] px-[5px] 2xl:px-[10px] h-[40px] w-[45px] grid place-items-center"
                }
                onClick={() => setIsShow(true)}
              >
                <img src={Icons.trashIcon} alt="" className=" w-[20px]" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WishlistCard;
