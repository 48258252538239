import React, { useState } from "react";
import { AiOutlineFileAdd } from "react-icons/ai";
import useDictionary from "../../hooks/lang/useDictionary";

const FileUploader = ({ className = null, setSelectedFile, selectedFile }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const { translate } = useDictionary();

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    setIsDragOver(false);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div>
      <p className="title2">
        {translate("upload-payment-slip", "Upload payment slip")}
      </p>
      <div
        className={`border-2 ${
          isDragOver ? "border-blue-400" : "border-gray-300"
        } ${
          className ? className : "w-full h-[65px]"
        } bg-black-1 border-dashed rounded-lg text-center flex items-center justify-center mt-[8px] cursor-pointer hover:bg-[#ebeaea] transition-all duration-150`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onClick={() => {
          document.getElementById("file-input").click();
        }}
      >
        <input
          id="file-input"
          type="file"
          className="hidden"
          onChange={handleFileSelect}
          accept="image/*"
        />
        {selectedFile ? (
          <p className="body_14">
            {translate("selected-file", "Selected file")}: {selectedFile.name}
          </p>
        ) : (
          <p className="body_14 flex">
            <AiOutlineFileAdd size={25} className="mr-2 md:mr-4" />{" "}
            {translate(
              "drag-and-drop-file",
              "Drag and drop or browse to choose file"
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
