import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";

const LoadingComponent = ({
  loadingGroup,
  loadingDesign,
  children,
  stopCallBack,
}) => {
  const selectedData = useSelector((state) => state.loading[loadingGroup]);
  if (selectedData == undefined && typeof stopCallBack == "function") {
    stopCallBack();
  }
  return selectedData ? loadingDesign || <LoadingPage /> : children;
};

export default LoadingComponent;
