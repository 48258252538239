import React from "react";
import { MdOutlineDirections } from "react-icons/md";
import useDictionary from "../../hooks/lang/useDictionary";

const MapDirection = ({ name, shopLocation }) => {
  const { translate } = useDictionary();

  const handleGetDirections = () => {
    // Check if the browser supports geolocation
    if ("geolocation" in navigator) {
      // Request user's location with higher accuracy
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = { lat: latitude, lng: longitude };

          // Create the Google Maps URL for directions
          const directionsUrl = `http://www.google.com/maps/dir/?api=1&origin=${currentLocation.lat},${currentLocation.lng}&destination=${shopLocation.lat},${shopLocation.lng}&travelmode=driving`;

          // Open the URL in a new tab
          window.open(directionsUrl, "_blank");
        },
        (error) => {
          console.error("Error getting user's location:", error.message);
        },
        { enableHighAccuracy: true } // Add enableHighAccuracy option
      );
    } else {
      // console.log("Geolocation is not supported in this browser.");
    }
  };

  return (
    <div>
      <button
        onClick={handleGetDirections}
        className="text-[#06D8CD] flex gap-2 items-center my-2 cursor-pointer"
      >
        <MdOutlineDirections /> {translate("get-direction", name)}
      </button>
    </div>
  );
};

export default MapDirection;
