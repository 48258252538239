import RegisterSuccessWrapper from "../../components/common/auth/RegisterSuccessWrapper";
import Images from "../../assets/images/index";
import Button from "../../components/common/button/Button";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import useMetaDatas from "../../hooks/useMetaDatas";

const RegisterSuccessPage = () => {
  const location = useLocation();
  const { translate } = useDictionary();
  const params = new URLSearchParams(location.search);
  const user = params.get("user-type") || "";
  const type = params.get("type") || "";
  const navigate = useNavigate();
  const language = useLanguage();

  const { getMetaData } = useMetaDatas();
  const resetPasswordSuccess = getMetaData("reset-password-succcess");
  console.log("get data.....", resetPasswordSuccess);

  return (
    <RegisterSuccessWrapper backBtnLink={"/"}>
      {type == "signup" ? (
        <>
          {user == "b2c" ? (
            <div className=" w-[90%] sm:w-[80%] lg:w-[800px] lg:h-[500px] 2xl:w-[865px] 2xl:h-[555px] bg-white flex rounded-[12px] overflow-hidden">
              <div className="lg:w-[46%] relative">
                <img
                  src={Images.successPet}
                  alt=""
                  className=" absolute bottom-0"
                />
              </div>
              <div className="lg:w-[54%] flex lg:pt-[10%] 2xl:pt-[15%] flex-col p-[30px] md:p-[60px]">
                <h1 className=" font-righteous lg:text-3xl leading-normal">
                  {translate("register_success", "Successfully registered!")}
                </h1>
                <span className="body block my-[30px]">
                  {language === "en"
                    ? "Thanks for registering with us."
                    : "စာရင်းသွင်းခြင်းအတွက် ကျေးဇူးတင်ပါသည်။"}
                </span>
                <Button
                  className={
                    "bg-red-4 w-[240px] h-[47px] rounded-[8px] text-white mb-[30px]"
                  }
                  onClick={() => navigate("/login")}
                >
                  {translate("login", "Login")}
                </Button>
                <Button
                  className={
                    "flex items-center gap-3 font-muli text-[18px] md:text-[24px] text-sky_blue"
                  }
                  onClick={() => navigate("/")}
                >
                  <FaAngleDoubleLeft />{" "}
                  {translate("go-to-home-page", "Go to Home Page")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-[90%] sm:w-[80%] lg:w-[800px] lg:h-[500px] 2xl:w-[865px] 2xl:h-[555px] bg-white flex rounded-[12px] overflow-hidden">
              <div className="lg:w-[46%] relative">
                <img
                  src={Images.successPet}
                  alt=""
                  className=" absolute bottom-0"
                />
              </div>
              <div className="lg:w-[54%] flex justify-center flex-col p-[30px] md:p-[60px]">
                <h1 className=" font-righteous text-lg md:text-xl lg:text-3xl leading-normal">
                  {translate("register_success", "Successfully registered!")}
                </h1>
                <span className="body block my-[30px]">
                  {language === "en"
                    ? "Thanks for registering as B2B member. You will need admin's approval to login. We will review and response to you soon."
                    : "လက်ကားအဖွဲ့ဝင်အဖြစ် စာရင်းသွင်းခြင်းအတွက် ကျေးဇူးတင်ပါသည်၊ အကောင့်ဝင်ရန် အက်မင်ခွင့်ပြုချက် လိုအပ်ပါသည်၊ ပြန်လည်စစ်ဆေးပြီး မကြာမီ အကြောင်းပြန်ပါမည်။"}
                </span>
                <Button
                  className={
                    "flex items-center gap-3 font-muli text-[18px] md:text-[24px] text-sky_blue"
                  }
                  onClick={() => navigate("/")}
                >
                  <FaAngleDoubleLeft />{" "}
                  {translate("go-to-home-page", "Go to Home Page")}
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className=" w-[90%] sm:w-[80%] lg:w-[800px] lg:h-[500px] 2xl:w-[865px] 2xl:h-[555px] bg-white flex rounded-[12px] overflow-hidden">
            <div className="lg:w-[46%] relative">
              <img
                src={Images.successPet}
                alt=""
                className=" absolute bottom-0"
              />
            </div>
            <div className="lg:w-[54%] flex lg:pt-[10%] 2xl:pt-[15%] flex-col p-[30px] md:p-[60px]">
              <h1 className=" font-righteous lg:text-3xl leading-normal">
                {resetPasswordSuccess?.text_one}
              </h1>
              <span className="body block my-[30px]">
                {resetPasswordSuccess?.text_two}
              </span>
              <Button
                className={
                  "bg-red-4 w-[240px] h-[47px] rounded-[8px] text-white mb-[30px]"
                }
                onClick={() => navigate("/login")}
              >
                {translate("Login", "Login")}
              </Button>
            </div>
          </div>
        </>
      )}
    </RegisterSuccessWrapper>
  );
};

export default RegisterSuccessPage;
