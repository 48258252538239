import MainLogo from "./Logo.png";
import Mb_logo from "./mb_Logo.png";
import delivery from "./delivery.png";
import headset from "./headset.png";
import mail from "./mail.png";
import profile from "./person.png";
import cart from "./shopping-bag.svg";
import search from "./search.svg";
import shape from "./shape.svg";
import facebook from "./facebook.png";
import instragram from "./instragram.png";
import viber from "./viber.png";
import footerLogo from "./footer-logo.png";
import cashOn from "./cashOn.png";
import kpay from "./og-kbzbank.png";
import envelope from "./envelope.png";
import phone from "./phone.png";
import map from "./map.png";
import brand1 from "./brand1.png";
import brand2 from "./brand2.png";
import brand3 from "./brand3.png";
import brand4 from "./brand4.png";
import categoryOverlay from "./category_overlay.svg";
import headset_mb from "./headset_mb.svg";
import delivery_mb from "./delivery_mb.png";
import petfoot from "./pet_foot.png";
import sparkieIcon from "./sparkie_icon.svg";
import AuthLogo from "./auth_logo.svg";
import VerifyLogo from "./verify_icon.svg";
import PetLeg from "./pet_leg.svg";
import CBLogo from "./cb_logo.png";
import AYALogo from "./aya.png";
import KBZLogo from "./kbzbank.png";
import YOMALogo from "./yoma.png";
import MoneyLogo from "./money.jpg";
import DeliveryCarLogo from "./delivery_black.png";
import GoogleMapLogo from "./google_map.png";
import ParcelLogo from "./parcel.png";
import KBZPayLogo from "./kbzpay.jpg";
import NotFoundLogo from "./404Logo.svg";
import BellLogo from "./bell.png";
import vipLogo from "./vip_member.svg";
import silverLogo from "./silver_member.svg";
import goldLogo from "./gold_member.svg";
import rubyLogo from "./ruby_member.svg";
import diamondLogo from "./diamond_member.svg";
import person from "./person.svg";
import mapPin from "./map-pin.svg";
import logout from "./logout.svg";
import favorite from "./favorite.svg";
import key from "./key.svg";
import receipt from "./receipt.svg";
import dropArrowIcon from "./arrow-dropleft.svg";
import loginIcon from "./login.svg";
import registerIcon from "./register.svg";
import promoIcon from "./promo.png";
import trashIcon from "./trash.svg";
import toLoginIcon from "./login.png";
import warnningIcon from "./warnning.png";
import deliveryChargeIcon from "./delivery.svg";
import cartSuccessIcon from "./cart_success.png";
import emptyCartIcon from "./empty_cart.png";
import exclamationIcon from "./exclamation.png";

export default {
  MainLogo,
  Mb_logo,
  delivery,
  headset,
  mail,
  profile,
  cart,
  search,
  shape,
  facebook,
  instragram,
  viber,
  footerLogo,
  cashOn,
  kpay,
  envelope,
  phone,
  map,
  brand1,
  brand2,
  brand3,
  brand4,
  categoryOverlay,
  headset_mb,
  delivery_mb,
  petfoot,
  sparkieIcon,
  AuthLogo,
  VerifyLogo,
  PetLeg,
  CBLogo,
  AYALogo,
  KBZLogo,
  YOMALogo,
  MoneyLogo,
  DeliveryCarLogo,
  GoogleMapLogo,
  ParcelLogo,
  KBZPayLogo,
  NotFoundLogo,
  BellLogo,
  vipLogo,
  silverLogo,
  goldLogo,
  rubyLogo,
  diamondLogo,
  person,
  mapPin,
  favorite,
  key,
  logout,
  receipt,
  dropArrowIcon,
  loginIcon,
  registerIcon,
  promoIcon,
  trashIcon,
  toLoginIcon,
  warnningIcon,
  deliveryChargeIcon,
  cartSuccessIcon,
  emptyCartIcon,
  exclamationIcon,
};
