import React, { useEffect, useState } from "react";
import styled from "./profile.module.css";
import Button from "../../components/common/button/Button";
import OrderItem from "./OrderItem";
import Icons from "../../assets/icons/index";
import FileUploader from "../checkout/FileUploader";
import callApi from "../../services/api/apiClient";
// import { Pagination } from "@mantine/core";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
import PayNowPopup from "../../components/common/popup/PayNowPopup";
import { useNavigate, useLocation } from "react-router-dom";
import useLanguage from "../../hooks/lang/useLanguage";
import { formatNumber } from "../../utils/generals";

const history = [
  { id: 1, status: "paid" },
  { id: 2, status: "cancel" },
  { id: 3, status: "paid" },
  { id: 4, status: "unpaid" },
  { id: 5, status: "paid" },
];

const OrderDetail = () => {
  const location = useLocation();
  const item = location?.state?.item; // Read values passed on state
  const navigate = useNavigate();
  const [detailId, setDetailId] = useState(item?.id || "");
  const [selected, setSelected] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const { translate } = useDictionary();
  const lang = useLanguage();
  const [showPopup, setShowPopup] = useState(false);
  const [status, setStatus] = useState(item || "");

  const orderLists = useSelector((state) => state?.profile?.orderLists?.data);

  // console.log("order list....", orderLists);

  const orderDetail = useSelector((state) => state?.profile?.orderDetail?.data);

  const userInfo = useSelector((state) => state?.auth?.getUser?.data);

  const productOnly = orderDetail?.order_lines?.filter(
    (cart) => cart?.product?.line_ref == false
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    setPage(1);
  }, [selected]);

  useEffect(() => {
    setTimeout(() => {
      callApi("profile/orderLists")
        .withParam({
          page_no: page,
          per_page: 8,
          status_id: selected == 0 ? null : selected,
        })
        .loadingGroup("order")
        .executeDispatch();
    }, 100);
  }, [page, selected]);

  // retive payment types data
  useEffect(() => {
    callApi("checkout/getPaymentTypes")
      .loadingGroup("checkout")
      .executeDispatch();
    callApi("profile/orderStatus").loadingGroup("order").executeDispatch();
  }, []);

  useEffect(() => {
    if (detailId) {
      callApi("profile/orderDetail")
        .withSegment(detailId)
        .loadingGroup("order")
        .executeDispatch();
    }
  }, [detailId]);

  useEffect(() => {
    if (orderLists?.total_count) {
      setTotalPage(Math.ceil(orderLists?.total_count / 8));
    }
  }, [orderLists]);

  window.addEventListener("popstate", (event) => {
    return;
  });

  return (
    <LoadingComponent loadingGroup={"order"}>
      {showPopup && (
        <PayNowPopup onClick={() => setShowPopup(false)} orderId={detailId} />
      )}
      <>
        <div className={styled.right_box}>
          {detailId && (
            <>
              <div className="bg-white p-[15px] md:p-[30px] rounded-[8px]">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                  <h5>{translate("order-detail", "Order Detail")}</h5>
                  <div className=" flex items-center justify-between sm:justify-normal gap-3">
                    {/* {history[detailId - 1]?.status === "paid" ? (
                        <span className="body text-[#2F8F00]">
                          Delivered, Paid
                        </span>
                      ) : history[detailId - 1]?.status === "unpaid" ? (
                        <span className="body text-orange-400">
                          Pending, Unpaid
                        </span>
                      ) : (
                        <span className="body text-black-3">
                          Cancel, Refund
                        </span>
                      )} */}
                    {/* {console.log("status....", orderDetail)} */}
                    {status?.status == "Cancelled" ? (
                      <span className={`body ${"text-black-3"}`}>
                        {status?.status} | {status?.payment_status}
                      </span>
                    ) : (
                      <span
                        className={`body ${
                          status?.payment_status == "Unpaid"
                            ? "text-orange-400"
                            : status?.payment_status == "Paid"
                            ? "text-[#2F8F00]"
                            : "text-black-3"
                        }`}
                      >
                        {orderDetail?.status} | {status?.payment_status}
                      </span>
                    )}

                    {/* <MdRestore size={25} className=" cursor-pointer" /> */}
                  </div>
                </div>
                <div className=" flex justify-between items-start">
                  <div className=" mt-[30px] flex flex-col gap-[5px] md:gap-[15px]">
                    <span className="title2 block">
                      {translate("order-no", "Order No.")} -{" "}
                      {orderDetail?.order_id}
                    </span>
                    <span className="title2 block">
                      {translate("order_date", "Order Date")} -{" "}
                      {formatDate(orderDetail?.date)}
                    </span>
                    {orderDetail?.shipping_info?.street ||
                    orderDetail?.shipping_info?.township?.name ||
                    orderDetail?.shipping_info?.state?.name ? (
                      <span className="title2 block">
                        {/*  No.97, Xxxxxxxxx Street, Lanmadaw, Yangon */}
                        {translate("delivery-to", "Deliver To")} :{" "}
                        {`${orderDetail?.shipping_info?.street}, ${orderDetail?.shipping_info?.township?.name},
                      ${orderDetail?.shipping_info?.state?.name}`}
                      </span>
                    ) : null}
                  </div>
                  {status?.status == "Cancelled" ||
                    (status?.payment_status !== "Paid" && (
                      <Button
                        className={
                          " w-[130px] sm:w-[200px] h-[35px] text-sm sm:text-base sm:h-[47px] bg-red-3 hover:bg-red-4 rounded-[8px] text-white mt-8 sm:mt-5"
                        }
                        onClick={() => setShowPopup(true)}
                      >
                        {translate("make-payment", "Make Payment")}
                      </Button>
                    ))}
                </div>
                <div className="mt-[20px]">
                  <table className=" w-full">
                    <thead className=" hidden sm:contents">
                      <tr className=" border-b-2">
                        <th className="w-[100px] text-center pb-5">
                          {translate("items", "Item")}
                        </th>
                        <th className="text-start pb-5"></th>
                        <th className="text-start pb-5 md:w-[110px]">
                          {translate("price", "Price")}
                        </th>
                        <th className="text-center pb-5 md:w-[90px] 2xl:w-[120px]">
                          {translate("quantity", "Quantity")}
                        </th>
                        <th className="text-end pb-5 md:w-[130px]">
                          {translate("subtotal", "Subtotal")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productOnly?.map((card, i) => (
                        <OrderItem key={i} item={card} />
                      ))}
                      <tr className="md:border-b-2">
                        <td className="md:pb-5"></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="" colSpan={2}></td>
                        <td className=" font-semibold pt-3" colSpan={2}>
                          {translate("order-total", "Order Total")}
                        </td>
                        <td className="text-end font-semibold pt-3">
                          {formatNumber(orderDetail?.subtotal, lang)}{" "}
                          {translate("ks", "Ks")}
                        </td>
                      </tr>
                      {orderDetail?.tax ? (
                        <tr>
                          <td className="" colSpan={2}></td>
                          <td className=" font-semibold pt-3" colSpan={2}>
                            {translate("tax", "Tax")}
                          </td>
                          <td className="text-end font-semibold pt-3">
                            {formatNumber(orderDetail?.tax, lang)}{" "}
                            {translate("ks", "Ks")}
                          </td>
                          <td className=" border-b-2"></td>
                        </tr>
                      ) : null}
                      {userInfo?.user_type !== "b2b" &&
                      orderDetail?.member_discount_amount ? (
                        <tr>
                          <td className="" colSpan={2}></td>
                          <td className=" border-b-2 py-2" colSpan={2}>
                            {translate("member-discount", "Member Discount")} (
                            {formatNumber(
                              orderDetail?.member_discount_percent,
                              lang
                            )}{" "}
                            %)
                          </td>
                          <td className="text-end border-b-2 py-2">
                            -{" "}
                            {formatNumber(
                              orderDetail?.member_discount_amount,
                              lang
                            )}{" "}
                            {translate("ks", "Ks")}
                          </td>
                          <td className=" border-b-2"></td>
                        </tr>
                      ) : (
                        <tr
                          className={`${
                            orderDetail?.member_discount_amount ? "" : "hidden"
                          }`}
                        >
                          <td className="" colSpan={2}></td>
                          <td className=" border-b-2 py-2" colSpan={2}></td>
                          <td className="text-end border-b-2 py-2"></td>
                          <td className=" border-b-2"></td>
                        </tr>
                      )}
                      {orderDetail?.member_discount_amount ? (
                        <tr>
                          <td className="" colSpan={2}></td>
                          <td className=" font-bold pt-2" colSpan={2}>
                            {translate("total", "Total")}
                          </td>
                          <td className="text-end font-bold pt-2">
                            {formatNumber(orderDetail?.total, lang)}{" "}
                            {translate("ks", "Ks")}
                          </td>
                        </tr>
                      ) : null}
                    </tfoot>
                  </table>
                </div>
                {history[detailId - 1]?.status === "unpaid" && (
                  <div className="flex flex-col md:flex-row gap-3 md:gap-[24px] md:items-end mt-[20px]">
                    <FileUploader className={"md:w-[480px] h-[48px]"} />
                    <Button
                      className={
                        " w-[200px] h-[48px] bg-red-3 text-white rounded-[8px] transition-all duration-150 hover:bg-red-4 active:scale-95"
                      }
                    >
                      {translate("submit", "Submit")}
                    </Button>
                  </div>
                )}
              </div>
              <Button
                className={
                  "flex items-center gap-3 mt-[30px] transition-all duration-150 active:gap-2"
                }
                onClick={() => navigate("/profile?category=order-history")}
              >
                <img
                  src={Icons.dropArrowIcon}
                  alt=""
                  className=" w-[23px] sm:w-auto"
                />{" "}
                <span className=" sm:text-[20px]">
                  {translate("back-to-order-history", "Back to Order History")}
                </span>
              </Button>
            </>
          )}
        </div>
      </>
    </LoadingComponent>
  );
};

export default OrderDetail;
