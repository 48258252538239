import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "./item_card.module.css";
import { useSelector } from "react-redux";
import Icons from "../../assets/icons/index";
import Images from "../../assets/images";

import callApi from "../../services/api/apiClient";
import { formatNumber, smartClick } from "../../utils/generals";
import ConfirmPopup from "../../components/common/popup/ConfirmPopup";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ItemCard = ({ item }) => {
  const cartInfo = useSelector((state) => state?.cart?.getCart?.data);
  const [qty, setQty] = useState(item?.quantity || 1);
  const [err, setErr] = useState("");
  const [isShow, setIsShow] = useState(false);
  const { translate } = useDictionary();
  const [control, setControl] = useState(false);
  const lang = useLanguage();
  const navigate = useNavigate();

  const promotionOnly = cartInfo?.order_lines?.filter(
    (cart) => cart?.line_ref !== false
  );

  // const isGift = promotionOnly?.find(
  //   (promo) => promo?.line_ref == item?.line_id
  // );

  const isGift = promotionOnly?.find(
    (promo) => promo?.line_ref[promo?.line_ref?.length - 1] === item.line_id
  );

  const orderInfo = useSelector(
    (state) => state?.persistSlice?.addToCart?.data
  );

  const getCartFun = (id = "initial") => {
    if (orderInfo?.id) {
      callApi("cart/getCart")
        .withSegment(orderInfo?.id)
        .loadingGroup(`changeQty${id}`)
        .executeDispatch();
    }
  };

  const updateQty = useCallback(() => {
    let datas = {
      product_id: item?.product_id,
      quantity: qty,
      order_id: orderInfo?.id,
      line_id: item?.line_id,
      source: "web",
    };
    if (item?.variant_id !== false) {
      datas.variant_id = item?.variant_id;
    }

    callApi("cart/updateQtyCart")
      .withBody(datas)
      .loadingGroup("QuantityUpdate" + item?.line_id)
      .execute()
      .then((res) => {
        setErr(res);
        getCartFun(item?.line_id);
      });
  }, [qty]);

  const removeCart = () => {
    callApi("cart/removeCart")
      .withBody({ order_id: orderInfo?.id, product_id: item?.product_id })
      .loadingGroup("removeCart")
      .executeDispatch();
  };

  useEffect(() => {
    if (control) {
      smartClick(() => {
        updateQty();
      });
    }
  }, [qty, updateQty]);

  useEffect(() => {
    if (err?.error) {
      setTimeout(() => {
        setQty(err?.available_base_qty);
      }, 2000);
    }
  }, [err]);

  // useEffect(() => {
  //   getCartFun();
  // }, [removeCartInfo, clearCartInfo, err]);

  const handleChange = (e) => {
    setControl(true);
    let num = Number(e.target.value);
    if (num >= 1) {
      setQty(num);
    }
  };

  const decreaseQty = () => {
    setControl(true);
    setQty((prev) =>
      prev > 1 ? prev - (item?.quantity_control_step || 1) : prev
    );
  };

  const increaseQty = () => {
    setControl(true);
    setQty((prev) => Number(prev) + (item?.quantity_control_step || 1));
  };
  return (
    <>
      {isShow && (
        <ConfirmPopup
          close={() => setIsShow(false)}
          onClick={removeCart}
          type={"cart"}
        />
      )}
      <tr className=" hidden lg:table-row">
        <td className="pt-[40px]">
          <div className=" flex gap-5 items-center">
            <img
              src={item?.product_image || Images.defaultImg}
              alt=""
              className="w-[100px] h-[100px] rounded-[5px] cursor-pointer"
              onClick={() =>
                navigate(`/product/detail/${item.product_template_id}`)
              }
            />
            <p
              className=" pr-10 font-semibold cursor-pointer"
              onClick={() =>
                navigate(`/product/detail/${item.product_template_id}`)
              }
            >
              {item?.name}
            </p>
          </div>
        </td>
        {/* <td className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px] ">
          <Link to={`/product/detail/${item.product_template_id}`}>
            <div className=" flex gap-5 items-center ml-[20px]">
              <p className=" pr-10 font-semibold">{item?.name}</p>
            </div>
          </Link>
        </td> */}
        <td className="pt-[40px]">
          <div className="flex items-center">
            {formatNumber(item?.price_unit, lang)} {translate("ks", "Ks")}
          </div>
        </td>
        <td className="pt-[40px] md:w-[120px]">
          <div className={`flex flex-col justify-end relative`}>
            <div className=" flex">
              <button
                className=" rounded-s-lg bg-black-0 p-[5px] border-[1px] px-3 text-base"
                onClick={decreaseQty}
              >
                -
              </button>
              <LoadingComponent
                loadingGroup={`changeQty${item?.line_id}`}
                loadingDesign={<QtyLoading />}
              >
                <input
                  type="text"
                  className="bg-black-0 p-[5px] text-center border-[1px] w-[40px] text-base focus:outline-none"
                  value={qty}
                  onChange={handleChange}
                />
              </LoadingComponent>
              <button
                className=" rounded-e-lg bg-black-0 p-[5px] border-[1px] px-3 text-base"
                onClick={increaseQty}
              >
                +
              </button>
            </div>
            <div className=" absolute w-[200px] bottom-[-25px]">
              {err?.error && (
                <span className=" text-red-2 text-sm">{err?.error}</span>
              )}
            </div>
          </div>
        </td>
        <td className="pt-[40px] opacity-0">
          <div className="flex items-center justify-center">{item?.uom}</div>
        </td>
        <td className="pt-[40px]">
          <div className="flex items-center justify-end">
            {formatNumber(item?.price_subtotal, lang)} {translate("ks", "Ks")}
          </div>
        </td>
        <td className="sm:w-[40px] pt-[40px] text-end">
          <button className="mb-1" onClick={() => setIsShow(true)}>
            <CloseIcon style={{ fontSize: 18 }} />
          </button>
        </td>
      </tr>
      <tr className="lg:hidden">
        <td colSpan={5} className=" border-b-2">
          <div className={`${styled.align_right_mobile}` + " my-5 relative"}>
            <button
              className={
                `${styled.absolute_to_relative_mobile}` +
                " mb-1 top-[-1px] right-2"
              }
              onClick={() => setIsShow(true)}
            >
              <CloseIcon style={{ fontSize: 18 }} />
            </button>
            <div className=" flex gap-2 sm:gap-5 items-start mb-5">
              <img
                src={item?.product_image || Images.defaultImg}
                alt=""
                className={styled.img}
                onClick={() =>
                  navigate(`/product/detail/${item?.product_template_id}`)
                }
              />
              <div className="w-full">
                <p
                  className={
                    `${styled.width80_to_100_mobile}` +
                    " " +
                    `${styled.align_right_mobile}` +
                    " " +
                    " sm:w-auto font-semibold"
                  }
                  onClick={() =>
                    navigate(`/product/detail/${item?.product_template_id}`)
                  }
                >
                  {item?.name}
                </p>
                <p className={styled.align_right_mobile}>
                  {translate("uom", "UOM")} : {item?.uom}
                </p>
                <div className={styled.mb_btns}>
                  <p className="text-xs text-sky_blue body">
                    {formatNumber(item?.price_subtotal, lang)}{" "}
                    {translate("ks", "Ks")}
                  </p>
                  <div className=" relative">
                    <div className=" absolute bottom-[-23px] w-[200px] left-[-25px]">
                      {err?.error && (
                        <span className=" text-red-2 text-sm">
                          {err?.error}
                        </span>
                      )}
                    </div>
                    <div className={`${styled.justify_end_mobile}` + " flex"}>
                      <button
                        className=" rounded-s-lg bg-black-0 py-2 border-[1px] px-4 text-base"
                        onClick={decreaseQty}
                      >
                        -
                      </button>
                      <LoadingComponent
                        loadingGroup={`changeQty${item?.line_id}`}
                        loadingDesign={<QtyLoading />}
                      >
                        <input
                          type="number"
                          className="bg-black-0 text-center border-[1px] w-[40px] text-base focus:outline-none"
                          value={qty}
                          onChange={handleChange}
                        />
                      </LoadingComponent>
                      <button
                        className=" rounded-e-lg bg-black-0 py-2 border-[1px] px-4 text-base"
                        onClick={increaseQty}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isGift?.reward_type == "product" && (
              <div className=" mt-3">
                <div className=" flex gap-2 sm:gap-5 items-start">
                  <div className={""}>
                    <div className={styled.mb_sm_img}>
                      <img
                        src={isGift?.product_image || Images.defaultImg}
                        alt=""
                        className="w-[60px] h-[60px] ms-auto"
                      />
                    </div>
                  </div>
                  <div className=" w-full">
                    <p className=" ">{isGift?.name}</p>
                    <div className={styled.mb_btns}>
                      <div className={styled.prd_free}>
                        {translate("free", "Free")}
                      </div>
                      <p className="text-xs body">
                        {translate("qty", "Qty")} :{" "}
                        {formatNumber(isGift?.quantity, lang)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isGift?.reward_type == "discount" && (
              <div className=" mt-3">
                <div className=" flex gap-2 sm:gap-5 items-start">
                  <div className={""}>
                    <div className={styled.mb_sm_img}>
                      <img
                        src={Icons.promoIcon}
                        alt=""
                        className="w-[40px] sm:w-[40px] h-[40px] sm:h-[40px] ms-auto"
                      />
                    </div>
                  </div>
                  <div className="">
                    <p className="">{isGift?.name}</p>
                    <div
                      className={
                        "flex items-center mt-1 gap-6" +
                        " " +
                        `${styled.justify_between_to_justify_end}`
                      }
                    >
                      <p className="text-xs body text-sky_blue">
                        {lang !== "en" ? "- " : null}
                        {formatNumber(isGift?.price_subtotal, lang)}{" "}
                        {translate("ks", "Ks")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isGift?.reward_type == "group" && (
              <div className=" mt-3">
                <div className=" flex gap-2 sm:gap-5 items-start">
                  <div className={""}>
                    <div className={styled.mb_sm_img}>
                      <img
                        src={isGift?.product_image || Icons.promoIcon}
                        alt=""
                        className="w-[40px] sm:w-[40px] h-[40px] sm:h-[40px] ms-auto"
                      />
                    </div>
                  </div>
                  <div className=" w-full">
                    <p className="">{isGift?.name}</p>
                    <p>{isGift?.remark}</p>

                    <div className={styled.mb_btns}>
                      {isGift?.price_subtotal === 0 ? (
                        <div className="pt-4 text-end text-sky_blue">
                          <div className="w-fit flex items-center text-sm font-muliBold py-[1px] px-[12px] bg-red-2 text-white rounded-[13px]">
                            {translate("free", "Free")}
                          </div>
                        </div>
                      ) : (
                        <div className="pt-4 text-end text-sky_blue">
                          {lang !== "en" ? "- " : null}
                          {formatNumber(isGift?.price_subtotal, lang)}{" "}
                          {translate("ks", "Ks")}
                        </div>
                      )}
                      <p className="text-xs body"></p>
                    </div>
                  </div>
                  {/* <div className="">
                    <p className="">{isGift?.name}</p>
                    <div
                      className={"flex justify-between items-center mt-1 gap-6"}
                    >
                      {isGift?.price_subtotal === 0 ? (
                        <p className="text-xs body text-sky_blue">
                          {translate("free", "Free")}
                        </p>
                      ) : (
                        <p className="text-xs body text-sky_blue">
                          {formatNumber(isGift?.price_subtotal, lang)}{" "}
                          {translate("ks", "Ks")}
                        </p>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </td>
      </tr>
      {isGift?.reward_type == "product" && (
        <tr className="hidden lg:table-row">
          <td className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]">
            <div className=" flex gap-5 items-center">
              <div className=" w-[100px] flex justify-end">
                <img
                  src={isGift?.product_image || Images.defaultImg}
                  alt=""
                  className="w-[60px] h-[60px] rounded-[5px]"
                />
              </div>
              <p className=" pr-10 font-semibold">{isGift?.name}</p>
            </div>
          </td>
          <td className="pt-[40px]">
            <div className="w-fit flex items-center text-sm font-muliBold py-[1px] px-[12px] bg-red-2 text-white rounded-[13px]">
              {translate("free", "Free")}
            </div>
          </td>
          <td className="pt-[40px] md:w-[120px]">
            <div className=" text-center">
              {formatNumber(isGift?.quantity, lang)}
            </div>
          </td>
        </tr>
      )}
      {isGift?.reward_type == "discount" && (
        <tr className="hidden lg:table-row">
          <td
            colSpan={4}
            className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]"
          >
            <div className=" flex gap-5 items-center">
              <div className=" w-[100px] flex justify-end">
                <img
                  src={Icons.promoIcon}
                  alt=""
                  className="w-[30px] rounded-[5px]"
                />
              </div>
              <div className="">
                <p className=" pr-10 ">{isGift?.name}</p>
                <p>{isGift?.remark}</p>
              </div>
            </div>
          </td>
          <td className="pt-[40px] text-end">
            {lang !== "en" ? "- " : null}
            {formatNumber(isGift?.price_subtotal, lang)} {translate("ks", "Ks")}
          </td>
        </tr>
      )}
      {isGift?.reward_type == "group" && (
        <tr className="hidden lg:table-row">
          <td
            colSpan={2}
            className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]"
          >
            <div className=" flex gap-5 items-center">
              <div className=" w-[100px] flex justify-end">
                <img
                  src={isGift?.product_image || Icons.promoIcon}
                  alt=""
                  className="w-[30px] rounded-[5px]"
                />
              </div>
              <div className="">
                <p className=" pr-10 ">{isGift?.name}</p>
                <p>{isGift?.remark}</p>
              </div>
            </div>
          </td>

          <td colSpan={2}></td>
          {isGift?.price_subtotal === 0 ? (
            <td className="pt-[40px] text-end">
              <div className="w-fit flex items-center text-sm font-muliBold py-[1px] px-[12px] bg-red-2 text-white rounded-[13px]">
                {translate("free", "Free")}
              </div>
            </td>
          ) : (
            <td className="pt-[40px] text-end">
              {lang !== "en" ? "- " : null}
              {formatNumber(isGift?.price_subtotal, lang)}{" "}
              {translate("ks", "Ks")}
            </td>
          )}
        </tr>
      )}
    </>
  );
};

function QtyLoading() {
  return (
    <div className=" w-[40px] h-auto grid place-items-center border-[1px] bg-black-0">
      <CircularProgress color="inherit" size={16} />
    </div>
  );
}

export default ItemCard;
