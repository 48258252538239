import { BsChevronRight } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "./header.module.css";

export const ProductMenu = ({ categories, ChooseType, setProduct }) => {
  const navigate = useNavigate();
  const navTriggerRef = useRef(null);
  const [subCategories, setSubCategories] = useState(
    categories[0]?.sub_category
  );
  const [subCategoryArr, setSubCategoryArr] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});

  const handleClickOutside = (e) => {
    if (navTriggerRef.current && !navTriggerRef.current?.contains(e.target)) {
      setProduct(false);
    }
    // console.log("hello ref");
  };

  useEffect(() => {
    if (subCategories) {
      setSubCategoryArr(subCategories[0]?.sub_category);
    }
  }, []);

  useEffect(() => {
    const handleClick = (e) => handleClickOutside(e);
    document
      .getElementById("product_menu_box")
      .addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="container-con header-expand">
      <div className="w-full" id="product_menu_box">
        <div
          className={
            "grid grid-cols-3 gap-1 max-w-[800px] 2xl:max-w-[1000px] mx-auto bg-white h-[393px] overflow-hidden shadow-lg"
          }
          ref={navTriggerRef}
        >
          <div
            id="category"
            className={`${styled.menu_scroll} flex flex-col overflow-y-auto`}
          >
            {categories?.map((category, index) => {
              return (
                <button
                  key={index}
                  onMouseOver={() => {
                    setSubCategories(category.sub_category);
                    setSelectedCategory(category);
                    setSubCategoryArr([]);
                  }}
                  style={{
                    color:
                      selectedCategory.category_name == category.category_name
                        ? "#C8040B"
                        : "#252525",
                  }}
                  className="w-full flex items-center justify-between p-3 border-b border-black-1 cursor-pointer"
                >
                  <p>{category.category_name}</p>
                  {category?.sub_category?.length > 0 ? (
                    <BsChevronRight
                      size={18}
                      color={
                        selectedCategory.category_name == category.category_name
                          ? "#C8040B"
                          : "#252525"
                      }
                    />
                  ) : null}
                </button>
              );
            })}
          </div>
          <div
            id="sub-category"
            className={`${styled.menu_scroll} flex flex-col overflow-y-auto border-l-[1px]`}
          >
            {subCategories?.map((subCategory, index) => {
              return (
                <button
                  key={index}
                  onMouseOver={() => {
                    setSelectedSubCategory(subCategory);
                    setSubCategoryArr(subCategory.sub_category);
                  }}
                  className="w-full flex items-center justify-between p-3 border-b border-black-1 cursor-pointer"
                  onClick={() => {
                    if (subCategory.sub_category?.length > 0) {
                      setSubCategoryArr(subCategory.sub_category);
                    } else {
                      ChooseType("Product");
                      navigate(
                        `/filter?category_id=${subCategory.category_id}`
                      );
                    }
                  }}
                >
                  <p
                    style={{
                      color:
                        selectedSubCategory.sub_category ===
                        subCategory.sub_category
                          ? "#C8040B"
                          : "#252525",
                    }}
                  >
                    {subCategory.category_name}
                  </p>
                  {subCategory?.sub_category?.length > 0 ? (
                    <BsChevronRight
                      size={18}
                      color={
                        selectedSubCategory.sub_category ===
                        subCategory.sub_category
                          ? "#C8040B"
                          : "#252525"
                      }
                    />
                  ) : null}
                </button>
              );
            })}
          </div>
          <div
            id="productDetailTitle"
            className={`${styled.menu_scroll} flex flex-col overflow-y-auto border-l-[1px]`}
          >
            {subCategoryArr?.map((item, index) => {
              return (
                <Link
                  onClick={() => ChooseType("Product")}
                  // href="#"
                  key={index}
                  to={`/filter?category_id=${item?.category_id}`}
                  className=" w-full flex items-center justify-between p-3 border-b border-black-1 cursor-pointer hover:text-red-4"
                >
                  {item?.category_name}
                </Link>
              );
            })}
          </div>
        </div>

        <div className="h-[100%]" onClick={(_) => ChooseType("")}></div>
      </div>
    </div>
  );
};
