import { useState } from "react";
import InputSelect from "../../components/common/input/InputSelect";
import TextFieldInput from "../../components/common/input/TextField";
import useDictionary from "../../hooks/lang/useDictionary";
import { useEffect } from "react";
import useSelectorStore from "../../hooks/useSelectorStore";

const PickupAddressForm = ({ formik }) => {
  const { allLocations } = useSelectorStore();
  const [allDivisions, setAllDivisions] = useState([]);
  const [allTownships, setAllTownships] = useState([]);
  const { translate } = useDictionary();

  useEffect(() => {
    if (allLocations) {
      setAllDivisions(allLocations[0]?.state_ids);
    }
  }, [allLocations]);
  useEffect(() => {
    if (formik.values.division) {
      setAllTownships(
        allDivisions?.find((state) => state.id == formik.values.division)
          ?.township_ids
      );
    }
  }, [formik.values.division, allDivisions]);

  return (
    <form action="">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px]">
        <div className="col-span-2 md:col-span-1">
          <TextFieldInput
            name={"name"}
            type={"text"}
            label={translate("name", "Name") + "*"}
            className={"w-full"}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <TextFieldInput
            name={"phone"}
            type={"text"}
            label={translate("phone", "Phone") + "*"}
            className={"w-full"}
            value={formik.values.phone}
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputSelect
            name={"division"}
            data={allDivisions}
            label={translate("states-divisions", "State / Division")}
            className={"w-full"}
            value={formik.values.division}
            onChange={formik.handleChange}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputSelect
            name={"township"}
            data={allTownships}
            label={translate("township", "Township") + "*"}
            className={"w-full"}
            value={formik.values.township}
            onChange={formik.handleChange}
          />
        </div>
        <div className=" col-span-2">
          <TextFieldInput
            name={"address"}
            type={"text"}
            label={translate("address", "Address") + "*"}
            className={"w-full"}
            value={formik.values.address}
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </form>
  );
};

export default PickupAddressForm;
