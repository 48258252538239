import React, { useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "./home.module.css";
import Image from "../../assets/images";
import Icon from "../../assets/icons";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper";

import ProductCard from "../../components/common/card/ProductCard";
import BannerCard from "./BannerCard";

import callApi from "../../services/api/apiClient";
import useLanguage from "../../hooks/lang/useLanguage";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
import { dispatch } from "../../redux/util/dispatchStore";
import { categorySlice } from "../../helper/customSlice";
import { productGroup } from "../../services/api/endpoints";
import useMetaDatas from "../../hooks/useMetaDatas";

const HomePage = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const navigate = useNavigate();
  const { getMetaData } = useMetaDatas();
  const shippingInfo = getMetaData("");

  // console.log(token);

  const { translate } = useDictionary();
  translate("hello", "Hello");

  const language = useLanguage();

  const productsGroup = useSelector(
    (state) => state?.products?.getProductsGroup?.data
  );
  const cates = useSelector((state) => state?.products?.MyCategories?.data);
  const selectedBrands = useSelector(
    (state) => state?.products?.getHighlightBrands?.data
  );
  const homeBannerImage = useSelector(
    (state) => state?.others?.getBannerImage?.data
  );

  const getSliderImages = useSelector(
    (state) => state?.others?.getSliderImage?.data
  );
  const getMobileSliderImages = useSelector(
    (state) => state?.others?.getMobileSliderImage?.data
  );

  const promotionDatas = useSelector(
    (state) => state?.products?.getAllPromotion?.data
  );

  const arrToString = (arr) => {
    const string = arr?.join("|");
    // console.log(string);
    return string;
  };

  // Mobile
  const bannerImageMb = [
    {
      name: "Home Banner1",
      image: Image.MbBanner1,
    },
    {
      name: "Home Banner2",
      image: Image.MbBanner2,
    },
  ];

  // call api
  useEffect(() => {
    // callApi("products/getAllProducts")
    //   .withParam({ page_number: 1, per_page: 5 })
    //   .loadingGroup("p")
    //   .executeDispatch();
    callApi("products/getProductsGroup")
      .withParam({ lang: language, code: arrToString(productGroup) })
      .loadingGroup("p")
      .executeDispatch();
    callApi("products/MyCategories")
      .withParam({ lang: language })
      .loadingGroup("p")
      .executeDispatch();
    callApi("products/getHighlightBrands").loadingGroup("p").executeDispatch();
    callApi("others/getSliderImage").loadingGroup("p").executeDispatch();
    callApi("others/getMobileSliderImage").loadingGroup("p").executeDispatch();
    callApi("others/getBannerImage")
      .withParam({ code: "pd_banner" })
      .loadingGroup("p")
      .executeDispatch();

    callApi("products/getAllPromotion")
      .withParam({ show: "product" })
      .loadingGroup("p")
      .executeDispatch();
  }, [language]);

  // data from products group
  let newArrival_data = productsGroup?.data?.filter(
    (product) => product.code === "newarrival"
  )?.[0];
  let bestSeller_data = productsGroup?.data?.filter(
    (product) => product.code === "bestseller"
  )?.[0];
  let hotItems_data = productsGroup?.data?.filter(
    (product) => product.code === "hotitems"
  )?.[0];

  let category_data = productsGroup?.data?.filter(
    (product) => product.code === "popularcategory"
  )?.[0];

  // highlighBrand
  let brandHighligh_data = productsGroup?.data?.filter(
    (product) => product.code === "highlighBrand"
  )?.[0];

  let sliderImages = getSliderImages?.map((slider) => slider)[0];
  let mobileSliderImages = getMobileSliderImages?.map((slider) => slider)[0];

  // console.log(mobileSliderImages.slider_images);

  return (
    <LoadingComponent loadingGroup={"p"}>
      <div className="">
        <Swiper
          pagination={{ clickable: true }}
          modules={[Autoplay, Pagination]}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {window.innerWidth > 991 &&
            sliderImages?.slider_images?.map((image, key) => (
              <SwiperSlide key={key}>
                <img
                  src={image.image}
                  alt={image.name}
                  title={image.name}
                  className={styled.swiperImg}
                />
              </SwiperSlide>
            ))}
          {window.innerWidth < 992 &&
            mobileSliderImages?.slider_images?.map((image, key) => (
              <SwiperSlide key={key}>
                <img
                  src={image.image}
                  alt={image.name}
                  title={image.name}
                  className={styled.swiperImg}
                />
              </SwiperSlide>
            ))}
        </Swiper>

        {/* Category */}
        {category_data?.item?.length > 0 ? (
          <div className="container-con category-con mb-[50px]">
            <div className="container">
              <h4 className="w-full flex justify-center">
                {translate("category", "Category")}
              </h4>
              <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 md:gap-10 mt-10">
                {category_data &&
                  category_data?.item?.map((item, key) => (
                    <CategoryCart item={item} key={key} />
                  ))}
              </div>
            </div>
          </div>
        ) : null}

        {/* New Arrivals */}
        {newArrival_data?.item?.length > 0 ? (
          <ProductCategory
            data={newArrival_data?.item}
            title={newArrival_data?.name}
            className="category-con"
          />
        ) : null}

        {/* Delivery Promotion */}
        <div className="container-con">
          <div className="container">
            {window.innerWidth > 991 ? (
              <img
                src={homeBannerImage && homeBannerImage[0]?.desktop_image}
                alt="Delivery"
                title="Divery Image"
                className={styled.deliveryImg}
              />
            ) : (
              <img
                src={homeBannerImage && homeBannerImage[0]?.mobile_image}
                alt="Delivery"
                title="Divery Image"
                className={styled.deliveryImg}
              />
            )}
          </div>
        </div>

        {/* Best Seller */}
        {bestSeller_data?.item?.length > 0 ? (
          <ProductCategory
            data={bestSeller_data?.item}
            title={bestSeller_data?.name}
            className="best-seller-con"
          />
        ) : null}

        {/* Promotion Cart Slider */}

        <Swiper
          slidesPerView={window.innerWidth > 991 ? 2 : 1}
          modules={[Autoplay, Navigation]}
          centeredSlides={promotionDatas?.length > 1 ? false : true}
          spaceBetween={30}
          navigation={promotionDatas?.length > 3 ? true : false}
          slidesPerGroup={1}
          // loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          cssMode={true}
          className="mySwiper"
          pagination={{ clickable: true }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {promotionDatas?.map((slideContent, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <BannerCard item={slideContent} />
            </SwiperSlide>
          ))}
          {promotionDatas?.length > 3 && (
            <>
              <div className="swiper-button-prev" ref={prevRef}></div>
              <div className="swiper-button-next" ref={nextRef}></div>
            </>
          )}
        </Swiper>
        {/* Hot Item */}
        {hotItems_data?.item?.length > 0 ? (
          <ProductCategory
            data={hotItems_data?.item}
            title={hotItems_data?.name}
            className="best-seller-con"
          />
        ) : null}

        {/* Selected Brands */}
        {brandHighligh_data?.item?.length > 0 ? (
          <div className="container-con selected-brand-con">
            <div className="container">
              <h4 className="mb-8 text-center">
                {translate("selected-brands", "Selected Brands")}
              </h4>
              <div className=" relative">
                <div className=" grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-5">
                  {brandHighligh_data?.item?.map((brand, key) => (
                    <div
                      className="flex flex-col gap-2 items-center cursor-pointer rounded-[10px] overflow-hidden"
                      key={key}
                    >
                      <img
                        src={brand.image || Image.defaultImg}
                        alt={brand.name}
                        title={brand.name}
                        key={key}
                        className=" border-[1px] hover:border-red-2 rounded-[15px]"
                        onClick={() =>
                          // navigate(
                          //   `/brand/${brand.id}?name=${brand.name}&pagename=brand`
                          // )
                          navigate(`/filter?brand_id=${brand.id}`)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </LoadingComponent>
  );
};

const ProductCategory = ({ data, title, className }) => {
  return (
    <div className={`container-con ${className}`}>
      <div className="container">
        <h4 className="w-full flex justify-center">{title}</h4>
        <div className=" grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-8 mt-10 mb-16">
          {data?.map((data, key) => (
            <ProductCard item={data} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};
const CategoryCart = ({ item }) => {
  const handleCategory = (item) => {
    dispatch(categorySlice.actions.storeCategory(item));
  };
  let route;
  if (item?.has_child) {
    route = `/filter?category_id=${item.category_id}`;
  } else {
    route = `/filter?category_id=${item.category_id}`;
  }

  return (
    <Link
      to={route}
      className={styled.categoryCart}
      onClick={() => handleCategory(item)}
    >
      <div className={styled.cate_img_box}>
        <div className={styled.contentOverlay}></div>
        <div className={styled.categoryImg}>
          {window.innerWidth > 1023 ? (
            <img
              src={`${
                item?.image_link ? item?.image_link : Image.brandDefaultImg
              }`}
              alt={item.image_link}
              title={item.image_link}
              className={styled.categoryImg}
            />
          ) : (
            <img
              src={`${item?.image_link ? item?.image_link : Image.defaultImg}`}
              alt={item.image_link}
              title={item.image_link}
              className={styled.categoryImg}
            />
          )}
        </div>
        <img
          src={Icon.categoryOverlay}
          alt="Category Hover"
          title="Category Hover"
          className={styled.contentImg}
        />
      </div>
      <p>{item.name}</p>
    </Link>
  );
};

export default HomePage;
