import styled from "./profile.module.css";
import Button from "../../components/common/button/Button";
import { useNavigate } from "react-router-dom";
import WishlistCard from "../../components/common/card/WishlistCard";
import { useEffect } from "react";
import callApi from "../../services/api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import { formatNumber } from "../../utils/generals";
import { wishListSlice } from "../../helper/customSlice";

const WishList = () => {
  const navigate = useNavigate();
  const wishListDatas = useSelector((state) => state.wishListSlice.data);
  const dispatch = useDispatch();
  const { translate } = useDictionary();
  const lang = useLanguage();

 

  const isDeleteWishList = useSelector(
    (state) => state?.wishList?.deleteWishList?.data
  );

  useEffect(() => {
    callApi("wishList/getAllWishLists")
      .loadingGroup("wishlist")
      .execute()
      .then((res) => dispatch(wishListSlice.actions.storeWishList(res)));
  }, [isDeleteWishList]);

  return (
    <LoadingComponent loadingGroup={"wishlist"}>
      <div className={styled.right_box}>
        {/* <div className="bg-white rounded-[8px] p-[30px] min-h-[370px]">
        <div className=" flex items-center gap-[10px]">
          <h5>WishList</h5>
          <span className=" text-sm font-muli">(0 ITEMS)</span>
        </div>
        <div className=" pt-[100px]">
          <span className=" text-lx block text-center">
            No Item in Wishlist. Start shopping with your favorite items.
          </span>
        </div>
      </div> */}
        {!wishListDatas?.length > 0 ? (
          <div className="bg-white rounded-[8px] p-[30px] min-h-[370px]">
            <div className=" flex items-center gap-[10px]">
              <h5>{translate("wishlist", "Wishlist")}</h5>
              <span className=" text-sm font-muli">
                ({lang === "en" ? "0 item" : "ဝ ခု"} )
              </span>
            </div>
            <div className=" pt-[100px]">
              <span className=" text-lx block text-center">
                {translate(
                  "no-item-in-wishlist",
                  "No Item in Wishlist. Start shopping with your favorite items."
                )}
              </span>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-[8px] p-[15px] md:p-[30px]">
            <div className=" flex items-center gap-[10px]">
              <h5>{translate("wishlist", "Wishlist")}</h5>
              <span className=" text-sm font-muli">
                ({formatNumber(wishListDatas?.length, lang)}{" "}
                {lang === "en" ? "items" : "ခု"})
              </span>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] mt-[20px]">
              {wishListDatas?.map((item, id) => (
                <WishlistCard item={item} key={id} />
              ))}
            </div>
          </div>
        )}
        
        {/* <div className="bg-white rounded-[8px] p-[15px] md:p-[30px]">
          <div className=" flex items-center gap-[10px]">
            <h5>WishList</h5>
            <span className=" text-sm font-muli">
              ({wishLists?.length} ITEMS)
            </span>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[15px] mt-[20px]">
            {wishLists?.map((item, id) => (
              <WishlistCard item={item} key={id} />
            ))}
          </div>
        </div> */}
        <Button
          className={
            " w-fit p-[12px] bg-black-1 text-lg rounded-[10px] mt-[30px] border-[2px]"
          }
          onClick={() => navigate("/")}
        >
          {translate("continue-shopping", "Continue Shopping")}
        </Button>
      </div>
    </LoadingComponent>
  );
};

export default WishList;
