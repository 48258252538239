import { useSelector } from "react-redux";
// import { getOrderId } from "../utils/getOrderId";
import { state } from "../redux/util/getStore";

const useSelectorStore = () => {
  const getGuestAddress = useSelector(
    (state) => state?.checkout?.getGuestShippingAddress?.data
  );

  const getShippingMethods = useSelector(
    (state) => state?.checkout?.getShippingMethod?.data
  );

  const allLocations = state("checkout")?.getAllLocations?.data;
  const deliverableLocations = state("checkout")?.getShippingLocations?.data;
  const orderInfo = useSelector(
    (state) => state?.persistSlice?.addToCart?.data
  );

  const orderDetail = useSelector((state) => state?.profile?.orderDetail?.data);

  const editShippingAddress = useSelector(
    (state) => state?.checkout?.editShippingAddress?.data
  );

  const deleteShippingAddress = useSelector(
    (state) => state?.checkout?.deleteShippingAddress?.data
  );
  const addShippingAddress = useSelector(
    (state) => state?.checkout?.addShippingAddress?.data
  );

  const shippingAddress = useSelector(
    (state) => state?.checkout?.getShippingAddress?.data
  );

  const pickupAddressInfo = useSelector(
    (state) => state?.checkout?.getPickupAddressInfo?.data
  );

  const userInfo = useSelector((state) => state?.auth?.getUser?.data);

  const paymentTypes = useSelector(
    (state) => state?.checkout?.getPaymentTypes?.data
  );

  const cartInfo = useSelector((state) => state?.cart?.getCart?.data);

  return {
    pickupAddressInfo,
    shippingAddress,
    addShippingAddress,
    deleteShippingAddress,
    editShippingAddress,
    orderInfo,
    allLocations,
    getGuestAddress,
    userInfo,
    cartInfo,
    paymentTypes,
    orderDetail,
    deliverableLocations,
    getShippingMethods,
  };
};

export default useSelectorStore;
