import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useAuth = () => {
  const auth = useSelector((state) => state?.AccessToken);
  // console.log(auth);
  const [token, setToken] = useState(null);
  useEffect(() => {
    if (auth) {
      setToken(Object.keys(auth).length > 0 ? auth : null);
    }
  }, [auth]);
  return token;
};

export default useAuth;
