import React, { useEffect, useState } from "react";
import TextFieldInput from "../../components/common/input/TextField";
import Button from "../../components/common/button/Button";
import { useFormik } from "formik";
import callApi from "../../services/api/apiClient";
import useAuth from "../../hooks/lang/useAuth";
import useDictionary from "../../hooks/lang/useDictionary";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ProfileEdit = ({ onClick, userInfo }) => {
  const newDate = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState("male");
  const [dob, setDob] = useState("");
  const token = useAuth();
  const { translate } = useDictionary();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setTimeout(() => {
        callApi("auth/editProfile")
          .withBody({
            name: values.name,
            email: values.email,
            gender: gender,
            dob: dob,
          })
          .loadingGroup(1)
          .executeDispatch();

        setIsLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
        onClick();
      }, 1000);
    },
  });

  useEffect(() => {
    if (userInfo) {
      const defaultValues = {
        name: userInfo?.name || "",
        email: userInfo?.email || "",
      };
      formik.setValues(defaultValues);
      setGender(userInfo?.gender || "male");
      setDob(moment(userInfo?.dob || new Date()).format("YYYY-MM-DD"));
    }
  }, [userInfo]);

  const handleRadioChange = (event) => {
    setGender(event.target.value);
  };

  return (
    <div className=" fixed w-full h-screen bg-[#333333c9] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className=" w-[95%] md:w-[550px] border-t-[20px] border-pink rounded-[5px] bg-white px-[20px] py-[10px] md:px-[50px] md:py-[30px]">
        <h4 className=" text-center mb-[10px]">
          {translate("edit-profile", "Edit Profile")}
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <TextFieldInput
              type={"text"}
              name={"name"}
              label={translate("name", "Name") + "*"}
              className={"w-full"}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <div className="mt-[10px]">
            <TextFieldInput
              type={"email"}
              name={"email"}
              label={translate("email", "Email")}
              className={"w-full"}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className=" mt-[8px]">
            <span className=" text-xs text-black-3 block font-muli">
              {translate("gender", "Gender")}
            </span>
            <div className="flex mb-3 w-full">
              <div className="w-1/2">
                <input
                  id="male"
                  type="radio"
                  value={"male"}
                  style={{ accentColor: "red" }}
                  checked={gender === "male"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="male" className="ml-2">
                  {translate("male", "Male")}
                </label>
              </div>
              <div className="w-1/2">
                <input
                  id="female"
                  type="radio"
                  value={"female"}
                  style={{ accentColor: "red" }}
                  checked={gender === "female"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="female" className="ml-2">
                  {translate("female", "Female")}
                </label>
              </div>
            </div>
          </div>

          <div className="mt-[25px]" style={{ lineHeight: 0 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="hundredPercentWidth"
                format="DD-MM-YYYY"
                defaultValue={dayjs(newDate)}
                label="Date of Birth"
              />
            </LocalizationProvider>
          </div>
          <div className="flex items-center gap-[35px] justify-center mt-[30px]">
            <Button
              type={"button"}
              className={
                "w-[150px] md:w-[200px] h-[40px] md:h-[47px] rounded-[8px] bg-black-1 hover:bg-black-2 text-lg"
              }
              onClick={onClick}
            >
              {translate("cancel", "Cancel")}
            </Button>
            <Button
              type={"submit"}
              className={
                "w-[150px] md:w-[200px] h-[40px] md:h-[47px] rounded-[8px] bg-red-3 hover:bg-red-4 text-lg text-white"
              }
            >
              {translate("save", "Save")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileEdit;
