const getBreadcrumb = (category_id, categories, path = []) => {
  for (let i = 0; i < categories?.length; i++) {
    const currentElement = categories[i];
  // console.log(currentElement, "BrandCurrentElement")

    const newPath = [...path, {id: currentElement?.category_id,name: currentElement?.category_name}];
    if (currentElement.category_id == category_id) {
      return { path: newPath, targetCategoryObj: currentElement };
    } else {
      const subBreadcrumb = getBreadcrumb(category_id, currentElement?.sub_category, newPath);
      if (subBreadcrumb) {
        return subBreadcrumb;
      }
    }
  }
  return null;
}

export default getBreadcrumb