import React, { Fragment, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import styled from "./filterProduct.module.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BsTagsFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import useDictionary from "../../../hooks/lang/useDictionary";
import Images from "../../../assets/images";

const FilterProduct = ({
  item,
  range,
  type = "brand",
  setBrandId,
  brandId,
  filterBrand,
  setFilterBrand,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {

  // }, []);

  const category_id = params.get("category_id");
  const brand_id = params.get("brand_id");
  const brandName = params.get("name");
  const navigate = useNavigate();
  const { translate } = useDictionary();

  const handleFilter = (event) => {
    const str = range[event.target.value];
    // console.log(typeof str); // Verify that str is a string
    // const numbers = str.match(/\d+/g).map(Number);
    setFilterBrand(event.target.value);
    // console.log(numbers);
  };
  console.log(item, "setBrandId")
  return (
    <div className="bg-white w-full rounded-[8px] p-5 md:py-5 lg:py-10">
      <div className="flex items-center py-3 border-b-2 border-b-black-1">
        <h5>{translate("filter", "Filter")}</h5>
      </div>
      <div className="flex items-center gap-3 pt-5">
        <BsTagsFill size={18} />
        <p className="title1">{translate("brand", "Brand")}</p>
      </div>
      {type === "brand" ? (
        <div
          className={
            window.innerWidth > 991 ? styled.buttonCon : styled.mbButtonCon
          }
        >
          {item?.map((brand, index) => (
            <button
              className={`${
                brand.ID.toString() === brand_id.toString()
                  ? styled["filterButton"] + " " + styled.active
                  : styled.filterButton
              } overflow-hidden`}
              key={index}
              onClick={() => {
                // navigate(`/brand/${brand.ID}?name=${brand.name}&pagename=brand`)
                navigate(`/filter?brand_id=${brand.ID}`);
              }}
            >
              <img
                src={brand.image || Images.defaultImg}
                alt={brand.name}
                title={brand.name}
              />
            </button>
          ))}
        </div>
      ) : (
        <div
          className={
            window.innerWidth > 991 ? styled.buttonCon : styled.mbButtonCon
          }
        >
          {item?.map((brand, index) => (
            <button
              className={`${
                brand.ID == brandId
                  ? styled["filterButton"] + " " + styled.active
                  : styled.filterButton
              } overflow-hidden`}
              key={index}
              onClick={() => {
                setBrandId(brand.ID);
                if (searchParams.has("brand_id")) {
                  const brand_id = searchParams.get("brand_id");
                  if (brand_id) {
                    searchParams.delete("brand_id");
                    setSearchParams(searchParams);
                  }
                }
                navigate(
                  `/filter` + window.location.search + `&brand_id=${brand.ID}`
                );
              }}
            >
              <img
                src={brand.image || Images.defaultImg}
                alt={brand.name}
                title={brand.name}
              />
            </button>
          ))}
        </div>
      )}
      {range && (
        <>
          <div className="flex items-center gap-3 mb-3 mt-8">
            <FaDollarSign size={18} />
            <p className="title1">{translate("price-range", "Price Range")}</p>
          </div>
          <FormControl
            sx={{
              minWidth: "100%",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "red",
              },
              "& .MuiOutlinedInput-notchedOutline": { height: 55 },
              "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
                fontFamily: "MuliRegular",
              },
              "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                {
                  fontFamily: "MuliRegular",
                },
            }}
          >
            <Select value={filterBrand} onChange={handleFilter} displayEmpty>
              <MenuItem disabled value="">
                <em className="body">
                  {translate("select-price-range", "Select price range")}
                </em>
              </MenuItem>
              {range?.map((range, index) => (
                <MenuItem value={index} className="body" key={index}>
                  {range}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
};
export default FilterProduct;
