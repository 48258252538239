import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import Images from "../../assets/images/index";
import ProductCard from "../../components/common/card/ProductCard";
import styled from "./promotion.module.css";
import { useLocation } from "react-router-dom";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";

const data = [
  {
    id: 1,
    name: "promotion",
  },
];

const PromotionPage = () => {
  const { pathname, search } = useLocation();
  const [img, setImg] = useState("");
  const params = new URLSearchParams(search);
  const promoId = params.get("promoId");
  const promoType = params.get("promoType");

  // get data
  const promotionInfo = useSelector(
    (state) => state?.products?.getPromotionDetail?.data
  );
  const promotionGroupInfo = useSelector(
    (state) => state?.products?.getProductsGroupDetail?.data
  );
  // console.log("promotion info...", promotionGroupInfo);

  useEffect(() => {
    if (promoId) {
      if (promoType === "group") {
        callApi("products/getProductsGroupDetail")
          .withSegment(`${promoId}/products`)
          .loadingGroup("promotion")
          .executeDispatch();
      } else {
        callApi("products/getPromotionDetail")
          .withSegment(`${promoId}/products`)
          .loadingGroup("promotion")
          .executeDispatch();
      }
    }
  }, [promoId]);
  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  // useEffect(() => {
  //   if (promotionInfo) {
  //     setImg(promotionInfo?.promotion_banner);
  //   }

  //   return () => {
  //     setImg("");
  //   };
  // }, [promotionInfo, img]);
  return (
    <LoadingComponent loadingGroup={"promotion"}>
      <div className=" w-full min-h-[50vh] bg-silver">
        <Breadcrumb
          title={
            promoId === "group" ? promotionGroupInfo?.name : promotionInfo?.name
          }
          item={data}
        />
        <div className="container mx-auto py-[40px]">
          {promoType === "group" ? (
            <div className="">
              <img
                src={promotionGroupInfo?.promotion_banner}
                alt=""
                className=" w-full"
              />

              {/* {window.innerWidth > 991 ? (
                  <img src={img} alt="" className=" w-full" />
                ) : (
                  <img src={img} alt="" className=" w-full" />
                )} */}
            </div>
          ) : (
            <div className="">
              <img
                src={promotionInfo?.promotion_banner}
                alt=""
                className=" w-full"
              />

              {/* {window.innerWidth > 991 ? (
                <img src={img} alt="" className=" w-full" />
              ) : (
                <img src={img} alt="" className=" w-full" />
              )} */}
            </div>
          )}
          <div className="">
            <h4 className=" my-[30px]">
              {promoType === "group"
                ? promotionGroupInfo?.name
                : promotionInfo?.name}
            </h4>
            <span className="body block mb-[30px]">
              {promoType === "group"
                ? promotionGroupInfo?.description
                : promotionInfo?.description}
            </span>
            {promoType === "group" ? (
              <div className={styled.promo_list}>
                {promotionGroupInfo?.product_list?.map((item, id) => (
                  <ProductCard item={item} key={id} />
                ))}
              </div>
            ) : (
              <div className={styled.promo_list}>
                {promotionInfo?.product_list?.map((item, id) => (
                  <ProductCard item={item} key={id} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </LoadingComponent>
  );
};

export default PromotionPage;
