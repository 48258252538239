import React, { useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import useAuth from "../../hooks/lang/useAuth";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { state } from "../../redux/util/getStore";
import { dispatch } from "../../redux/util/dispatchStore";
import { ProfilePopupSlice } from "../../helper/customSlice";

const ProtectRoute = ({ children, isAccessToken = false }) => {
  const aToken = state("AccessToken");
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  function handleClickOutside(event) {
    dispatch(ProfilePopupSlice.actions.setShowMbProfile(false));
  }

  if (isAccessToken) {
    if (Object.keys(aToken).length != 0) {
      return (
        <>
          <Header />
          <div onClick={handleClickOutside} className=" min-h-[60vh]">
            {children}
          </div>
          <Footer />
        </>
      );
    } else {
      return <Navigate to={"/"} />;
    }
  } else {
    return (
      <>
        <Header />
        <div className=" min-h-[60vh]">{children}</div>
        <Footer />
      </>
    );
  }
};

export default ProtectRoute;
