import React, { useCallback } from "react";
import TextFieldInput from "../../components/common/input/TextField";
import Button from "../../components/common/button/Button";
import useDictionary from "../../hooks/lang/useDictionary";
import { BsPlus } from "react-icons/bs";
import InputSelect from "../../components/common/input/InputSelect";
import MapDirection from "./MapDirection";
import useSelectorStore from "../../hooks/useSelectorStore";
import LoadingComponent from "../../components/loading/LoadingComponent";
import PickupAddressForm from "./PickupAddressForm";
import AddShippingAddressForm from "./AddShippingAddressForm";
import { useEffect } from "react";
import callApi from "../../services/api/apiClient";
import useLanguage from "../../hooks/lang/useLanguage";

const LoginUserInfo = (props) => {
  const {
    shippingAddress,
    pickupAddressInfo,
    addShippingAddress,
    deleteShippingAddress,
    editShippingAddress,
  } = useSelectorStore();
  const { translate } = useDictionary();
  const {
    isDelivery,
    phones,
    formik,
    checkAddress,
    setCheckAddress,
    addressActions,
    setAddressActions,
    setRemarkId,
  } = props;
  const language = useLanguage();

  // retrieve shippingAddress datas
  useEffect(() => {
    callApi("checkout/getShippingAddress")
      .withParam({ lang: language })
      .loadingGroup("address")
      .executeDispatch();
  }, [addShippingAddress, deleteShippingAddress, editShippingAddress]);

  useEffect(() => {
    if (shippingAddress) {
      setTimeout(() => {
        setCheckAddress(
          shippingAddress?.find(
            (address) => address?.default_shipping_address === true
          )?.id
        );
      }, 500);
    }
  }, [shippingAddress, addShippingAddress, isDelivery]);

  return (
    <>
      {isDelivery ? (
        <LoadingComponent
          loadingGroup={"getCart-delivery"}
          loadingDesign={<div></div>}
        >
          <>
            {/* for member ui  */}

            {shippingAddress?.length > 0 ? (
              <>
                <p className="body_14 text-red-2 mt-[30px] mb-[20px]">
                  *
                  {translate(
                    "choose-delivery-address",
                    "Please choose delivery address"
                  )}
                </p>
                <div className="">
                  {shippingAddress?.map((address, id) => (
                    <div className="relative" key={id}>
                      <div className="flex items-center border-b-[1px] border-gray-200 rounded ">
                        <input
                          id={`radio-address-${address?.id}`}
                          type="radio"
                          defaultValue=""
                          name="radio"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                          checked={checkAddress === address?.id}
                          onChange={() => setCheckAddress(address?.id)}
                        />
                        <label
                          htmlFor={`radio-address-${address?.id}`}
                          className="w-full py-4 ml-2 text-sm font-medium text-gray-900 "
                        >
                          <p className="body">{address?.name}</p>
                          <p className="body">{address?.mobile}</p>
                          <p className="body">
                            {address?.address1}, {address?.township["name"]},{" "}
                            {address?.state["name"]}
                          </p>
                        </label>
                      </div>
                      <div className=" absolute top-4 right-0 flex gap-[12px]">
                        <Button
                          className={"text-sky_blue text-sm sm:text-base"}
                          onClick={() => {
                            setRemarkId(address?.id);
                            setAddressActions({
                              ...addressActions,
                              isEdit: true,
                            });
                          }}
                        >
                          {translate("edit", "Edit")}
                        </Button>
                        <Button
                          className={`text-sm sm:text-base ${checkAddress === address?.id && " opacity-50"
                            }`}
                          onClick={() => {
                            setRemarkId(address?.id);
                            setAddressActions({
                              ...addressActions,
                              isDelete: true,
                            });
                          }}
                          disabled={checkAddress === address?.id}
                        >
                          {translate("delete", "Delete")}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className=" h-[52px] border-[1px] border-sky_blue border-dashed rounded-[5px] text-sky_blue flex justify-center gap-2 items-center mt-[16px] cursor-pointer"
                  onClick={() =>
                    setAddressActions({ ...addressActions, isAdd: true })
                  }
                >
                  <BsPlus size={20} />
                  {translate("add-address", "Add Address")}
                </div>
              </>
            ) : (
              <>
                <p className="body mt-[30px] mb-[15px]">
                  *
                  {translate(
                    "fill-delivery-information",
                    "Please fill delivery information"
                  )}
                </p>
                {Object.keys(formik.values).length >= 5 && (
                  <>
                    <div className=" text-red-3 mb-3 w-full h-[33px] ps-3 bg-[#FFF0F1] flex items-center rounded-[5px]">
                      {translate("please-save-field", "* Please save your fields !")}
                    </div>
                  </>
                )}

                {/* for normal user ui  */}

                <AddShippingAddressForm formik={formik} />
              </>
            )}
          </>
        </LoadingComponent>
      ) : (
        <LoadingComponent
          loadingGroup={"getCart-pickup"}
          loadingDesign={<div></div>}
        >
          <>
            <div className=" py-7">
              <p className=" title1 mb-3">
                {translate("pickup-address", "Pickup Address")}
              </p>
              {pickupAddressInfo?.address1 ? (
                <div className=" flex">
                  <span className=" title2 me-5">
                    {translate("address", "Address")}:
                  </span>{" "}
                  <div className="flex flex-col">
                    <span>{pickupAddressInfo?.address1}</span>
                    <MapDirection
                      name={"Get Direction"}
                      shopLocation={{
                        lat: pickupAddressInfo?.latitude,
                        lng: pickupAddressInfo?.longitude,
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {pickupAddressInfo?.mobile ? (
                <div className=" mb-2">
                  <span className=" title2 me-3">
                    {translate("phone", "Phone")}:
                  </span>{" "}
                  {phones?.map((phone, index) => (
                    <a
                      className="ml-[5px]"
                      href={`tel:09${phone?.split("-")[1]}`}
                      key={phone}
                    >
                      {phone}
                    </a>
                  ))}
                  {/* {pickupAddressInfo?.mobile} */}
                </div>
              ) : null}

              {pickupAddressInfo?.email ? (
                <p>
                  <span className=" me-3">{translate("email", "Email")}</span>{" "}
                  {pickupAddressInfo?.email}
                </p>
              ) : null}
            </div>

            <>
              <p className="body_14 text-red-2 mt-[30px] mb-[20px]">
                *
                {translate(
                  "fill-contact-information",
                  "Please fill contact information"
                )}
              </p>

              {/* for login user pickup address form ui  */}

              <PickupAddressForm formik={formik} />
            </>
          </>
        </LoadingComponent>
      )}
    </>
  );
};

export default LoginUserInfo;
