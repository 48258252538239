import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import callApi from "../../services/api/apiClient";
import useLanguage from "../../hooks/lang/useLanguage";
import { useSelector } from "react-redux";
import Button from "../../components/common/button/Button";
import ProductCard from "../../components/common/card/ProductCard";
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
// import Pagination from "../../components/common/pagination/pagination";
import Pagination from "@mui/material/Pagination";

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const name = params.get("name");
  const language = useLanguage();
  const { translate } = useDictionary();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const handleSearch = () => {
    if (search) {
      navigate(`/search?name=${search}`);
      setSearch("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const products = useSelector(
    (state) => state?.products?.getAllProducts?.data
  );

  useEffect(() => {
    callApi("products/getAllProducts")
      .withParam({
        lang: language,
        keywords: name,
        page_number: page,
        per_page: 12,
      })
      // .loadingGroup("products")
      .executeDispatch();
  }, [language, name, page]);
  return (
    <LoadingComponent loadingGroup={"products"}>
      <div className=" container mx-auto min-h-[70vh]">
        <div className=" flex gap-[10px] md:gap-[32px] my-[20px] pt-5">
          <div className=" w-full relative">
            <input
              type="text"
              placeholder={name}
              className=" h-[47px] px-3 border focus:outline-none w-full rounded-[5px]"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            {search && (
              <BsSearch
                className=" absolute top-3 right-4 text-[20px] cursor-pointer"
                onClick={handleSearch}
              />
            )}
          </div>
          <Button
            type={"button"}
            className={" w-[200px] h-[47px] bg-[#DEDEDE] rounded-[10px]"}
            onClick={handleSearch}
          >
            {translate("search", "Search")}
          </Button>
        </div>
        <div className="">
          <p className=" font-semibold">
            {translate("search-result-for", "Search Result for ")} "{name}"
          </p>
          <p>
            {translate("items", "Items")} : {products?.total_products}
          </p>
        </div>
        {products?.product_list?.length > 0 ? (
          <div className="mb-[30px]">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[30px] my-[30px]">
              {products?.product_list?.map((data, id) => (
                <ProductCard key={id} item={data} />
              ))}
            </div>
            {products?.product_list?.length > 0 &&
              Math.ceil(products?.total_products / 12) > 1 && (
                <div className="lg:ml-[20px]">
                  {/* <Pagination
                    value={page}
                    onChange={setPage}
                    total={Math.ceil(products?.total_products / 12)}
                  /> */}
                  <Pagination
                    page={page}
                    onChange={(event, value) => {
                      window.scrollTo({ top: 0, left: 0 })
                      setTimeout(() => {
                        setPage(value)
                      }, 700);
                    }}
                    count={Math.ceil(products?.total_products / 12)}
                    variant="outlined"
                    shape="rounded"
                  />
                </div>
              )}
          </div>
        ) : (
          <div className=" flex flex-col items-center justify-center gap-[10px] min-h-[30vh]">
            <BsSearch size={65} className=" text-gray-500 mt-[50px]" />
            <p className=" md:text-[20px] font-semibold mt-9">
              {translate("no-result", "Search No Result")}
            </p>
            <p className=" mt-3">
              {translate(
                "product-not-found-mb",
                "We're sorry. We cannot find any matches for your search term."
              )}
            </p>
          </div>
        )}
      </div>
    </LoadingComponent>
  );
};

export default SearchPage;
