import styled from "./checkout.module.css";
import useDictionary from "../../hooks/lang/useDictionary";
import TextArea from "../../components/common/input/TextArea";
import FileUploader from "./FileUploader";
import Button from "../../components/common/button/Button";
import useMetaDatas from "../../hooks/useMetaDatas";
import useSelectorStore from "../../hooks/useSelectorStore";
import { useState } from "react";
import callApi from "../../services/api/apiClient";
import { useDispatch } from "react-redux";
import { slice } from "../../redux/reducers/reducer";
import useLanguage from "../../hooks/lang/useLanguage";

const CheckoutPayment = (props) => {
  const {
    setProductErr,
    setIsOrder,
    setOrderData,
    isDelivery,
    formik,
    setIsPayNow,
  } = props;
  const {
    userInfo,
    paymentTypes,
    orderInfo,
    shippingAddress,
    getGuestAddress,
  } = useSelectorStore();
  const { translate } = useDictionary();
  const { getMetaData } = useMetaDatas();
  const paymentTextB2B = getMetaData("b2b-payment-text");
  const paymentTextB2C = getMetaData("b2c-payment-text");
  const language = useLanguage();

  // states
  const [isPay, setIsPay] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [requestMessage, setRequestMessage] = useState("");
  const [Checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const handleClear = () => {
    dispatch(slice.persistSlice.actions.clearSliceElement("addToCart"));
  };

  const clearCarts = () => {
    dispatch(slice.cart.actions.clearSliceElement("getCart"));
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("payslip_image", selectedFile);
    callApi("checkout/uploadFile")
      .withSegment(`${orderInfo?.id}/payment_slip`)
      .withBody(formData)
      .loadingGroup("file")
      .executeDispatch();
  };

  const handlePayNow = () => {
    setIsPayNow(true);
    let datas = {
      order_id: orderInfo?.id,
      acquirer_id: isPay?.id,
      special_request: requestMessage,
    };
    callApi("checkout/payNow")
      .withBody(datas)
      .loadingGroup("checkout")
      .execute()
      .then((res) => {
        if (res?.status === "success") {
          if (selectedFile) {
            handleFileUpload();
          }
          setOrderData(res);
          setIsOrder(true);
          handleClear();
          clearCarts();
        } else {
          setProductErr(res);
          setTimeout(() => {
            setProductErr(false);
          }, 5000);
        }
      });
  };

  const handleOrderClick = () => {
    if (isDelivery) {
      if (shippingAddress?.length > 0 || getGuestAddress) {
        handlePayNow();
      } else {
        window.scrollTo(400, 300);
      }
    } else {
      const datas = {
        order_id: orderInfo?.id,
        name: formik.values.name,
        phone: formik.values.phone,
      };

      if (formik.values.division) datas.state_id = formik.values.division;
      if (formik.values.township) datas.township_id = formik.values.township;
      if (formik.values.address) datas.street = formik.values.address;

      callApi("checkout/addPickupUserInfo")
        .withBody(datas)
        .loadingGroup("address")
        .execute()
        .then((res) => {
          console.log(res);
          if (res?.status === "success") {
            handlePayNow();
            formik.resetForm();
          } else {
            window.scrollTo(500, 400);
          }
        });
    }
  };

  return (
    <div className="min-h-[200px] sm:border-l-[19px] border-red-1 p-[15px] md:p-[30px] bg-white mt-[20px] rounded-[8px]">
      <h5>
        {translate("choose-payment", "Choose Payment")} <br />{" "}
      </h5>
      <div className=" flex flex-col gap-[20px] mt-[25px]">
        {userInfo?.user_type === "b2b" ? (
          <>
            <p className="">{paymentTextB2B?.text_one}</p>
            {/* for b2b user  */}
            {paymentTypes?.map((payment, id) => (
              <div
                className={`flex h-[75px] sm:h-[60px] items-center justify-between p-[15px] border ${isPay?.code === payment?.code
                  ? " border-red-4"
                  : "border-gray-200"
                  } rounded-[5px]`}
                key={id}
              >
                <>
                  <input
                    id={`radio-pay-${id}`}
                    type="radio"
                    checked={isPay?.code == payment?.code}
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    onChange={() => setIsPay(payment)}
                  />
                  <label htmlFor={`radio-pay-${id}`} className={styled.label}>
                    {payment?.name} <br />
                    {payment?.account_no || null}{" "}
                    {payment?.account_name
                      ? `(${payment?.account_name})`
                      : null}
                  </label>
                </>
                <div className="">
                  <img src={payment?.image} alt="" className=" h-[55px]" />
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <p className="">{paymentTextB2C?.text_one}</p>
            {paymentTypes?.map((payment, id) => (
              <div
                className={`flex h-[75px] sm:h-[60px] items-center justify-between p-[15px] border ${isPay?.code === payment?.code
                  ? " border-red-4"
                  : "border-gray-200"
                  } rounded-[5px]`}
                key={id}
              >
                <>
                  <input
                    id={`radio-pay-${id}`}
                    type="radio"
                    checked={isPay?.code == payment.code}
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    onChange={() => setIsPay(payment)}
                  />
                  <label htmlFor={`radio-pay-${id}`} className={styled.label}>
                    {payment?.name}
                  </label>
                </>
                <div className="">
                  <img src={payment?.image} alt="" className=" h-[55px]" />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {userInfo?.user_type !== "b2b" && isPay?.payment_image && (
        <>
          <div className=" w-fit p-[10px] rounded-[13px] border mx-auto my-[20px]">
            <img
              src={`${isPay?.payment_image}`}
              alt=""
              className=" w-[170px] h-[170px]"
            />
          </div>
          <p className="flex justify-center body">
            {translate("scan-qr-for-payment", "Scan QR for payment")}
          </p>
        </>
      )}
      {isPay?.code !== "COD" && (
        <div className="mt-[20px]">
          <FileUploader
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </div>
      )}
      <div className=" mt-[20px]">
        <TextArea
          rows={3}
          name="request"
          label={translate("special_request", "Special Request (Note)")}
          className={
            "w-full p-3 border rounded-[5px] focus:outline-none focus:border-black-4"
          }
          value={requestMessage}
          onChange={(e) => setRequestMessage(e.target.value)}
        />
      </div>
      <div className="flex text-sm font-medium text-gray-600">
        <input
          id="checkbox"
          type="checkbox"
          defaultValue=""
          required
          onClick={() => setChecked(!Checked)}
          className="mr-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mt-1"
        />
        {language === "en" ? (
          <div>
            {translate("i-have-read-and-accept", "I've read and accept the")}{" "}
            <Button
              className="text-sm font-medium text-red-3"
              onClick={() => window.open("/terms-conditions", "blank")}
            >
              {translate("terms-and-conditions", "Terms & Conditions")}{" "}*
            </Button>
          </div>
        ) : (
          <div>
          {translate("i-have-read-and-accept", "I've read and accept the")}{" "}
            <Button
              className="text-sm font-medium text-red-3"
              onClick={() => window.open("/terms-conditions", "blank")}
            >
              {translate("terms-and-conditions", "Terms & Conditions")}
            </Button>
            {" "}*
          </div>
        )}
      </div>
      <div className=" mt-[35px]">
        <Button
          className={`w-full ${(isPay && Checked) ? "bg-red-3" : "bg-red-1"
            } h-[50px] text-white rounded-[8px]`}
          onClick={handleOrderClick}
          disabled={(isPay && Checked) ? false : true}
        >
          {translate("place-order", "Place Order")}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutPayment;
