import { res_modifiers_config } from "../../helper/res_modifiers_config";

// Define the API URLs for different environments
export const API_URLs = {
  development: "http://npt.innovixcommerce.com",
  devServer: "http://npt.innovixcommerce.com",
  uatServer: "http://npt-internal-uat.innovixerp.com",
  goldServer: "http://npt-internal-uat.innovixerp.com",
  // production: "http://52.221.97.234:8093",
  // development: "http://52.221.97.234:8093",
};

export const endpoints = Object.freeze({
  auth: {
    signUp: {
      method: "post",
      endpoint: "api/user/signup",
      // res_modifier: res_modifiers_config.getAccessToken,
      // token: "optional", // require, optional
      // expire_in: 10
    },
    verify: {
      method: "post",
      endpoint: "api/user/verify",
    },
    login: {
      method: "get",
      endpoint: "api/auth/token",
    },
    logout: {
      method: "delete",
      endpoint: "api/auth/token",
    },
    checkUser: {
      method: "post",
      endpoint: "api/exist/user",
      token: "optional",
    },
    resetPassword: {
      method: "post",
      endpoint: "api/user/reset/password",
    },
    changePassword: {
      method: "post",
      endpoint: "api/password/change",
      token: "optional",
    },
    getUser: {
      method: "get",
      endpoint: "api/user",
      token: "require",
    },
    editProfile: {
      method: "put",
      endpoint: "api/user",
      token: "optional",
    },
    memberType: {
      method: "get",
      endpoint: "api/member/type",
    },
  },
  products: {
    getAllProducts: {
      method: "get",
      endpoint: "api/product",
      token: "optional",
    },
    getProductDetail: {
      method: "get",
      endpoint: "api/product",
      token: "optional",
    },
    MyCategories: {
      method: "get",
      endpoint: "api/product/categories",
      token: "optional",
    },
    getSingleCategory: {
      method: "get",
      endpoint: "api/product",
      token: "optional",
    },
    getProductsGroup: {
      method: "get",
      endpoint: "api/group",
      token: "optional",
    },
    getProductsGroupDetail: {
      method: "get",
      endpoint: "api/group/promotion",
      token: "optional",
    },
    getBrandsList: {
      method: "get",
      endpoint: "api/product/brands",
      token: "optional",
    },
    getHighlightBrands: {
      method: "get",
      endpoint: "api/highlight/brands",
      token: "optional",
    },
    getAllPromotion: {
      method: "get",
      endpoint: "api/list/promotion",
      token: "optional",
    },
    getPromotionDetail: {
      method: "get",
      endpoint: "api/promotion",
      token: "optional",
    },
    checkQuantity: {
      method: "post",
      endpoint: "api/product/check/quantity",
      token: "optional",
    },
  },
  wishList: {
    addToWishList: {
      method: "post",
      endpoint: "api/wishlist/add",
      token: "require",
    },
    isExistWishList: {
      method: "get",
      endpoint: "api/exist/wishlist",
      token: "require",
    },
    getAllWishLists: {
      method: "get",
      endpoint: "api/wishlist",
      token: "require",
    },
    deleteWishList: {
      method: "delete",
      endpoint: "api/wishlist/remove",
      token: "require",
    },
  },
  cart: {
    getCart: {
      method: "get",
      endpoint: "api/cart",
      token: "optional",
    },
    updateQtyCart: {
      method: "post",
      endpoint: "api/cart/quantity/update",
      token: "optional",
    },
    removeCart: {
      method: "delete",
      endpoint: "api/cart/remove",
      token: "optional",
    },
    clearCart: {
      method: "delete",
      endpoint: "api/clear/cart",
      token: "optional",
    },
  },
  checkout: {
    addShippingAddress: {
      method: "post",
      endpoint: "api/user/address/add",
      token: "optional",
    },
    editShippingAddress: {
      method: "put",
      endpoint: "api/user/address",
      token: "optional",
    },
    deleteShippingAddress: {
      method: "delete",
      endpoint: "api/user/address",
      token: "optional",
    },
    getShippingAddress: {
      method: "get",
      endpoint: "api/user/address",
      token: "optional",
    },
    getGuestShippingAddress: {
      method: "get",
      endpoint: "api/guest/address",
      token: "optional",
    },
    getSingleAddress: {
      method: "get",
      endpoint: "api/user/address",
    },
    getShippingLocations: {
      method: "get",
      endpoint: "api/delivery/locations/mm",
      token: "optional",
    },
    getPaymentTypes: {
      method: "get",
      endpoint: "api/payments",
    },
    useShippingAddressForOrder: {
      method: "post",
      endpoint: "api/order/shippingaddress",
      token: "optional",
    },
    payNow: {
      method: "post",
      endpoint: "api/paynow",
      token: "optional",
    },
    uploadFile: {
      method: "post",
      endpoint: "api/order",
      token: "optional",
    },
    setDefaultAddress: {
      method: "post",
      endpoint: "api/user/default_address",
      token: "require",
    },
    addPickupUserInfo: {
      method: "post",
      endpoint: "api/user/pickup_user_info",
      token: "optional",
    },
    getPickupAddressInfo: {
      method: "get",
      endpoint: "api/shipping/byCode",
    },
    getShippingMethod: {
      method: "get",
      endpoint: "api/shipping_address",
      token: "optional",
    },
    getAllLocations: {
      method: "get",
      endpoint: "api/locations/mm",
    },
  },
  profile: {
    orderLists: {
      method: "get",
      endpoint: "api/orders",
      token: "require",
    },
    orderDetail: {
      method: "get",
      endpoint: "api/orders",
      token: "require",
    },
    orderStatus: {
      method: "get",
      endpoint: "api/order/status",
    },
  },
  others: {
    privacyPolicy: {
      method: "get",
      endpoint: "api/page/privacy_policy",
    },
    termsCondition: {
      method: "get",
      endpoint: "api/page/terms_and_condition",
    },
    faq: {
      method: "get",
      endpoint: "api/faq",
    },
    aboutUs: {
      method: "get",
      endpoint: "api/page/about",
    },
    contactUs: {
      method: "post",
      endpoint: "api/save/contactinformation",
    },
    faceBookSignIn: {
      method: "get",
      endpoint: "/api/auth/facebook",
    },
    genericPageInfo: {
      method: "get",
      endpoint: "/api/page/",
    },
    getBannerImage: {
      method: "get",
      endpoint: "api/image/banner",
    },
    getSliderImage: {
      method: "get",
      endpoint: "api/image/slider/home-slider",
    },
    getMobileSliderImage: {
      method: "get",
      endpoint: "api/image/slider/home-slider-mobile",
    },
    getDictionary: {
      method: "get",
      endpoint: "api/dictionary",
    },
    getStores: {
      method: "get",
      endpoint: "api/store",
    },
    getContactInfo: {
      method: "get",
      endpoint: "api/contact_info",
    },
    getMetaData: {
      method: "get",
      endpoint: "api/application/meta",
    },
  },
  persistSlice: {
    addToCart: {
      method: "post",
      endpoint: "api/cart/add",
      token: "optional",
      res_modifier: res_modifiers_config.getOrderId,
    },
  },
});

export const productGroup = [
  "newarrival",
  "bestseller",
  "hotitems",
  "popularcategory",
  "highlighBrand",
];
