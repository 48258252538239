import React from "react";
import CheckoutSummary from "./CheckoutSummary";
import CheckoutInfo from "./CheckoutInfo";
import { useState } from "react";
import LoadingComponent from "../../components/loading/LoadingComponent";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import CheckoutPayment from "./CheckoutPayment";
import { useEffect } from "react";
import callApi from "../../services/api/apiClient";
import AlertPopup from "../../components/loading/AlertPopup";
import useDictionary from "../../hooks/lang/useDictionary";
import ShowOrderSuccess from "./ShowOrderSuccess";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import useSelectorStore from "../../hooks/useSelectorStore";

const shippingSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  division: Yup.string().required("Division is required"),
  township: Yup.string().required("Township is required"),
  address: Yup.string().required("Address is required"),
});

const CheckoutPage = () => {
  const { cartInfo } = useSelectorStore();
  const { translate } = useDictionary();
  const [checkAddress, setCheckAddress] = useState(null);
  const [isDelivery, setIsDelivery] = useState(true);
  const [isOrder, setIsOrder] = useState(false);
  const [isPayNow, setIsPayNow] = useState(false);
  const [productErr, setProductErr] = useState(null);
  const [orderData, setOrderData] = useState(null);

  console.log(cartInfo)

  if(cartInfo){
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      division: "",
      township: "",
      address: "",
      request: "",
    },
    validationSchema: shippingSchema,
    onSubmit: (values, actions) => {
      const datas = {
        name: values.name,
        mobile: values.phone,
        state_id: values.division,
        township_id: values.township,
        street: values.address,
        country_id: 145,
      };
      callApi("checkout/addShippingAddress")
        .withBody(datas)
        .loadingGroup("add_address")
        .executeDispatch();

      actions.setSubmitting(false);
      actions.resetForm();
    },
  });

  const infoProps = {
    checkAddress,
    setCheckAddress,
    isDelivery,
    setIsDelivery,
    formik,
    isPayNow,
  };

  const navigate = useNavigate();
  console.log("checkout render");

  useEffect(() => {
    callApi("checkout/getPaymentTypes")
      .loadingGroup("checkout")
      .executeDispatch();
  }, []);

  const paymentProps = {
    setProductErr,
    setOrderData,
    setIsOrder,
    isDelivery,
    formik,
    setIsPayNow,
  };

  return (
    <LoadingComponent loadingGroup={"checkout"}>
      {productErr && (
        <AlertPopup
          route={"/"}
          btnLabel={translate("go-to-shopping", "Go to Shopping")}
          text={productErr?.message}
        />
      )}
      <div className="bg-black-0">
        <Breadcrumb title={"checkout"} />
        {/* for all  popup  */}
        {isOrder && (
          <ShowOrderSuccess
            onClick={() => {
              navigate("/");
              setIsOrder(false);
            }}
            data={{
              orderId: orderData?.order_no,
              total_amount: orderData?.total_amount,
            }}
          />
        )}
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-5 py-[32px] lg:gap-[30px]">
          <div className="lg:col-span-3">
            <CheckoutInfo {...infoProps} />
            <CheckoutPayment {...paymentProps} />
          </div>

          {/* for summary ui  */}
          <CheckoutSummary />
        </div>
      </div>
    </LoadingComponent>
  );
};

export default CheckoutPage;
