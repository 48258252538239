import React, { useEffect } from "react";
import AppLayout from "./routers";
import callApi from "./services/api/apiClient";
import useLanguage from "./hooks/lang/useLanguage";
import "./assets/css/main.css";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "./components/common/button/Button";
import CloseIcon from "@mui/icons-material/Close";
import useDictionary from "./hooks/lang/useDictionary";

const App = () => {
  const { translate } = useDictionary();
  function openAlert() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className=" fixed w-full h-screen bg-[#333333e5] top-0 left-0 right-0 z-50 flex justify-center items-center">
            <div className=" w-[90%] md:w-[450px] min-h-[150px] bg-white border-t-[10px] border-red-1 rounded-[5px] p-[20px] md:p-[40px] relative">
              <div className="">
                <p className="title2 flex justify-center mt-[10px]">
                  {translate("expired_token_msg", "User Token is expired")}
                </p>
              </div>
              <div className="flex justify-between mt-[30px] gap-[30px]">
                <Button
                  className={
                    "w-full p-[12px] rounded-[10px] bg-black-1 hover:bg-black-2"
                  }
                  onClick={() => window.location.replace("/")}
                >
                  {translate("home", "Home")}
                </Button>
                <Button
                  className={
                    "w-full p-[12px] rounded-[10px] bg-red-3 hover:bg-red-4 text-white"
                  }
                  onClick={() => window.location.replace("/login")}
                >
                  {translate("login", "Login")}
                </Button>
              </div>
              <Button className=" absolute top-2 right-3">
                <CloseIcon style={{ fontSize: 18 }} />
              </Button>
            </div>
          </div>
        );
      },
    });
  }

  const language = useLanguage();
  const ExpireAlertBox = useSelector((state) => state?.ExpireAlertBox);
  useEffect(() => {
    callApi("others/getDictionary")
      .loadingGroup("dictionary")
      .executeDispatch();
  }, []);
  useEffect(() => {
    callApi("others/getMetaData")
      .withParam({ lang: language })
      .executeDispatch();
  }, [language]);

  useEffect(() => {
    if (ExpireAlertBox == true) {
      openAlert();
    }
    // console.log(ExpireAlertBox, "ExpireAlertBox")
  }, [ExpireAlertBox]);

  return (
    <>
      <AppLayout />
    </>
  );
};

export default App;
