import React from "react";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import LoadingComponent from "../../components/loading/LoadingComponent";

const BreadData = [
  {
    id: 1,
    name: "terms-and-conditions",
  },
];

const TermConditionPage = () => {
  const [data, setData] = useState({});

  const others = useSelector((state) => state.others?.termsCondition?.data);

  useEffect(() => {
    callApi("others/termsCondition").loadingGroup("t").executeDispatch();
  }, []);

  useEffect(() => {
    if (others) {
      setData(others);
    }
  }, [others]);

  return (
    <LoadingComponent loadingGroup={"t"}>
      <div className=" min-h-[30vh] bg-black-0">
        <Breadcrumb title={"terms-and-conditions"} item={BreadData} />
        <div className="container mx-auto py-[70px]">
          {data && (
            <span
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></span>
          )}
          {/* <div className="">
          <h4>Terms & Conditions</h4>
          <span className="body block my-[20px]">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </span>
        </div>
        <div className="">
          <p className="title1 mt-[40px]">Title 1</p>
          <span className="body block mt-[10px]">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </span>
        </div>
        <div className="">
          <p className="title1 mt-[40px]">Title 1</p>
          <span className="body block mt-[10px]">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </span>
        </div>
        <div className="">
          <p className="title1 mt-[40px]">Title 1</p>
          <span className="body block mt-[10px]">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </span>
        </div> */}
        </div>
      </div>
    </LoadingComponent>
  );
};

export default TermConditionPage;
