import React, { useEffect, useState } from "react";
import { GrDeliver } from "react-icons/gr";
import TextFieldInput from "../../components/common/input/TextField";
import InputSelect from "../../components/common/input/InputSelect";
import Button from "../../components/common/button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/lang/useAuth";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import useLanguage from "../../hooks/lang/useLanguage";
import useDictionary from "../../hooks/lang/useDictionary";
import { Checkbox } from "@mantine/core";
import Images from "../../assets/images/index";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { CircularProgress } from "@mui/material";

const shippingSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  division: Yup.string().required("Division is required"),
  township: Yup.string().required("Township is required"),
  address: Yup.string().required("Address is required"),
});

const ShippingAddressPopup = ({
  isAdd,
  onClick,
  addressId = null,
  type = "add",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [division, setDivision] = useState(null);
  const [township, setTownship] = useState(null);
  const [checked, setChecked] = useState(false);
  const token = useAuth();
  const language = useLanguage();
  const { translate } = useDictionary();
  const locations = useSelector(
    (state) => state?.checkout?.getShippingLocations?.data[0]
  );
  const shippingAddress = useSelector(
    (state) => state?.checkout?.getShippingAddress?.data
  );

  const getGuestAddress = useSelector(
    (state) => state?.checkout?.getGuestShippingAddress?.data
  );

  // console.log(addressId);

  const currentLocation = shippingAddress?.find(
    (address) => address.id == addressId
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      division: "",
      township: "",
      address: "",
    },
    validationSchema: shippingSchema,
    onSubmit: (values, actions) => {
      let data = {
        name: values.name,
        mobile: values.phone,
        state_id: values.division,
        township_id: values.township,
        street: values.address,
        country_id: 145,
      };

      if (checked == true) {
        data.set_default = true;
      }

      setIsLoading(true);
      setTimeout(() => {
        if (type == "add") {
          callApi("checkout/addShippingAddress")
            .withBody(data)
            .loadingGroup("add-address")
            .executeDispatch();
        } else {
          callApi("checkout/editShippingAddress")
            .withSegment(addressId)
            .withBody({
              name: values.name,
              mobile: values.phone,
              state_id: values.division,
              township_id: values.township,
              street: values.address,
              country_id: 145,
              // set_default: false,
            })
            .loadingGroup("add-address")
            .executeDispatch();
        }

        setIsLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
        onClick();
      }, 1000);
    },
  });

  useEffect(() => {
    if (locations) {
      setDivision(locations?.state_ids);
    }
  }, [locations]);

  useEffect(() => {
    if (type == "edit" && currentLocation) {
      const defaultValues = {
        name: currentLocation?.name || "",
        phone: currentLocation?.mobile || "",
        division: currentLocation?.state?.id || "",
        township: currentLocation?.township?.id || "",
        address: currentLocation?.address1 || "",
      };
      formik.setValues(defaultValues);
    } else {
      if (getGuestAddress) {
        const defaultValues = {
          name: getGuestAddress?.name || "",
          phone: getGuestAddress?.mobile || "",
          division: getGuestAddress?.state?.id || "",
          township: getGuestAddress?.township?.id || "",
          address: getGuestAddress?.street || "",
        };
        formik.setValues(defaultValues);
      }
    }
  }, [type, shippingAddress]);

  useEffect(() => {
    if (division) {
      setTownship(
        division?.find((state) => state.id == formik.values.division)
          ?.township_ids
      );
    }
  }, [division, formik.values]);

  useEffect(() => {
    if (isAdd) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    if (type === "add") {
      formik.resetForm();
    }
  }, [type]);
  // console.log(township);
  return (
    <div className=" fixed w-full h-screen bg-[#333333c9] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className=" w-[90%] md:w-[550px] min-h-[300px] bg-white border-t-[20px] border-red-1 rounded-[5px] p-[20px] md:p-[40px]">
        <div className="">
          <img src={Images.addressImg} alt="" className=" w-[90px] mx-auto" />
          <h4 className=" text-center my-[20px]">
            {translate("shipping-address", "Shipping Address")}
          </h4>
        </div>
        <form action="" onSubmit={formik.handleSubmit}>
          <div className="">
            <TextFieldInput
              name={"name"}
              type={"text"}
              label={translate("name", "Name") + "*"}
              className={"w-full"}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik?.touched.name && formik?.errors.name ? (
              <span className=" text-sm text-red-2">
                {translate("name-is-required", formik.errors.name)}
              </span>
            ) : null}
          </div>
          <div className=" mt-[10px]">
            <TextFieldInput
              name={"phone"}
              type={"phone"}
              label={translate("phone", "Phone") + "*"}
              className={"w-full"}
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            {formik?.touched.phone && formik?.errors.phone ? (
              <span className=" text-sm text-red-2">
                {translate("phone-is-required", formik.errors.phone)}
              </span>
            ) : null}
          </div>
          <div className=" mt-[10px]">
            <InputSelect
              name={"division"}
              data={division}
              label={translate("states-divisions", "State / Division") + "*"}
              className={" w-full"}
              value={formik.values.division}
              onChange={formik.handleChange}
            />
            {formik?.touched.division && formik?.errors.division ? (
              <span className=" text-sm text-red-2">
                {translate("division-require-error", formik.errors.division)}
              </span>
            ) : null}
          </div>
          <div className=" mt-[10px]">
            <InputSelect
              name={"township"}
              data={township}
              label={translate("township", "Township") + "*"}
              className={"w-full"}
              value={formik.values.township}
              onChange={formik.handleChange}
            />
            {formik?.touched.township && formik?.errors.township ? (
              <span className=" text-sm text-red-2">
                {translate("township-is-required", formik.errors.township)}
              </span>
            ) : null}
          </div>
          <div className="mt-[10px]">
            <TextFieldInput
              name={"address"}
              type={"text"}
              label={translate("address", "Address") + "*"}
              className={"w-full"}
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {formik?.touched.address && formik?.errors.address ? (
              <span className=" text-sm text-red-2">
                {translate("address-is-required", formik.errors.address)}
              </span>
            ) : null}
          </div>
          {type == "add" && (
            <div className=" pt-5">
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.currentTarget.checked)}
                label={translate(
                  "use-my-default-address",
                  "Use as my default address "
                )}
              />
            </div>
          )}
          <div className="flex justify-between mt-[20px]">
            <Button
              className={
                " bg-black-1 p-[10px] md:p-[12px] rounded-[10px] w-[120px] md:w-[220px]"
              }
              type={"button"}
              onClick={onClick}
            >
              {translate("cancel", "Cancel")}
            </Button>
            <Button
              className={
                " bg-red-2 p-[10px] md:p-[12px] rounded-[10px] text-white w-[120px] md:w-[220px]"
              }
              type={"submit"}
            >
              {formik.isSubmitting || isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                translate("save", "Save")
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShippingAddressPopup;
