import React, { useState } from "react";
import styled from "./sign_up.module.css";
import Button from "../../components/common/button/Button";
import TextFieldInput from "../../components/common/input/TextField";
import AuthWrapper from "../../components/common/auth/AuthWrapper";
import Icons from "../../assets/icons/index";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import callApi from "../../services/api/apiClient";
import { BeatLoader } from "react-spinners";
import useDictionary from "../../hooks/lang/useDictionary";
import AlertPopup from "../../components/loading/AlertPopup";
import useLanguage from "../../hooks/lang/useLanguage";
import LoadingComponent from "../../components/loading/LoadingComponent";

const signUpSchemaB2B = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  shop_name: Yup.string().required("Shop name is required"),
  address: Yup.string().required("Address is required"),
  viber_number: Yup.string().required("Viber number is required"),
  password: Yup.string()
    .min(1, "Password must be at least 1 characters long")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const signUpSchemaB2C = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .min(1, "Password must be at least 1 characters long")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const SignUpPage = () => {
  const navigate = useNavigate();
  const lang = useLanguage();
  const { translate } = useDictionary();
  const [selectedValue, setSelectedValue] = useState("b2c");
  const [isLoading, setIsLoading] = useState(false);
  const [existNum, setExistNum] = useState({
    status: false,
    message: "",
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      shop_name: "",
      address: "",
      viber_number: "",
      password: "",
      confirm_password: "",
    },
    validationSchema:
      selectedValue === "b2c" ? signUpSchemaB2C : signUpSchemaB2B,
    onSubmit: (values, actions) => {
      const formData = {
        name: values.name,
        mobile: values.phone,
        email: values.email || "",
        shop_name: values.shop_name || "",
        address: values.address || "",
        viber_number: values.viber_number || "",
        password: values.password,
        confirm_password: values.confirm_password,
        // user_type_id: selectedValue === "b2c" ? 1 : 2,
        user_type_code: selectedValue === "b2c" ? "b2c" : "b2b",
      };

      setTimeout(() => {
        callApi("auth/signUp")
          .withBody(formData)
          .loadingGroup(1)
          .execute()
          .then((res) => {
            if (res?.status === "success") {
              actions.resetForm();
              navigate("/verify?type=signup", {
                state: { mobile: values.phone, user_type: selectedValue },
              });
            } else {
              console.log(res, "setExistNum")
              setExistNum({ status: true, message: res?.message });
            }
          })
          .catch((err) => console.log("sign up error", err));

        setIsLoading(false);
        actions.setSubmitting(false);
      }, 1000);
    },
  });

  const handleClose = () => {
    setExistNum({ status: false, message: "" });
    formik.values.phone = "";
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <LoadingComponent loadingGroup={"dictionary"}>
      <AuthWrapper>
        {existNum.status ? (
          <AlertPopup
            type={"message"}
            btnLabel={"OK"}
            text={existNum.message}
            onClick={handleClose}
          />
        ) : null}
        <div className={`${styled.box}`}>
          <div className=" absolute left-0 right-0 top-0">
            <div className={`${styled.img_box}`}>
              <img src={Icons.AuthLogo} alt="" className="w-[70px] 2xl:w-auto" />
            </div>
          </div>
          <h3 className={styled.heading}>
            {translate("create-account", "Create Account")}
          </h3>
          <div className="mb-2">
            <p>{translate("please-select-role", "Please select role")} :</p>
          </div>
          <div className=" flex mb-3 gap-5">
            <div className="">
              <input
                id="b2c"
                type="radio"
                value={"b2c"}
                style={{ accentColor: "red" }}
                checked={selectedValue === "b2c"}
                onChange={handleRadioChange}
              />
              <label htmlFor="b2c" className="ml-2">
                {translate("b2c", "B2C Customer")}
              </label>
            </div>
            <div className="">
              <input
                id="b2b"
                type="radio"
                value={"b2b"}
                style={{ accentColor: "red" }}
                checked={selectedValue === "b2b"}
                onChange={handleRadioChange}
              />
              <label htmlFor="b2b" className="ml-2">
                {translate("b2b", "B2B Customer")}
              </label>
            </div>
          </div>
          <form action="" onSubmit={formik.handleSubmit}>
            {selectedValue !== "b2c" ? (
              <>
                <div className="">
                  <TextFieldInput
                    name={"name"}
                    type="text"
                    label={translate("name", "Name") + "*"}
                    className={"w-full"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.name && formik?.errors.name ? (
                    <span className=" text-sm text-red-2">
                      {translate("name-is-required", formik.errors.name)}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"phone"}
                    type="tel"
                    label={translate("phone", "Phone") + "*"}
                    className={"w-full"}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.phone && formik?.errors.phone ? (
                    <span className=" text-sm text-red-2">
                      {translate("phone-is-required", formik.errors.phone)}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"email"}
                    type="email"
                    label={translate("email", "Email")}
                    className={"w-full"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.email && formik?.errors.email ? (
                    <span className=" text-sm text-red-2">
                      {formik.errors.email}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"shop_name"}
                    type="text"
                    label={
                      translate("shop-name", "Company Name/ Shop Name") + "*"
                    }
                    className={"w-full"}
                    value={formik.values.shop_name}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.shop_name && formik?.errors.shop_name ? (
                    <span className=" text-sm text-red-2">
                      {formik.errors.shop_name}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"address"}
                    type="text"
                    label={translate("address", "Address") + "*"}
                    className={"w-full"}
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.address && formik?.errors.address ? (
                    <span className=" text-sm text-red-2">
                      {translate("address-is-required", formik.errors.address)}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"viber_number"}
                    type="tel"
                    label={translate("viber_phone_no", "Viber Phone No") + "*"}
                    className={"w-full"}
                    value={formik.values.viber_number}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.viber_number && formik?.errors.viber_number ? (
                    <span className=" text-sm text-red-2">
                      {translate(
                        "mobile-require-error",
                        formik.errors.viber_number
                      )}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"password"}
                    type="password"
                    label={translate("password", "Password") + "*"}
                    className={"w-full"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.password && formik?.errors.password ? (
                    <span className=" text-sm text-red-2">
                      {translate(
                        "password_field_is_required",
                        formik.errors.password
                      )}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"confirm_password"}
                    type="password"
                    label={
                      translate("confirm-password", "Confirm Password") + "*"
                    }
                    className={"w-full"}
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                  />
                  {/* {formik?.touched.confirm_password &&
                formik?.errors.confirm_password ? (
                  <span className=" text-sm text-red-2">
                    {translate(
                      "confirm_password_required",
                      formik.errors.confirm_password
                    )}
                  </span>
                ) : null} */}
                  {formik?.touched.confirm_password &&
                    !formik?.values?.confirm_password ? (
                    <span className=" text-sm text-red-2">
                      {translate(
                        "confirm_password_required",
                        formik.errors.confirm_password
                      )}
                    </span>
                  ) : formik?.touched.confirm_password &&
                    formik?.values?.password !==
                    formik?.values?.confirm_password ? (
                    <span className=" text-sm text-red-2">
                      {translate(
                        "password_and_confirmpassword_not_equal",
                        "Password and confirm password must be same."
                      )}
                    </span>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="">
                  <TextFieldInput
                    name={"name"}
                    type="text"
                    label={translate("name", "Name") + "*"}
                    className={"w-full"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.name && formik?.errors.name ? (
                    <span className=" text-sm text-red-2">
                      {translate("name-is-required", formik.errors.name)}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"phone"}
                    type="tel"
                    label={translate("phone", "Phone") + "*"}
                    className={"w-full"}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.phone && formik?.errors.phone ? (
                    <span className=" text-sm text-red-2">
                      {translate("phone-is-required", formik.errors.phone)}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"email"}
                    type="email"
                    label={translate("email", "Email")}
                    className={"w-full"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.email && formik?.errors.email ? (
                    <span className=" text-sm text-red-2">
                      {formik.errors.email}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"password"}
                    type="password"
                    label={translate("password", "Password") + "*"}
                    className={"w-full"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.password && formik?.errors.password ? (
                    <span className=" text-sm text-red-2">
                      {translate(
                        "password_field_is_required",
                        formik.errors.password
                      )}
                    </span>
                  ) : null}
                </div>
                <div className="mt-[15px]">
                  <TextFieldInput
                    name={"confirm_password"}
                    type="password"
                    label={
                      translate("confirm-password", "Confirm Password") + "*"
                    }
                    className={"w-full"}
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                  />
                  {formik?.touched.confirm_password &&
                    !formik?.values?.confirm_password ? (
                    <span className=" text-sm text-red-2">
                      {translate(
                        "confirm_password_required",
                        formik.errors.confirm_password
                      )}
                    </span>
                  ) : formik?.touched.confirm_password &&
                    formik?.values?.password !==
                    formik?.values?.confirm_password ? (
                    <span className=" text-sm text-red-2">
                      {translate(
                        "password_and_confirmpassword_not_equal",
                        "Password and confirm password must be same."
                      )}
                    </span>
                  ) : null}
                  {/* {formik?.touched.confirm_password &&
                formik?.errors.confirm_password ? (
                  <span className=" text-sm text-red-2">
                    {translate(
                      "confirm_password_required",
                      formik.errors.confirm_password
                    )}
                  </span>
                ) : null} */}
                </div>
              </>
            )}
            <div className="flex items-start mb-4 mt-[20px]">
              <input
                id="checkbox"
                type="checkbox"
                defaultValue=""
                required
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mt-1"
              />
              {lang === "en" ? (
                <label
                  htmlFor="checkbox"
                  className="ml-2 text-sm font-medium text-gray-600"
                >
                  I have read and agree to NPT's
                  <Button
                    className="ml-2 text-sm font-medium text-blue-600"
                    onClick={() => window.open("/terms-conditions", "blank")}
                  >
                    Terms of Use
                  </Button>{" "}
                  and{" "}
                  <Button
                    className="ml-2 text-sm font-medium text-blue-600"
                    onClick={() => window.open("/privacy-policy", "blank")}
                  >
                    Privacy Policy
                  </Button>
                </label>
              ) : (
                <label
                  htmlFor="checkbox"
                  className="ml-2 text-sm font-medium text-gray-600"
                >
                  <Button
                    className="ml-2 text-sm font-medium text-blue-600"
                    onClick={() =>
                      window.open("http://localhost:3000/terms-conditions")
                    }
                  >
                    မူဝါဒ
                  </Button>{" "}
                  နှင့်
                  <Button
                    className="ml-2 text-sm font-medium text-blue-600"
                    onClick={() =>
                      window.open("http://localhost:3000/privacy-policy")
                    }
                  >
                    စည်းမျဥ်းစည်းကမ်းများ
                  </Button>
                  ကို သဘောတူပါသည်
                </label>
              )}
            </div>
            <div className=" flex justify-center">
              <Button
                type={"submit"}
                className=" bg-red-3 text-white h-[47px] w-[180px] 2xl:w-[230px] p-[8px] 2xl:p-[12px] rounded-[8px] lg:text-md 2xl:text-lg flex items-center justify-center mt-[20px] lg:mt-[30px]"
                disabled={formik.isSubmitting || isLoading}
              >
                {formik.isSubmitting || isLoading ? (
                  <BeatLoader color="#ffffff" size={13} />
                ) : (
                  translate("register", "Register")
                )}
              </Button>
            </div>
          </form>
          <p className="text-center mt-[20px] 2xl:mt-[30px]">
            {translate("already-have-an-account", " Already have an account ?")}{" "}
            <span
              className=" text-sky_blue cursor-pointer"
              onClick={() => navigate("/login")}
            >
              {translate("login", "Login")}
            </span>
          </p>
        </div>
      </AuthWrapper>
    </LoadingComponent>
  );
};

export default SignUpPage;
