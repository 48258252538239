import React, { useEffect } from "react";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import BranchCard from "./BranchCard";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useLanguage from "../../hooks/lang/useLanguage";

const data = [
  {
    id: 1,
    name: "branches",
  },
];

const items = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

const BranchesPage = () => {
  const language = useLanguage();
  const storeData = useSelector((state) => state?.others?.getStores?.data);
  useEffect(() => {
    callApi("others/getStores")
      .withParam({ lang: language })
      .loadingGroup("s")
      .executeDispatch();
  }, [language]);
  return (
    <LoadingComponent loadingGroup={"s"}>
      <div>
        <Breadcrumb title={"branches"} item={data} />
        <div className=" container mx-auto grid grid-cols-1 lg:grid-cols-2 py-[60px] relative gap-x-[35px] gap-y-[30px] md:gap-y-[60px]">
          {storeData?.store?.map((item) => (
            <BranchCard key={item.id} item={item} />
          ))}
        </div>
      </div>
    </LoadingComponent>
  );
};

export default BranchesPage;
