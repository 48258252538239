import { useCallback } from "react";
import { getMetaDataStore } from "../utils/getMetaDataStore";

const getSingleMetaData = (data, key) => {
  // console.log("translate", key);
  if (window.location.href.toString().includes("debug=1")) {
    return {text_one: key}
  }
  else {
    if (data) {
      return data?.[key];
    }
  }
};

const useMetaDatas = () => {
  // console.log("translate testing");
  const data = getMetaDataStore();
  const getMetaData = useCallback(
    (key) => {
      if (data) {
        return getSingleMetaData(data, key);
      }
    },
    [data]
  );

  return {
    getMetaData,
  };
};

export default useMetaDatas;


// usage example ----\------\--------

// import useMetaDatas from hooks components folder

// const { getMetaData } = useMetaDatas();
// const shippingInfo = getMetaData("shipping");

// console.log(shippingInfo);
