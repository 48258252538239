// for money format
const convertToMyanmarNumber = (number) => {
  const myanmarDigits = ["၀", "၁", "၂", "၃", "၄", "၅", "၆", "၇", "၈", "၉"];
  const myanmarDecimalPoint = ".";

  if (number) {
    const parts = number.toString().split(".");
    let integerPart = parts[0]
      .split("")
      .map((digit) => myanmarDigits[parseInt(digit)])
      .join("");

    if (parts.length > 1) {
      const decimalPart = parts[1]
        .split("")
        .map((digit) => myanmarDigits[parseInt(digit)])
        .join("");

      return `${integerPart}${myanmarDecimalPoint}${decimalPart}`;
    } else {
      return integerPart;
    }
  }

  // return number;
};

function formatMyanmarNumberWithCommas(myanmarNumber) {
  // Remove any leading zeros
  myanmarNumber = myanmarNumber?.replace(/^0+/, "");

  // Format the number with commas as thousand separators
  const parts = myanmarNumber?.split("");
  let formattedNumber = "";

  for (let i = 0; i < parts?.length; i++) {
    formattedNumber += parts[i];
    if ((parts?.length - i - 1) % 3 === 0 && i !== parts?.length - 1) {
      formattedNumber += ",";
    }
  }

  return formattedNumber;
}

export const formatNumber = (number, lang) => {
  let num = Math.trunc(number);
  if (lang === "en") {
    // English format
    return num?.toLocaleString("en");
  } else if (lang === "my") {
    // Myanmar format
    return formatMyanmarNumberWithCommas(convertToMyanmarNumber(num));
  }
  // Default to English format
  return num?.toLocaleString("en");
};

let timer;

export const smartClick = (callback, delay = 500) => {
  clearTimeout(timer);
  timer = setTimeout(callback, delay);
};
