export const priceHandler = (
  uom_lists,
  prices,
  qty,
  promotion,
  userUom,
  variantPrices
) => {
  let normalPrice;
  let promotionPrice;
  let bestMatchObj = null;
  if (userUom !== undefined && userUom != "") {
    let uom = uom_lists?.find((p) => p.id === userUom);

    if (prices) {
      for (const p of prices) {
        if (p?.quantity <= qty) {
          if (bestMatchObj === null || p?.quantity > bestMatchObj?.quantity) {
            bestMatchObj = p;
          }
        }
      }
    }

    normalPrice = bestMatchObj?.price?.toFixed(2);

    normalPrice = Number(
      prices?.find((p) => {
        if (p.uom === uom?.name) {
          if (p.quantity >= qty) {
            console.log(p, "normalPrice")
            return p
          }
          else if (p.quantity < qty) {
            return null
          }
          else {
            return p
          }
        }
      })?.price
    ).toFixed(2);

    console.log(prices, userUom, "bestMatchObj");
  } else {
    let uom = prices?.find((p) => p.uom === uom_lists[0]?.name)?.price;
    normalPrice = Number(uom).toFixed(2);
  }

  if (promotion?.promotion_type === "discount") {
    let promoPrice = normalPrice * (Number(promotion?.discount_percent) / 100);
    promotionPrice = normalPrice - promoPrice;
  }

  // console.log(normalPrice, promotionPrice);

  if (variantPrices?.length > 0) {
    const prices = variantPrices?.reduce((pv, cv) => pv + Number(cv), 0);
    normalPrice = (Number(prices) + Number(normalPrice)).toFixed(2);
    promotionPrice = (Number(prices) + Number(promotionPrice)).toFixed(2);
  }

  return {
    normalPrice,
    promotionPrice,
  };
};
