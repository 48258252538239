import React from "react";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import { useState } from "react";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import { useEffect } from "react";
import LoadingComponent from "../../components/loading/LoadingComponent";

const breadData = [
  {
    id: 1,
    name: "faq",
  },
];

const FaqPage = () => {
  const faq = useSelector((state) => state?.others?.faq?.data);

  useEffect(() => {
    callApi("others/faq").loadingGroup("faq").executeDispatch();
  }, []);

  return (
    <LoadingComponent loadingGroup={"faq"}>
      <div className=" min-h-[30vh] bg-black-0">
        <Breadcrumb title={"faq"} item={breadData} />

        {window.innerWidth > 760 ? (
          <div className="grid grid-cols-5 py-[70px] justify-center">
            <div className="col-start-2 col-span-3">
              <table className="w-full">
                <tbody className=" w-full">
                  {faq?.map((data, id) => (
                    <tr key={id}>
                      <td className="w-[20px] flex mr-[8px]">
                        <span className="title1">{data.id}.</span>
                      </td>
                      <td>
                        <div className="pb-[30px]">
                          <p className="title1 mb-[10px]">{data?.ques}</p>
                          <span className="body text-justify">{data?.ans}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="mt-[16px] mx-[16px] justify-center">
            <table className="w-full">
              <tbody className=" ">
                {faq?.map((data, id) => (
                  <>
                    <tr key={id}>
                      <td className="flex">
                        <span className="title1">
                          {data.id}. {""}
                          {""}
                          {""} {data?.ques}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="pb-[30px] pt-[10px]">
                          <li className="indent-3">
                            <span className="body text-justify">
                              {data?.ans}
                            </span>
                          </li>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* <div className="">
          <h4>FAQs ( Frequently Asked Questions)</h4>
          <span className="body block my-[20px]">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </span>
        </div>
        <div className="">
          <table className="w-full">
            <tbody>
              <tr>
                <td className="w-[30px] flex">
                  <span className="title1">1.</span>
                </td>
                <td>
                  <div className="pb-[30px]">
                    <p className="title1 mb-[20px]">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna?
                    </p>
                    <span className="body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum.
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-[30px] flex">
                  <span className="title1">2.</span>
                </td>
                <td>
                  <div className="pb-[30px]">
                    <p className="title1 mb-[20px]">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna?
                    </p>
                    <span className="body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum.
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-[30px] flex">
                  <span className="title1">3.</span>
                </td>
                <td>
                  <div className="pb-[30px]">
                    <p className="title1 mb-[20px]">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna?
                    </p>
                    <span className="body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum.
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="w-[30px] flex">
                  <span className="title1">4.</span>
                </td>
                <td>
                  <div className="pb-[30px]">
                    <p className="title1 mb-[20px]">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna?
                    </p>
                    <span className="body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum.
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
    </LoadingComponent>
  );
};

export default FaqPage;
