import { useState } from "react";
import styled from "./quantityCard.module.css";
import { useSelector } from "react-redux";

const QuantityCard = (props) => {
  const { className, setQty, qty, availableQty, minQty, isInStock } = props;
  const [num, setNum] = useState(qty);
  const userInfo = useSelector((state) => state?.auth?.getUser?.data) || "";

  const countChange = (e) => {
    setNum(Number(e.target.value));
  };

  const countBlur = () => {
    if (num !== 0) {
      setQty(Number(num));
    } else {
      if (!isInStock) {
        setQty(minQty);
        setNum(minQty);
      } else {
        setQty(0);
        setNum(0);
      }
    }
  };

  const handleDecrease = () => {
    if (isInStock && userInfo?.user_type !== "b2b") {
      setNum((prev) => (prev > 1 ? prev - 1 : prev));
      setQty(num > 1 ? num - 1 : num);
    } else {
      setNum((prev) => (prev > 1 ? prev - 1 : prev));
      setQty(num > 1 ? num - 1 : num);
    }
  };

  const handleIncrease = () => {
    if (isInStock && userInfo?.user_type !== "b2b") {
      setNum(num + 1);
      setQty(num + 1);
    } else {
      setNum(num + 1);
      setQty(num + 1);
    }
  };

  return (
    <div className={className ? className : styled.qtyCon}>
      <button
        className={styled.countBtn}
        onClick={handleDecrease}
        disabled={userInfo?.user_type === "b2b" ? false : !isInStock}
      >
        -
      </button>
      <input
        type="number"
        value={num}
        className={styled.count}
        onChange={countChange}
        onBlur={countBlur}
      ></input>
      <button
        className={styled.countBtn}
        onClick={handleIncrease}
        disabled={userInfo?.user_type === "b2b" ? false : !isInStock}
      >
        +
      </button>
    </div>
  );
};
export default QuantityCard;
