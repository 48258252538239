/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import callApi from "../../services/api/apiClient";
import Icons from "../../assets/icons/index";
import useDictionary from "../../hooks/lang/useDictionary";
import Button from "../../components/common/button/Button";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import TextFieldInput from "../../components/common/input/TextField";
import InputSelect from "../../components/common/input/InputSelect";
import MapDirection from "./MapDirection";
import { memo } from "react";
import useSelectorStore from "../../hooks/useSelectorStore";
import useAuth from "../../hooks/lang/useAuth";
import AddShippingAddressForm from "./AddShippingAddressForm";
import useLanguage from "../../hooks/lang/useLanguage";
import ShippingAddressPopup from "./ShippingAddressPopup";
import ConfirmPopup from "../../components/common/popup/ConfirmPopup";
import PickupAddressForm from "./PickupAddressForm";
import LoginUserInfo from "./LoginUserInfo";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

const CheckoutInfo = (props) => {
  const {
    checkAddress,
    setCheckAddress,
    isDelivery,
    setIsDelivery,
    formik,
    isPayNow,
  } = props;
  const {
    pickupAddressInfo,
    getGuestAddress,
    addShippingAddress,
    editShippingAddress,
    deleteShippingAddress,
    orderInfo,
    getShippingMethods,
  } = useSelectorStore();

  const { translate } = useDictionary();
  const language = useLanguage();
  const token = useAuth();
  const location = useLocation();

  // for states

  const [remarkId, setRemarkId] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [addressActions, setAddressActions] = useState({
    isAdd: false,
    isDelete: false,
    isEdit: false,
  });

  let phones = pickupAddressInfo?.mobile?.split(", ");
  // use first address index as default

  // for api call
  // retrieve pickup address info

  useEffect(() => {
    callApi("checkout/getPickupAddressInfo")
      .withSegment("pickup_shop")
      .loadingGroup("pickup_address")
      .executeDispatch();
    callApi("checkout/getShippingLocations")
      .loadingGroup("deliveriable_locations")
      .executeDispatch();
    callApi("checkout/getAllLocations")
      .loadingGroup("all_locations")
      .executeDispatch();
  }, []);

  // get shipping method
  useEffect(() => {
    if (checkAddress && orderInfo?.id) {
      callApi("checkout/getShippingMethod")
        .withSegment(`${checkAddress}/delivery_methods`)
        .withParam({ order_id: orderInfo?.id })
        .loadingGroup("shipping_method")
        .executeDispatch();
    }
  }, [checkAddress, orderInfo?.id, isDelivery]);

  // get cart
  const getCartFun = useCallback(() => {
    if (orderInfo?.id) {
      callApi("cart/getCart")
        .withSegment(orderInfo?.id)
        .withParam({ lang: language })
        .loadingGroup("carts")
        .executeDispatch();
    }
  }, [orderInfo?.id]);

  // retrieve guest shippingAddress datas
  useEffect(() => {
    if (addShippingAddress?.ID && !token) {
      callApi("checkout/getGuestShippingAddress")
        .withSegment(addShippingAddress?.ID)
        .withParam({ lang: language })
        .loadingGroup("address")
        .executeDispatch();
    }
  }, [addShippingAddress?.ID, editShippingAddress]);

  useEffect(() => {
    if (getGuestAddress?.id) {
      setCheckAddress(getGuestAddress?.id);
    }
  }, [getGuestAddress?.id]);

  useEffect(() => {
    if (deleteShippingAddress) {
      setAddressActions({ ...addressActions, isDelete: false });
    }
  }, [deleteShippingAddress]);

  // for functions
  const handleDeleteAddress = (id) => {
    callApi("checkout/deleteShippingAddress")
      .withSegment(id)
      .loadingGroup("removeCart")
      .executeDispatch();
  };

  const selectShippingAddressForOrderFun = (loading) => {
    const datas = {
      id: isDelivery ? checkAddress : pickupAddressInfo?.id,
      order_id: orderInfo?.id,
      is_pickup: !isDelivery,
      shipping_method_id: isDelivery && deliveryInfo?.shipping_method_id,
    };
    if (!isPayNow) {
      callApi("checkout/useShippingAddressForOrder")
        .withBody(datas)
        .loadingGroup(loading)
        .execute()
        .then((res) => {
          console.log(res);
          if (res?.status === "success") getCartFun();
        });
    }
  };

  // add delivery data
  useEffect(() => {
    if (getShippingMethods) {
      setDeliveryInfo(getShippingMethods[0]);
    }
  }, [getShippingMethods]);

  useEffect(() => {
    selectShippingAddressForOrderFun(
      isDelivery ? "delivery-address" : "pickup-address"
    );
  }, [isDelivery]);

  useEffect(() => {
    if (checkAddress && deliveryInfo) {
      selectShippingAddressForOrderFun("use-address");
    }
  }, [checkAddress, deliveryInfo]);

  // for child props
  const loginUserProps = {
    isDelivery,
    phones,
    formik,
    checkAddress,
    setCheckAddress,
    addressActions,
    setAddressActions,
    setRemarkId,
  };

  return (
    <>
      {addressActions.isAdd && (
        <ShippingAddressPopup
          isAdd={addressActions.isAdd}
          onClick={() => setAddressActions({ ...addressActions, isAdd: false })}
        />
      )}
      {addressActions.isDelete && (
        <ConfirmPopup
          close={() =>
            setAddressActions({ ...addressActions, isDelete: false })
          }
          onClick={() => handleDeleteAddress(remarkId)}
          type={"address"}
        />
      )}
      {addressActions.isEdit && (
        <ShippingAddressPopup
          isAdd={addressActions.isEdit}
          onClick={() =>
            setAddressActions({ ...addressActions, isEdit: false })
          }
          addressId={remarkId}
          type="edit"
        />
      )}
      <div className="min-h-[200px] sm:border-l-[19px] border-red-1 p-[15px] md:p-[30px] bg-white rounded-[8px]">
        <p className="body">
          *{" "}
          {translate(
            "choose-convenient-service",
            "You can choose the service that is convenient for you."
          )}
        </p>
        <div className="grid grid-cols-2 mt-[12px] gap-[20px] md:gap-[30px]">
          <div>
            <Button
              className={`w-full 2xl:w-[345px] p-[10px] md:p-[16px] rounded-[8px] border-[1px] ${
                !isDelivery && "border-red-4"
              } flex justify-center items-center gap-[16px]`}
              onClick={() => setIsDelivery(false)}
            >
              <LoadingComponent
                loadingGroup={"pickup-address"}
                loadingDesign={<CircularProgress color="inherit" size={20} />}
              >
                <img src={Icons.ParcelLogo} alt="" />
                <h5>{translate("pickup", "Pickup")}</h5>
              </LoadingComponent>
            </Button>
          </div>
          <div>
            <Button
              className={`w-full 2xl:w-[345px] p-[10px] md:p-[16px] rounded-[8px] border-[1px] ${
                isDelivery && "border-red-4"
              } flex justify-center items-center gap-[16px]`}
              onClick={() => setIsDelivery(true)}
            >
              <LoadingComponent
                loadingGroup={"delivery-address"}
                loadingDesign={<CircularProgress color="inherit" size={20} />}
              >
                <img src={Icons.DeliveryCarLogo} alt="" className="w-[24px]" />
                <h5>{translate("delivery", "Delivery")}</h5>
              </LoadingComponent>
            </Button>
          </div>
        </div>
        {token ? (
          <LoginUserInfo {...loginUserProps} />
        ) : (
          <>
            {isDelivery ? (
              <LoadingComponent
                loadingGroup={"delivery-address"}
                loadingDesign={<div></div>}
              >
                <>
                  {getGuestAddress && !token ? (
                    <div className="relative">
                      <div className="flex items-center border-b-[1px] border-gray-200 rounded ">
                        <input
                          id={`radio-address-${getGuestAddress?.id}`}
                          type="radio"
                          defaultValue=""
                          name="radio"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                          checked={checkAddress === getGuestAddress?.id}
                          onChange={() => setCheckAddress(getGuestAddress?.id)}
                        />
                        <label
                          htmlFor={`radio-address-${getGuestAddress?.id}`}
                          className="w-full py-4 ml-2 text-sm font-medium text-gray-900 "
                        >
                          <p className="body">{getGuestAddress?.name}</p>
                          <p className="body">{getGuestAddress?.mobile}</p>
                          {getGuestAddress ? (
                            <p className="body">
                              {getGuestAddress?.street},{" "}
                              {getGuestAddress?.township["name"]},{" "}
                              {getGuestAddress?.state["name"]}
                            </p>
                          ) : null}
                        </label>
                      </div>
                      <div className=" absolute top-4 right-0 flex gap-[12px]">
                        <Button
                          className={"text-sky_blue text-sm sm:text-base"}
                          onClick={() => {
                            setRemarkId(getGuestAddress?.id);
                            setAddressActions({
                              ...addressActions,
                              isEdit: true,
                            });
                          }}
                        >
                          {translate("edit", "Edit")}
                        </Button>
                        {checkAddress !== getGuestAddress?.id && (
                          <Button
                            className={"text-sm sm:text-base"}
                            onClick={() => setRemarkId(getGuestAddress?.id)}
                          >
                            {translate("delete", "Delete")}
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <p className="body mt-[30px] mb-[15px]">
                        *
                        {translate(
                          "fill-delivery-information",
                          "Please fill delivery information"
                        )}
                      </p>
                      {Object.keys(formik.values).length >= 5 && (
                        <>
                          <div className=" text-red-3 mb-3 w-full h-[33px] ps-3 bg-[#FFF0F1] flex items-center rounded-[5px]">
                            {translate("please-save-field", "* Please save your fields !")}
                          </div>
                        </>
                      )}

                      {/* for guest address form ui  */}

                      <AddShippingAddressForm formik={formik} />
                    </>
                  )}
                </>
              </LoadingComponent>
            ) : (
              <LoadingComponent
                loadingGroup={"pickup-address"}
                loadingDesign={<div></div>}
              >
                <>
                  <div className=" py-7">
                    <p className=" title1 mb-3">
                      {translate("pickup-address", "Pickup Address")}
                    </p>
                    {pickupAddressInfo?.address1 ? (
                      <div className=" flex">
                        <span className=" title2 me-5">
                          {translate("address", "Address")}:
                        </span>{" "}
                        <div className="flex flex-col">
                          <span>{pickupAddressInfo?.address1}</span>
                          <MapDirection
                            name={"Get Direction"}
                            shopLocation={{
                              lat: pickupAddressInfo?.latitude,
                              lng: pickupAddressInfo?.longitude,
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                    {pickupAddressInfo?.mobile ? (
                      <p className=" mb-2">
                        <span className=" title2 me-3">
                          {translate("phone", "Phone")}:
                        </span>{" "}
                        {phones?.map((phone, index) => {
                          return (
                            <a
                              className="ml-[5px]"
                              href={`tel:09${phone?.split("-")[1]}`}
                              key={index}
                            >
                              {phone}
                            </a>
                          );
                        })}
                        {/* {pickupAddressInfo?.mobile} */}
                      </p>
                    ) : null}

                    {pickupAddressInfo?.email ? (
                      <p>
                        <span className=" me-3">
                          {translate("email", "Email")}
                        </span>{" "}
                        {pickupAddressInfo?.email}
                      </p>
                    ) : null}
                  </div>
                  <>
                    <p className="body_14 text-red-2 mt-[10px] mb-[20px]">
                      *
                      {translate(
                        "fill-contact-information",
                        "Please fill contact information"
                      )}
                    </p>

                    {/* for guest pickup form ui  */}

                    <PickupAddressForm formik={formik} />
                  </>
                </>
              </LoadingComponent>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(CheckoutInfo);
