import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ItemCard from "./ItemCard";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import Button from "../../components/common/button/Button";
import Icons from "../../assets/icons/index";
// import { TbBellRingingFilled } from "react-icons/tb";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import callApi from "../../services/api/apiClient";
// import useAuth from "../../hooks/lang/useAuth";
import ConfirmPopup from "../../components/common/popup/ConfirmPopup";
import { useEffect } from "react";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import { formatNumber } from "../../utils/generals";

const cartData = [
  {
    id: 1,
    name: "cart",
  },
];

const AddToCardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useDictionary();
  const [isShow, setIsShow] = useState(false);
  const [canGo, setCanGo] = useState(false);
  const cartInfo = useSelector((state) => state?.cart?.getCart?.data);
  const userInfo = useSelector((state) => state?.auth?.getUser?.data);
  const clearCartInfo = useSelector((state) => state?.cart?.clearCart?.data);
  const language = useLanguage();
  const productOnly = cartInfo?.order_lines?.filter(
    (cart) => cart?.line_ref == false
  );
  const orderInfo = useSelector(
    (state) => state?.persistSlice?.addToCart?.data
  );
  const updateCart = useSelector((state) => state?.cart?.updateQtyCart?.data);
  const removeCartInfo = useSelector((state) => state?.cart?.removeCart?.data);
  // const metaDatas = useSelector((state) => state?.others?.getMetaData?.data);
  // const token = useAuth();

  const clearCarts = () => {
    callApi("cart/clearCart")
      .withBody({ order_id: orderInfo?.id })
      .loadingGroup("removeCart")
      .executeDispatch();
  };

  useEffect(() => {
    if (orderInfo?.id) {
      callApi("cart/getCart")
        .withSegment(orderInfo?.id)
        .withParam({ lang: language, remove_shipping: true })
        .loadingGroup("carts")
        .executeDispatch();
    }
  }, [
    orderInfo?.id,
    updateCart,
    removeCartInfo,
    clearCartInfo,
    language,
    location,
  ]);

  const handleToCheckout = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    if (userInfo?.user_type == "b2b") {
      if (cartInfo?.untax_amount >= 300000) {
        setCanGo(false);
      } else {
        setCanGo(true);
      }
    } else {
      setCanGo(false);
    }
  }, [userInfo, cartInfo]);

  useEffect(() => {
    setIsShow(false);
  }, [clearCartInfo]);

  return (
    <LoadingComponent loadingGroup={"carts"}>
      <Breadcrumb title={"cart"} item={cartData} />
      {isShow && (
        <ConfirmPopup
          close={() => setIsShow(false)}
          onClick={clearCarts}
          type={"removeAll"}
        />
      )}
      {cartInfo?.order_lines?.length > 0 ? (
        <div className="w-full h-auto bg-silver pb-[30px] sm:py-[60px]">
          <div className="container mx-auto">
            <div className="sm:max-w-[700px] lg:max-w-[900px] 2xl:max-w-[1090px] mx-auto">
              {/* {token && userInfo?.user_type == "b2c" && (
                <p className="body text-[#DA7528] mb-[20px] flex items-center gap-[5px]">
                  <img src={Icons.BellLogo} alt="" />
                  "You are silver member,buy Ks 150,000 more before 18-05-2023
                  to become gold member."
                </p>
              )} */}
              <div className="bg-white rounded-[8px] p-[15px] sm:p-[30px]">
                <table className=" w-full">
                  <thead className=" hidden lg:contents">
                    <tr className=" border-b-2">
                      <th className="w-auto text-start pb-5 pl-10">
                        {translate("items", "Item")}
                      </th>

                      <th className="text-start pb-5 md:w-[150px]">
                        {translate("price", "Price")}
                      </th>
                      <th className="text-start pb-5 md:w-[120px]">
                        {translate("quantity", "Quantity")}
                      </th>
                      <th className="text-center pb-5 md:w-[80px] lg:w-[5px] opacity-0">
                        {translate("uom", "UOM")}
                      </th>
                      <th className="text-end pb-5 md:w-[110px]">
                        {translate("subtotal", "Subtotal")}
                      </th>
                      <th className=" w-fit pb-5"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {productOnly?.map((card, i) => (
                      <ItemCard key={i} item={card} />
                    ))}
                    <tr className="lg:border-b-2">
                      <td className="pb-5"></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="" colSpan={2}></td>
                      <td className=" font-semibold pt-3" colSpan={2}>
                        {translate("order-total", "Order Total")}
                      </td>
                      <td className="text-end font-semibold pt-3">
                        {formatNumber(cartInfo?.untax_amount, language) || null}{" "}
                        {translate("ks", "Ks")}
                      </td>
                    </tr>
                    {userInfo?.user_type == "b2c" ? (
                      <>
                        {cartInfo?.tax ? (
                          <tr>
                            <td className="" colSpan={2}></td>
                            <td className=" font-semibold pt-3" colSpan={2}>
                              {translate("tax", "Tax")}
                            </td>
                            <td className="text-end font-semibold pt-3">
                              {formatNumber(cartInfo?.tax, language) || null}{" "}
                              {translate("ks", "Ks")}
                            </td>
                          </tr>
                        ) : null}
                        {cartInfo?.member_discount_amount > 0 && (
                          <>
                            <tr className="">
                              <td className="" colSpan={2}></td>
                              <td
                                className=" py-2 border-b-2 w-[200px]"
                                colSpan={2}
                              >
                                {translate(
                                  "member-discount",
                                  "Member Discount"
                                )}{" "}
                                (
                                {formatNumber(
                                  cartInfo?.member_discount_percent,
                                  language
                                )}{" "}
                                %)
                              </td>
                              <td className="text-end py-2 border-b-2">
                                -{" "}
                                {formatNumber(
                                  cartInfo?.member_discount_amount,
                                  language
                                )}{" "}
                                {translate("ks", "Ks")}
                              </td>
                            </tr>
                          </>
                        )}
                        {cartInfo?.member_discount_amount > 0 && (
                          <tr>
                            <td className="" colSpan={2}></td>
                            <td className=" font-bold pt-2" colSpan={2}>
                              {translate("total", "Total")}
                            </td>
                            <td className="text-end font-bold pt-2">
                              {formatNumber(cartInfo?.total, language)}{" "}
                              {translate("ks", "Ks")}
                            </td>
                          </tr>
                        )}
                      </>
                    ) : null}
                  </tfoot>
                </table>
              </div>
              <div className="flex flex-col md:flex-row justify-between mt-[12px] md:items-end">
                <div className="flex gap-[16px] items-center">
                  <Button
                    className={
                      "p-[12px] text-sm sm:text-base rounded-[10px] bg-black-2 w-full sm:w-auto"
                    }
                    onClick={() => navigate("/")}
                  >
                    {translate("continue-shopping", "Continue Shopping")}
                  </Button>
                  <Button
                    className={
                      "p-[12px] text-sm sm:text-base rounded-[10px] bg-red-2 text-white w-full sm:w-auto"
                    }
                    onClick={() => setIsShow(true)}
                  >
                    {translate("clear-cart", "Clear Cart")}
                  </Button>
                </div>
                <div className=" flex flex-col sm:flex-row sm:items-center gap-2 justify-between mt-[12px]">
                  {userInfo?.user_type == "b2b" && canGo && (
                    <p className="body text-red-2">
                      *
                      {translate(
                        "b2b-shop-text",
                        "Total amount must be 3lks and above"
                      )}
                    </p>
                  )}
                  <Button
                    className={`p-[12px] text-sm sm:text-base rounded-[10px] ${
                      canGo ? "bg-red-1" : "bg-red-2"
                    } text-white w-full sm:w-fit`}
                    onClick={handleToCheckout}
                    disabled={canGo}
                  >
                    {translate("proceed-to-checkout", "Proceed to CheckOut")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" w-full min-h-[40vh] flex justify-center items-center">
          <div className=" flex flex-col justify-center items-center">
            <img
              src={Icons.emptyCartIcon}
              alt=""
              className=" w-[100px] mx-auto"
            />
            <p className=" mt-3">
              {translate("empty-cart-title", "Your Cart is Empty")} !
            </p>
            {/* <button
              className=" underline text-blue-600 mt-2"
              onClick={() => navigate("/")}
            >
              {translate("continue-shopping", "Continue Shopping")}
            </button> */}
            <Button
              className={
                "w-[200px] p-[12px] mt-2 rounded-[10px] bg-red-2 text-white"
              }
              onClick={() => navigate("/")}
            >
              {translate("continue-shopping", "Continue Shopping")}
            </Button>
          </div>
        </div>
      )}
    </LoadingComponent>
  );
};

export default AddToCardPage;
