import callApi from "../../../../services/api/apiClient";

export const checkUser = (phone, setIsTrue) => {
  callApi("auth/checkUser")
    .withBody({ mobile: phone })
    .loadingGroup(1)
    .execute()
    .then((res) => {
      setIsTrue && setIsTrue(res?.exist ? "Yes" : "No");
    });
};
