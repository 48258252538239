import React, { useEffect, useState } from "react";
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "./subCategory.module.css";
import Button from "../../components/common/button/Button";
import Image from "../../assets/images";
import Icon from "../../assets/icons";
import { BsChevronRight } from "react-icons/bs";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";

const SubCategory = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("category_id");
  const [currentCategory, setCurrentCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  const categories = useSelector(
    (state) => state?.products?.MyCategories?.data
  );

  // console.log(categoryId, "categoryId")

  useEffect(() => {
    callApi("products/MyCategories")
      .loadingGroup("sub_category")
      .executeDispatch();
  }, [categoryId]);

  const changeText = (text) => {
    let string = text;
    const filtered = string.replace(/&/g, "/");
    return filtered;
  };

  useEffect(() => {
    if (categories) {
      setCurrentCategory(
        categories?.find((cate) =>
          cate?.sub_category?.length > 0
            ? cate?.sub_category?.find(
              (nestcate) =>
                nestcate?.category_id == categoryId ||
                cate?.category_id == categoryId
            )
            : cate?.category_id == categoryId
        )
      );
    }
  }, [categoryId, categories]);

  const SubCategoryList = ({ item }) => {
    const navigate = useNavigate();
    const handleClick = () => {
      if (item?.sub_category?.length > 0) {
        setSubCategory(item?.sub_category);
      } else {
        navigate(
          `/filter?category_id=${item?.category_id}`
        );
      }
    };

    return (
      <button className={styled.subCategoryCon} onClick={handleClick}>
        <div className={styled.imgDiv}>
          <img
            src={Icon.petfoot}
            alt={item.category_name}
            title={item.category_name}
          />
        </div>
        <div
          className={`flex items-center justify-between`}
          style={{ width: `calc(100% - 80px)` }}
        >
          {item.category_name}
          <BsChevronRight size={18} />
        </div>
      </button>
    );
  };

  return (
    <LoadingComponent loadingGroup={"c"}>
      <div className="container-con category">
        <div className="container">
          {window.innerWidth > 991 ? (
            <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3 border-b-black-1">
              {categories?.map((category, key) => (
                <Link
                  to={`/filter?category_id=${category.category_id}`}
                  key={key}
                >
                  <Button
                    className={
                      category.category_id === currentCategory?.category_id
                        ? styled["categoryButton"] + " " + styled.active
                        : styled.categoryButton
                    }
                    children={category.category_name}
                    key={key}
                  />
                </Link>
              ))}
            </div>
          ) : (
            <h2>Cat</h2>
          )}
          <div className={styled.subCon}>
            {!subCategory ? (
              <div className={styled.subCategoryListCon}>
                {currentCategory?.sub_category?.map((subcategory, index) => (
                  <SubCategoryList item={subcategory} key={index} />
                ))}
              </div>
            ) : (
              <div className={styled.subCategoryListCon}>
                {subCategory?.map((subcategory, index) => (
                  <SubCategoryList item={subcategory} key={index} />
                ))}
              </div>
            )}

            {window.innerWidth > 1023 && (
              <img
                src={Image.subCategoryBg}
                className="pl-5 pt-10"
                alt=""
              // title="category bg"
              />
            )}
          </div>
        </div>
      </div>
    </LoadingComponent>
  );
};
export default SubCategory;
