import { useSelector } from "react-redux";
import useLanguage from "./useLanguage";
import { useCallback } from "react";

const getUiTranslate = (dict, key, lang, text) => {

  if (window.location.href.toString().includes("debug=1")) {
    return key
  }
  else{
    if (dict) {
      return dict?.[key]?.[lang] || text || key;
    }
  }
};

const useDictionary = () => {
  // console.log("translate testing");
  const lang = useLanguage();
  const data = useSelector((state) => state?.others?.getDictionary?.data);
  const translate = useCallback(
    (key, text) => {
      if (data) {
        return getUiTranslate(data, key, lang, text);
      }
    },
    [lang, data]
  );

  return {
    translate,
  };
};

export default useDictionary;
