import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "./footer.module.css";

import Icon from "../../assets/icons";
import useDictionary from "../../hooks/lang/useDictionary";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

const Footer = () => {
  const { translate } = useDictionary();
  const [contactData, setContactData] = useState(null);
  const [phones, setPhones] = useState([]);
  const contactInfo = useSelector(
    (state) => state?.others?.getContactInfo?.data
  );

  useEffect(() => {
    if (contactInfo) {
      setContactData(contactInfo?.branches[0]);
    }
  }, [contactInfo]);

  useEffect(() => {
    if (contactData) {
      setPhones(contactData?.phone?.split(", "));
    }
  }, [contactData]);

  return (
    <div className="container-con footer-con">
      <div className="container">
        <div className={styled.footer}>
          <div className="flex flex-col gap-8">
            <a href="/">
              <img
                src={contactInfo?.logo2}
                className={styled.footerLogo}
                alt="Logo"
              />
            </a>
            <div className={styled.social}>
              {contactInfo?.social_links?.map((social, id) => (
                <a href={social?.link} key={id}>
                  <img
                    src={social?.social_image}
                    alt={social?.platform}
                    title={social?.platform}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <p className="title1">
              {translate("payment-types", "Payment Types")}
            </p>
            <div className="flex items-center gap-4">
              <button className="cursor-pointer">
                <img
                  src={Icon.kpay}
                  className={styled.payment}
                  alt="Kpay"
                  title="Kpay"
                />
              </button>
              <button className="cursor-pointer">
                <img
                  src={Icon.cashOn}
                  className={styled.payment}
                  alt="Cash ON"
                  title="Cash ON Delivery"
                />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <p className="title1">{translate("useful-link", "Useful Links")}</p>
            <div className="flex flex-col gap-3">
              <Link to="/about-us" className="lg:hover:text-red-4">
                {translate("about-us", "About Us")}
              </Link>
              <Link to="/contact-us" className="lg:hover:text-red-4">
                {translate("contact-us", "Contact Us")}
              </Link>
              <Link to="/faq" className="lg:hover:text-red-4">
                {translate("faq", "FAQs")}
              </Link>
              <Link to="/privacy-policy" className="lg:hover:text-red-4">
                {translate("privacy_policy", "Privacy Policy")}
              </Link>
              <Link to="/terms-conditions" className="lg:hover:text-red-4">
                {translate("terms-and-conditions", "Terms & Conditions")}
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <p className="title1">{translate("contact-us", "Contact Us")}</p>
            <div className="flex flex-col gap-5">
              <div className="flex gap-2">
                <img
                  src={Icon.map}
                  className={styled.contactImg}
                  alt="Address Map"
                  title="Address Map"
                />
                <p className={styled.footerP}>{contactData?.address}</p>
              </div>
              <div className="flex flex-wrap gap-2">
                <img
                  src={Icon.phone}
                  className={styled.contactImg}
                  alt="phone"
                  title="Phone"
                />
                <div className="flex gap-1">
                  {phones?.map((phone) => (
                    <a
                      className="ml-[5px]"
                      href={`tel:09${phone?.split("-")[1]}`}
                      key={phone}
                    >
                      {phone}
                    </a>
                  ))}
                </div>
              </div>
              <div className="flex gap-2">
                <img
                  src={Icon.envelope}
                  className={styled.contactImg}
                  alt="Mail"
                  title="Mail"
                />
                <a href="mailto:contact@company.com">{contactData?.email}</a>
              </div>
            </div>
          </div>
        </div>
        <p className="text-black-4 text-sm font-muli !font-normal">
          Copyright © NPT PET SHOP 2023. All right reserved. Powered by
          <Button
            // href="https://www.innovixdigital.com"
            className=" hover:underline text-blue-600 hover:text-blue-600"
            onClick={() => window.open("https://www.innovixdigital.com")}
          >
            {" "}
            Innovix Digital.
          </Button>
        </p>
      </div>
    </div>
  );
};
export default Footer;
