import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "../../../screens/checkout/checkout.module.css";
import Button from "../button/Button";
import callApi from "../../../services/api/apiClient";
import FileUploader from "../../../screens/checkout/FileUploader";
import useDictionary from "../../../hooks/lang/useDictionary";
import CloseIcon from "@mui/icons-material/Close";

const PayNowPopup = ({ onClick, orderId }) => {
  const [isPay, setIsPay] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const userInfo = useSelector((state) => state?.auth?.getUser?.data);
  const { translate } = useDictionary();

  const paymentTypes = useSelector(
    (state) => state?.checkout?.getPaymentTypes?.data
  );

  const handleRePay = () => {
    const formData = new FormData();
    formData.append("payslip_image", selectedFile);
    formData.append("acquirer_id", isPay?.id);
    callApi("checkout/uploadFile")
      .withSegment(`${orderId}/payment_slip`)
      .withBody(formData)
      .loadingGroup("order")
      .execute()
      .then((res) => {
        if (res?.status) {
          onClick();
        }
      });
  };

  return (
    <div className="fixed w-full h-screen bg-[#333333e5] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className="w-[90%] md:w-[500px] min-h-[150px] bg-white border-t-[10px] border-red-1 rounded-[5px] p-[20px] md:p-[40px] relative">
        <div className=" mb-3">

          <span className="flex relative">
            <h5 className=" mb-3">{translate("payment", "Payment")}</h5>
            <Button className=" absolute right-0" onClick={onClick} >
              <CloseIcon style={{ fontSize: 18 }} />
            </Button>
          </span>

          {/* <h5 className=" mb-3">{translate("payment", "Payment")}</h5> */}
          <span className=" text-red-3">
            {translate("choose-payment-type", "Choose Payment Type")}
          </span>
        </div>
        <div className=" grid gap-[20px]">
          {userInfo?.user_type == "b2b" ? (
            <>
              {paymentTypes?.map(
                (payment, id) =>
                  payment.code != "COD" && (
                    <div
                      className={`flex h-[75px] sm:h-[60px] items-center justify-between p-[15px] border ${isPay.code === payment.code
                          ? " border-red-4"
                          : "border-gray-200"
                        } rounded-[5px]`}
                      key={id}
                    >
                      <>
                        <input
                          id={`radio-pay-${id}`}
                          type="radio"
                          checked={isPay.code == payment.code}
                          name="bordered-radio"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                          onChange={() => setIsPay(payment)}
                        />
                        <label
                          htmlFor={`radio-pay-${id}`}
                          className={styled.label}
                        >
                          {payment?.name} <br />
                          {payment?.account_no || null}{" "}
                          {payment?.account_name
                            ? `(${payment?.account_name})`
                            : null}
                        </label>
                      </>
                      <div className="">
                        <img src={payment?.image} alt="" />
                      </div>
                    </div>
                  )
              )}
            </>
          ) : (
            <>
              {paymentTypes?.map((payment, id) => (
                <div
                  className={`flex h-[75px] sm:h-[60px] items-center justify-between p-[15px] border ${isPay.code === payment.code
                      ? " border-red-4"
                      : "border-gray-200"
                    } rounded-[5px]`}
                  key={id}
                >
                  <>
                    <input
                      id={`radio-pay-${id}`}
                      type="radio"
                      checked={isPay.code == payment.code}
                      name="bordered-radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={() => setIsPay(payment)}
                    />
                    <label htmlFor={`radio-pay-${id}`} className={styled.label}>
                      {payment?.name}
                    </label>
                  </>
                  <div className="">
                    <img src={payment?.image} alt="" />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {userInfo?.user_type !== "b2b" && isPay?.payment_image && (
          <>
            <div className=" w-fit p-[10px] rounded-[13px] border mx-auto my-[20px]">
              <img
                src={`${isPay?.payment_image}`}
                alt=""
                className=" w-[170px] h-[170px]"
              />
            </div>
            <p className="flex justify-center body">Scan QR for payment</p>
          </>
        )}
        {isPay?.code !== "COD" && (
          <div className="mt-[20px]">
            <FileUploader
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </div>
        )}
        <Button
          className={`w-full h-[47px] ${isPay && selectedFile ? "bg-red-3 hover:bg-red-4" : "bg-red-2"
            } rounded-[8px] text-white mt-5`}
          onClick={handleRePay}
          disabled={isPay && selectedFile ? false : true}
        >
          {translate("submit", "Submit")}
        </Button>
      </div>
    </div>
  );
};

export default PayNowPopup;
