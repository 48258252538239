import React from "react";
import Button from "../../components/common/button/Button";
import Images from "../../assets/images/index";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import { useSelector } from "react-redux";
import { formatNumber } from "../../utils/generals";

const ShowOrderSuccess = ({ onClick, data }) => {
  const { translate } = useDictionary();
  const lang = useLanguage();
  const userInfo = useSelector((state) => state?.auth?.getUser?.data);

  return (
    <div className=" fixed w-full h-screen bg-[#333333c9] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className=" w-[90%] md:w-[450px] min-h-[300px] bg-white border-t-[20px] border-red-1 rounded-[5px] p-[20px] md:p-[40px]">
        <div className="">
          <img
            src={Images.orderSuccessImg}
            alt=""
            className=" w-[90px] mx-auto"
          />

          {userInfo?.user_type == "b2b" ? (
            <>
              <h5 className=" text-center mt-[20px]">
                {translate("your-orders-have-been-placed-successfully-b2b", "Your orders have been placed successfully!")}
              </h5>
              <p className="body text-center mt-[15px]">
                {lang === "en"
                  ? "Thank you for ordering, we will check your order and reply as soon as possible"
                  : "အော်ဒါမှာသည့်အတွက် ကျေးဇူးတင်ပါတယ်၊ သင့်အော်ဒါကိုစစ်ဆေးပြီးအကြောင်းပြန်ပါမည်။"}
              </p>
            </>
          ) : (
            <>
              <h5 className=" text-center mt-[20px]">
                {translate("your-orders-have-been-placed-successfully-b2c", "Your orders have been placed successfully!")}
              </h5>
              <p className="body flex justify-center mt-[15px]">
                {lang === "en" ? `Order ID : ` : `အော်ဒါနံပါတ် - `}{" "}
                {data.orderId}
              </p>
              <p className="body flex justify-center">
                {lang === "en" ? "Amount :" : "ကျသင့်ငွေ - "}{" "}
                {formatNumber(data.total_amount, lang)}{" "}
                {lang === "en" ? "Ks" : "ကျပ်"}
              </p>
            </>
          )}
        </div>
        <div className=" text-center mt-[20px]">
          <Button
            className={"w-[200px] p-[12px] rounded-[10px] bg-red-2 text-white"}
            onClick={onClick}
          >
            {translate("continue-shopping", "Continue Shopping")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShowOrderSuccess;
