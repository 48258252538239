import React from "react";
import Icons from "../../assets/icons/index";
import styled from "../addToCard/item_card.module.css";
import { useSelector } from "react-redux";
import useDictionary from "../../hooks/lang/useDictionary";
import { formatNumber } from "../../utils/generals";
import useLanguage from "../../hooks/lang/useLanguage";
import Image from "../../assets/images";

const OrderItem = ({ item }) => {
  const orderDetail = useSelector((state) => state?.profile?.orderDetail?.data);
  const promotionOnly = orderDetail?.order_lines?.filter(
    (cart) => cart?.product?.line_ref != false
  );

  const { translate } = useDictionary();
  const lang = useLanguage();

  // const isGift = promotionOnly?.find(
  //   (promo) => promo?.product?.line_ref == item?.product?.line_id
  // );
  const isGift = promotionOnly?.find(
    (promo) =>
      promo?.product?.line_ref[promo?.product?.line_ref?.length - 1] ===
      item?.product?.line_id
  );
  return (
    <>
      <tr className=" hidden md:table-row">
        <td className="pt-[40px]">
          <div className=" w-[100px]">
            <img
              src={
                item?.product?.is_delivery_line
                  ? Icons.deliveryChargeIcon
                  : item?.product?.image || Image.defaultImg
              }
              alt=""
              className={`${
                item?.product?.is_delivery_line
                  ? "w-[60px] ms-auto"
                  : "w-[100px]"
              } h-[100px] rounded-[5px]`}
            />
          </div>
        </td>
        <td className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]">
          <div className=" flex gap-5 items-center">
            <p className="ml-[20px] pr-10 font-semibold">
              {item?.product?.name}
            </p>
          </div>
        </td>
        <td className="pt-[40px]">
          {!item?.product?.is_delivery_line && (
            <div className="flex items-center">
              {formatNumber(item?.price_unit, lang)} {translate("ks", "Ks")}
            </div>
          )}
        </td>
        <td className="pt-[40px] md:w-[120px] text-center">
          {!item?.product?.is_delivery_line &&
            formatNumber(item?.quantity, lang)}
        </td>
        <td className="pt-[40px]">
          <div className="flex items-center justify-end">
            {formatNumber(item?.total_price, lang)} {translate("ks", "Ks")}
          </div>
        </td>
      </tr>
      <tr className="md:hidden">
        <td colSpan={5} className=" border-b-2">
          <div className="my-5 relative">
            <div className=" flex gap-5 sm:gap-5 mb-5">
              <div className=" w-[100px]">
                <img
                  src={
                    item?.product?.is_delivery_line
                      ? Icons.deliveryChargeIcon
                      : item?.product?.image || Image.defaultImg
                  }
                  alt=""
                  className={`${
                    item?.product?.is_delivery_line
                      ? "w-[50px] ms-auto"
                      : "w-[100px]"
                  } rounded-[5px]`}
                />
              </div>
              <div className="w-full">
                <p
                  className={
                    styled.width80_to_100_mobile + " sm:w-auto font-semibold"
                  }
                >
                  {item?.product?.name}
                </p>
                <div className={styled.mb_btns}>
                  {!item?.product?.is_delivery_line ? (
                    <p className={styled.mb_btns_quantity}>
                      {translate("qty", "Qty")} :{" "}
                      {formatNumber(item?.quantity, lang)}
                    </p>
                  ) : (
                    <div></div>
                  )}
                  <p className="text-xs text-sky_blue body">
                    {formatNumber(item?.total_price, lang)}{" "}
                    {translate("ks", "Ks")}
                  </p>
                </div>
              </div>
            </div>
            {/* for mobile  */}
            {isGift?.product?.reward_type == "product" && (
              <div className=" mt-3">
                <div className="flex gap-2">
                  <div className=" w-[100px]">
                    <img
                      src={isGift.product?.image || Image.defaultImg}
                      alt=""
                      className="w-[50px] rounded-[5px] ms-auto"
                    />
                  </div>
                  <div className=" w-full">
                    <p>{isGift?.product?.name}</p>
                    <div className=" flex justify-between">
                      <p className=" text-sm">
                        {translate("qty", "Qty")} :{" "}
                        {formatNumber(isGift?.quantity, lang)}
                      </p>
                      <p>{translate("free", "Free")}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isGift?.product?.reward_type == "discount" && (
              <div className=" mt-3">
                <div className="flex gap-2">
                  <div className=" w-[100px]">
                    <img
                      src={Icons.promoIcon || Image.defaultImg}
                      alt=""
                      className="w-[50px] rounded-[5px] ms-auto"
                    />
                  </div>
                  <div className=" w-full">
                    <p>{isGift?.product?.name}</p>
                    <p>{isGift?.remark}</p>
                    <div className=" flex justify-between mt-2">
                      <p className=""></p>
                      <p className=" text-sky_blue">
                        {lang !== "en" ? "- " : null}
                        {formatNumber(isGift?.total_price, lang)}{" "}
                        {translate("ks", "Ks")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isGift?.product?.reward_type == "group" && (
              <div className=" mt-3">
                <div className="flex gap-2">
                  <div className=" w-[100px]">
                    <img
                      src={isGift.product?.image || Icons.promoIcon}
                      alt=""
                      className="w-[50px] rounded-[5px] ms-auto"
                    />
                  </div>
                  <div className=" w-full">
                    <p>{isGift?.product?.name}</p>
                    <p>{isGift?.remark}</p>
                    <div className=" flex justify-between mt-2">
                      <p className=""></p>
                      {isGift?.total_price == 0 ? (
                        <p className=" text-sky_blue">
                          {translate("free", "Free")}
                        </p>
                      ) : (
                        <p className=" text-sky_blue">
                          {lang !== "en" ? "-" : null}
                          {formatNumber(isGift?.total_price, lang)}{" "}
                          {translate("ks", "Ks")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </td>
      </tr>
      {/* for tablet and laptop  */}
      {isGift?.product?.reward_type == "product" && (
        <tr className="hidden md:table-row">
          <td className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]">
            <div className=" w-[100px] flex justify-end">
              <img
                src={isGift.product?.image || Image.defaultImg}
                alt=""
                className="w-[50px] rounded-[5px]"
              />
            </div>
          </td>
          <td className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]">
            <div className="px-[20px]">
              <p>{isGift?.product?.name}</p>
              {/* <p>{isGift?.remark}</p> */}
            </div>
          </td>

          <td className=" text-center py-[13px]"></td>
          <td className=" text-center py-[13px] pt-[40px]">
            {formatNumber(isGift?.quantity, lang)}
          </td>
          <td className=" text-end py-[13px] pt-[40px]">
            {translate("free", "Free")}
          </td>
        </tr>
      )}
      {isGift?.product?.reward_type == "discount" && (
        <tr className="hidden md:table-row">
          <td
            colSpan={4}
            className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]"
          >
            <div className=" flex gap-5 items-center">
              <div className=" w-[100px] flex justify-end">
                <img
                  src={Icons.promoIcon || Image.defaultImg}
                  alt=""
                  className="w-[50px] rounded-[5px]"
                />
              </div>
              <div className="">
                <p className=" pr-10 ">{isGift?.product?.name}</p>
                <p>{isGift?.remark}</p>
              </div>
            </div>
          </td>
          <td className="pt-[40px] text-end">
            {lang !== "en" ? "- " : null}
            {formatNumber(isGift?.total_price, lang)} {translate("ks", "Ks")}
          </td>
        </tr>
      )}
      {isGift?.product?.reward_type == "group" && (
        <tr className="hidden md:table-row">
          <td className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]">
            <div className=" w-[100px] flex justify-end">
              <img
                src={isGift.product?.image || Icons.promoIcon}
                alt=""
                className="w-[50px] rounded-[5px]"
              />
            </div>
          </td>
          <td className="pt-[40px] sm:max-w-[150px] 2xl:max-w-[180px]">
            <div className="px-[20px]">
              <p>{isGift?.product?.name}</p>
              <p>{isGift?.remark}</p>
            </div>
          </td>

          <td colSpan={2} className=" text-center py-[13px]"></td>
          {isGift?.total_price == 0 ? (
            <td className=" text-end py-[13px]">{translate("free", "Free")}</td>
          ) : (
            <td className="pt-[40px] text-end">
              {lang !== "en" ? "-" : null}
              {formatNumber(isGift?.total_price, lang)} {translate("ks", "Ks")}
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default OrderItem;
