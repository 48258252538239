import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import { formatNumber } from "../../utils/generals";

const SummaryItem = ({ item }) => {
  const cartInfo = useSelector((state) => state?.cart?.getCart?.data);
  const { translate } = useDictionary();
  const language = useLanguage();

  const promotionOnly = cartInfo?.order_lines?.filter(
    (cart) => cart?.line_ref != false
  );

  // const isGift = promotionOnly?.find(
  //   (promo) => promo?.line_ref == item?.line_id
  // );
  const isGift = promotionOnly?.find(
    (promo) => promo?.line_ref[promo?.line_ref?.length - 1] === item.line_id
  );

  return (
    <Fragment>
      <tr>
        <td className="py-[13px]">{item?.name}</td>
        <td className="py-[13px] px-[13px]">
          {item.product_type === "service" ? null : item?.quantity}
        </td>
        <td className=" text-end py-[13px]">
          {formatNumber(item?.price_subtotal, language)} {translate("ks", "Ks")}
        </td>
      </tr>
      {isGift?.reward_type == "product" && (
        <tr>
          <td className="py-[13px]">{isGift?.name}</td>
          <td className="py-[13px] px-[13px]">{isGift?.quantity}</td>
          <td className=" text-end py-[13px]">{translate("free", "Free")}</td>
        </tr>
      )}
      {isGift?.reward_type == "discount" && (
        <tr>
          <td className="py-[13px]">{isGift?.name}</td>
          <td className="py-[13px]"></td>
          <td className=" text-end py-[13px]">
            {language !== "en" ? "- " : null}
            {formatNumber(isGift?.price_subtotal, language)}{" "}
            {translate("ks", "Ks")}
          </td>
        </tr>
      )}
      {isGift?.reward_type == "group" && (
        <tr>
          <td className="py-[13px]">{isGift?.name}</td>
          <td className="py-[13px] px-[13px]">{isGift?.quantity}</td>
          {isGift?.price_subtotal === 0 ? (
            <td className=" text-end py-[13px]">{translate("free", "Free")}</td>
          ) : (
            <td className=" text-end py-[13px]">
              {language !== "en" ? "- " : null}
              {formatNumber(isGift?.price_subtotal, language)}{" "}
              {translate("ks", "Ks")}
            </td>
          )}
        </tr>
      )}
    </Fragment>
  );
};

export default SummaryItem;
