import React from "react";

const ProductVariant = (props) => {
  const {
    data,
    variants,
    index,
    setVariants,
    variantPriceLists,
    setVariantPriceLists,
  } = props;
  // console.log(variants, "variants");
  const addVariant = (variant_id, index) => {
    // Create a copy of the current variants array
    const variantsCopy = [...variants];

    // add data with index
    variantsCopy[index] = variant_id;

    // Update the state with the modified copy
    setVariants(variantsCopy);
  };
  const addPrice = (price, index) => {
    const variantsCopy = [...variantPriceLists];

    // add data with index
    variantsCopy[index] = price;

    // Update the state with the modified copy
    setVariantPriceLists(variantsCopy);
  };
  return (
    <div className="">
      <p className=" text-black-3">{data?.name}</p>
      <div className=" flex gap-3 items-center mt-[5px]">
        {data?.vals?.map((variant, id) => (
          <div className="" key={id}>
            {variant?.photo ? (
              <div
                className=""
                onClick={() => {
                  addVariant(variant?.id, index);
                  addPrice(variant?.price, index);
                }}
              >
                <img
                  src={variant?.photo}
                  alt={variant?.photo}
                  title={variant?.name}
                  className={` w-[50px] h-[50px] rounded border cursor-pointer hover:border-red-2 ${
                    variants[index] == variant?.id
                      ? " border-red-4 hover:border-red-4"
                      : null
                  }`}
                />
              </div>
            ) : (
              <div
                className={`px-2 py-[1px] rounded border cursor-pointer hover:border-red-2 ${
                  variants[index] == variant?.id
                    ? " border-red-4 hover:border-red-4 font-muliBold"
                    : null
                }`}
                onClick={() => {
                  addVariant(variant?.id, index);
                  addPrice(variant?.price, index);
                }}
              >
                {variant?.name}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductVariant;
