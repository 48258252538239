import React from "react";
import Icons from "../../../assets/icons/index";
import useDictionary from "../../../hooks/lang/useDictionary";
import Button from "../../../components/common/button/Button";
import { useNavigate } from "react-router-dom";

const CartSuccess = ({ onClick }) => {
  const { translate } = useDictionary();
  const navigate = useNavigate();
  return (
    <div className="fixed w-full h-screen bg-[#333333e5] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className=" w-[300px] bg-white rounded-[10px] border-t-2 border-t-red-1 py-[20px]">
        <img src={Icons.cartSuccessIcon} alt="" className=" w-[80px] mx-auto" />
        <h5 className=" text-center mt-3">
          {translate("add-to-cart-successful", "Add to cart successful")}
        </h5>
        <Button
          className={` bg-red-3 cursor-pointer hover:bg-[#c8040b] w-[150px] sm:w-[200px] h-[40px] rounded-[8px] flex items-center justify-center text-lg text-white font-muli mx-auto mt-[20px]`}
          onClick={onClick}
          children={translate("go-to-cart", "Add to Cart")}
        />
      </div>
    </div>
  );
};

export default CartSuccess;
