import React, { useState, useEffect, useRef } from "react";
import styled from "./header.module.css";
import Icon from "../../assets/icons";
import {
  BsChevronDown,
  BsChevronUp,
  BsChevronRight,
  BsChevronLeft,
} from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { dispatch } from "../../redux/util/dispatchStore";
import { ProfilePopupSlice, languageSlice } from "../../helper/customSlice";
import useLanguage from "../../hooks/lang/useLanguage";
import useDictionary from "../../hooks/lang/useDictionary";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import useAuth from "../../hooks/lang/useAuth";
import { ProductMenu } from "./ProductMenu";
import Images from "../../assets/images";
import Logout from "../../redux/util/logoutFunction";

const Header = () => {
  const myRef = useRef(null);
  const navigate = useNavigate();
  const language = useLanguage();
  const { translate } = useDictionary();
  const [title, setTitle] = useState("Home");
  const [checked, setChecked] = useState(true);
  const [product, setProduct] = useState(false);
  const [brand, setBrand] = useState(false);
  const [cardQty, setCartQty] = useState(0);
  const [searchProduct, setSearchProduct] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});
  const location = useLocation();
  const token = useAuth();

  //   useEffect(() => {
  //     if (language == "my") {
  //       setChecked(true);
  //     } else {
  //       setChecked(false);
  //     }
  //   }, []);

  useEffect(() => {
    if (language == "my") {
      setChecked(false);
      dispatch(languageSlice.actions.changeLanguage("my"));
    } else {
      setChecked(true);
      dispatch(languageSlice.actions.changeLanguage("en"));
    }
  }, [language]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = e => {
    if (!myRef?.current?.contains(e.target)) {
      if (!e.target?.href) {
        if (!e.target?.onclick) {
          dispatch(ProfilePopupSlice.actions.setShowMbProfile(false));
        }
      }
    }
  };

  const handleLogout = () => {
    Logout()
  };

  const handleSearch = () => {
    if (searchProduct) {
      navigate(`/search?name=${searchProduct}`);
      setSearchProduct("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const brandLists = useSelector(
    (state) => state?.products?.getBrandsList?.data
  );

  const addToCartStatus =
    useSelector((state) => state?.persistSlice?.addToCart?.frame_status) || "";

  const orderInfo = useSelector(
    (state) => state?.persistSlice?.addToCart?.data
  );

  const contactInfo = useSelector(
    (state) => state?.others?.getContactInfo?.data
  );

  const cartQtyInfo = useSelector((state) => state?.cart?.getCart?.data);
  const updateCart = useSelector((state) => state?.cart?.updateQtyCart?.data);
  const removeCartInfo = useSelector((state) => state?.cart?.removeCart?.data);
  const clearCartInfo = useSelector((state) => state?.cart?.clearCart?.data);
  const categories = useSelector(
    (state) => state?.products?.MyCategories?.data
  );
  const showMbProfile = useSelector(
    (state) => state?.ProfilePopupSlice?.showMbProfile
  );

  useEffect(() => {
    if (language == "en") {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [language]);

  useEffect(() => {
    callApi("others/getContactInfo")
      .loadingGroup("contact_info")
      .executeDispatch();
    callApi("checkout/getShippingLocations").executeDispatch();
  }, [language]);

  useEffect(() => {
    if (cartQtyInfo) {
      // setCartQty(
      //   cartQtyInfo?.order_lines?.reduce((pre, cur) => {
      //     if (cur?.is_delivery_line === false) {
      //       if (cur?.reward_type == false) {
      //         return pre + Number(cur?.quantity);
      //       } else {
      //         return pre;
      //       }
      //     } else {
      //       return +Number(cur?.quantity);
      //     }
      //   }, 0)
      // );

      // console.log(cartQtyInfo, "cartQtyInfoif");
      // setCartQty(
      //   cartQtyInfo?.order_lines?.reduce(
      //     (pre, cur) => {
      //       if (cur?.is_delivery_line == false) {
      //         return pre + Number(cur?.quantity)
      //       }
      //       if (cur?.reward_type == "discount") {
      //         return pre + Number(cur?.quantity)
      //       }
      //       else {
      //         return pre, 0
      //       }
      //     }
      //   )
      // );
      setCartQty(
        cartQtyInfo?.order_lines?.reduce(
          (pre, cur) =>
            cur?.is_delivery_line == false && cur?.reward_type == false
              ? pre + Number(cur?.quantity)
              : pre,
          0
        )
      );
    } else {
      if (!cartQtyInfo) {
        setCartQty(0);
      } else if (cartQtyInfo?.order_lines?.length < 0) {
        setCartQty(0);
      }
    }
  }, [cartQtyInfo, orderInfo, updateCart, removeCartInfo, clearCartInfo]);

  useEffect(() => {
    if (orderInfo?.id) {
      callApi("cart/getCart")
        .withSegment(orderInfo?.id)
        .withParam({ lang: language })
        .loadingGroup("c")
        .executeDispatch();
    }
  }, [
    orderInfo?.id,
    updateCart,
    removeCartInfo,
    clearCartInfo,
    addToCartStatus,
  ]);

  useEffect(() => {
    callApi("products/getBrandsList")
      .withParam({ lang: language })
      .loadingGroup("p")
      .executeDispatch();

    callApi("auth/getUser").loadingGroup("p").executeDispatch();

    callApi("products/MyCategories").loadingGroup("p").executeDispatch();
  }, [language, token]);

  let category = "";
  let subCate = "";
  let detailCate = "";
  let lengthArr = [];
  let heightLenght = 0;
  useEffect(() => {
    category = document.querySelectorAll("#category button");
    subCate = document.querySelectorAll("#sub-category button");
    detailCate = document.querySelectorAll("#productDetailTitle a");
    if (detailCate.length / 2 === 0) {
      // console.log(detailCate.length);
    } else {
      detailCate = Number(detailCate.length / 2) + 0.5;
    }
    lengthArr = [category.length, subCate.length, detailCate];
    heightLenght = Math.max(...lengthArr);
  }, [product]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => { }, [title]);

  const Brand = ({ item }) => {
    return (
      // <div className="container-con header-expand">
      //   <div className="container">
      //     <div className="flex flex-row flex-wrap gap-8 my-8 justify-center">
      //       {item?.map((brand, index) => (
      //         <Link
      //           to={`/brand/${brand.ID}?name=${brand.name}&pagename=brand`}
      //           to={`/filter?brand_id=${brand.ID}`}
      //           className={styled.brandCon}
      //           key={index}
      //           onClick={(_) => ChooseType("Brand")}
      //         >
      //           <img
      //             src={brand.image}
      //             alt={brand.name}
      //             title={brand.name}
      //             className={styled.brandImage}
      //           />
      //           <p className="secondary-body">{brand.name}</p>
      //         </Link>
      //       ))}
      //     </div>
      //   </div>
      // </div>

      <div className="container-con header-expand">
        <div className="w-full">
          <div className={styled.productList + " flex justify-center"}>
            <div className="w-full bg-white shadow">
              <div className="flex flex-row flex-wrap gap-8 my-8 justify-center">
                {item?.map((brand, index) => (
                  <Link
                    to={`/filter?brand_id=${brand.ID}`}
                    className={styled.brandCon}
                    key={index}
                    onClick={(_) => ChooseType("Brand")}
                  >
                    <img
                      src={brand.image || Images.defaultImg}
                      alt={brand.name}
                      title={brand.name}
                      className={styled.brandImage}
                    />
                    <p className="secondary-body">{brand.name}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div class="h-[100%]" onClick={(_) => ChooseType("")}></div>
        </div>
      </div>
    );
  };
  const ChooseType = (type) => {
    if (type === "Product") {
      setTitle("Product");
      setProduct(!product);
      setBrand(false);
    } else if (type === "Brand") {
      setTitle("Brand");
      setBrand(!brand);
      setProduct(false);
    } else {
      setTitle(type);
      setBrand(false);
      setProduct(false);
    }
  };

  // Mobile
  const [menu, setMenu] = useState(false);
  const [productMb, setProductMb] = useState(false);
  const [brandMb, setBrandMb] = useState(false);
  const [subMb, setSubMb] = useState(false);
  const [subsubMb, setSubSubMb] = useState(false);

  const MainMenuMb = ({ item }) => {
    return (
      <div className="container absolute">
        <div className="flex flex-col">
          <button onClick={() => setMenu(!menu)}>
            <Link to="/">{translate("home", "Home")}</Link>
          </button>
          <button onClick={() => setProductMb(!productMb)}>
            <p className={styled.p}>{translate("product", "Product")}</p>
            <BsChevronRight size={18} />
          </button>
          <button onClick={() => setBrandMb(!brandMb)}>
            <p className={styled.p}>{translate("brand", "Brand")}</p>
            <BsChevronRight size={18} />
          </button>
          <button onClick={() => setMenu(!menu)}>
            <Link to="/about-us">{translate("about-us", "About Us")}</Link>
          </button>
          <button onClick={() => setMenu(!menu)}>
            <Link to="/contact-us">
              {translate("contact-us", "Contact Us")}
            </Link>
          </button>

          <button onClick={() => setMenu(!menu)}>
            <Link to="/branches">{translate("branches", "Branches")}</Link>
          </button>
        </div>
        <div className="flex flex-col border-b border-b-black-1 py-4 gap-3">
          <div className={styled.hotcon}>
            <img src={Icon.headset_mb} alt="Hotline" />
            <a href={`tel:${contactInfo?.hotline}`}>
              {translate("hotline", "Hotline")} : {contactInfo?.hotline}
            </a>
          </div>
          <div className={styled.hotcon}>
            <img src={Icon.delivery_mb} alt="Hotline" />
            <p>
              {translate("free-delivery-over", "Free Delivery over 100,000Ks")}
            </p>
          </div>
        </div>
        <div className="pt-4">
          <label className="inline-flex items-center cursor-pointer">
            <span
              className="mr-2 text-black-3 text-base"
              onClick={() => {
                setChecked(false)
                dispatch(languageSlice.actions.changeLanguage("my"));
              }}
            >
              {translate("english", "EN")}
            </span>
            <span className="relative">
              <input
                type="checkbox"
                defaultValue={checked}
                className="sr-only"
              />
              <div
                onClick={() => {
                  setChecked(!checked)
                  if (language == "en") {
                    dispatch(languageSlice.actions.changeLanguage("my"));
                  }
                  else {
                    dispatch(languageSlice.actions.changeLanguage("en"));
                  }
                }}
                className=" w-[40px] h-[20px] bg-white rounded-full flex items-center relative border-[1px] border-red-4"
              >
                <div
                  className={`absolute top-[1.5px] flex items-center transition-all duration-300 ${checked ? "left-1" : "right-1"
                    }`}
                >
                  <div
                    className={`transition-all duration-300 w-[15px] h-[15px] bg-red-4 rounded-full`}
                  ></div>
                </div>
              </div>
            </span>
            <span
              className="ml-2 text-black-3 text-base"
              onClick={() => {
                setChecked(true)
                dispatch(languageSlice.actions.changeLanguage("en"));
              }}
            >
              {translate("myanmar", "MY")}
            </span>
          </label>
        </div>
      </div>
    );
  };
  const ProductMobile = ({ item }) => {
    return subMb ? (
      <div className={styled["ListCon"] + " " + styled.active}>
        <div className="w-full bg-black-0 flex justify-center sticky top-0 bg-gray-100">
          <div className="container">
            <div
              className={styled.productTitle}
              onClick={() => setSubMb(false)}
            >
              <BsChevronLeft size={18} />
              {selectedCategory?.category_name}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex flex-col">
            {selectedCategory?.sub_category?.map((category, index) => {
              return category?.sub_category?.length > 0 ? (
                <a
                  href={`/filter?category_id=${category.category_id}`}
                  key={index}
                  onClick={() => {
                    setSelectedSubCategory(category);
                    setSubSubMb(true);
                    setSubMb(false);
                  }}
                >
                  {category.category_name}
                  {category?.sub_category?.length > 0 ? (
                    <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                  ) : null}
                </a>
              ) : (
                <a
                  href={`/filter?category_id=${category.category_id}`}
                  onClick={() => {
                    setSubSubMb(false);
                    setSubMb(true);
                  }}
                >
                  {category.category_name}
                  {category?.sub_category?.length > 0 ? (
                    <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                  ) : null}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    ) : subsubMb ? (
      <div className={styled["ListCon"] + " " + styled.active}>
        <div className="w-full bg-black-0 flex justify-center sticky top-0 hover:text-red-4">
          <div className="container">
            <div
              className={styled.productTitle}
              onClick={() => setSubSubMb(false)}
            >
              <BsChevronLeft size={18} />
              {selectedSubCategory?.category_name}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex flex-col">
            {selectedSubCategory?.sub_category?.map((category, index) => {
              return (
                <a
                  href={`/filter?category_id=${selectedCategory.category_id}`}
                  className="hover:underline"
                  key={index}
                  onClick={() => {
                    setSubMb(false);
                  }}
                >
                  {category.category_name}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    ) : (
      <div className={styled["ListCon"] + " " + styled.active}>
        <div className="w-full bg-black-0 flex justify-center sticky top-0">
          <div className="container">
            <div
              className={styled.productTitle}
              onClick={() => setProductMb(!productMb)}
            >
              <BsChevronLeft size={18} />
              {translate("product", "Product")}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex flex-col">
            {categories?.map((category, index) => {
              return (
                <button
                  // href="/"
                  key={index}
                  onClick={() => {
                    setSelectedCategory(category);
                    setSubMb(true);
                  }}
                >
                  {category.category_name}
                  {category?.sub_category?.length > 0 ? (
                    <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                  ) : null}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const BrandMb = ({ item }) => {
    return (
      <div className={styled["ListCon"] + " " + styled.active}>
        <div className="w-full bg-black-0 flex justify-center sticky top-0">
          <div className="container">
            <div
              className={styled.productTitle}
              onClick={() => setBrandMb(!brandMb)}
            >
              <BsChevronLeft size={18} />
              {translate("brand", "Brand")}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex flex-col">
            {brandLists?.map((brand, index) => (
              <Link
                // to={`/brand/${brand.ID}?name=${brand.name}&pagename=brand`}
                to={`/filter?brand_id=${brand.ID}`}
                onClick={onChangeMenu}
                key={index}
                className=" flex gap-3"
              >
                <img
                  src={brand.image || Images.defaultImg}
                  alt={brand.name}
                  title={brand.name}
                />
                {brand.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const onChangeMenu = () => {
    dispatch(ProfilePopupSlice.actions.setShowMbProfile(false));
    setMenu(!menu);
    setBrandMb(false);
    setProductMb(false);
  };
  const closePopup = () => {
    dispatch(ProfilePopupSlice.actions.setShowMbProfile(false));
  };

  return (
    <>
      <div className={styled.mainHeader}>
        <div className={styled.firstHeader}>
          <div className="flex gap-x-2 items-center">
            <img src={Icon.delivery} alt="delivery" />
            <p
              className={`${styled.freeDel}
                ${language === "en"
                  ? "text-sm lg:text-base"
                  : "text-xs lg:text-sm"
                }
              `}
            >
              {translate("free-delivery-over", "Free Delivery over 100,000Ks")}
            </p>
          </div>
          <div className={styled.contact}>
            <div className="flex gap-x-2 items-center">
              <img
                src={Icon.headset}
                className={styled.hotline}
                alt="Headset"
              />
              <a
                href={`tel:${contactInfo?.hotline}`}
                className={`${styled.hotlineNo}
                ${language === "en"
                    ? "text-sm lg:text-base"
                    : "text-xs lg:text-sm"
                  }
              `}
              >
                {translate("hotline", "Hotline")} : {contactInfo?.hotline}
              </a>
            </div>
          </div>
          <div className={styled.info}>
            <label className="inline-flex items-center cursor-pointer">
              <span
                className={`${styled.span}
                ${language === "en"
                    ? "text-sm lg:text-base"
                    : "text-xs lg:text-sm"
                  }
              `}
                onClick={() => {
                  setChecked(false)
                  dispatch(languageSlice.actions.changeLanguage("my"));
                }}
              >
                {translate("english", "EN")}
              </span>
              <span className="relative">
                <input
                  type="checkbox"
                  defaultValue={checked}
                  className="sr-only"
                />
                <div
                  onClick={() => {
                    setChecked(!checked)
                    if (language == "en") {
                      dispatch(languageSlice.actions.changeLanguage("my"));
                    }
                    else {
                      dispatch(languageSlice.actions.changeLanguage("en"));
                    }
                  }}
                  className=" w-[40px] h-[20px] bg-white rounded-full flex items-center relative"
                >
                  <div
                    className={`absolute transition-all duration-300 ${checked ? "left-1" : "right-1"
                      }`}
                  >
                    <div
                      className={`transition-all duration-300 w-[15px] h-[15px] bg-red-4 rounded-full`}
                    ></div>
                  </div>
                </div>
              </span>
              <span
                className={`${styled.span}
                ${language === "en"
                    ? "text-sm lg:text-base"
                    : "text-xs lg:text-sm"
                  }
              `}
                onClick={() => {
                  setChecked(true)
                  dispatch(languageSlice.actions.changeLanguage("my"));
                }}
              >
                {translate("myanmar", "MY")}
              </span>
            </label>
            <div className={styled.profile}>
              <img
                src={Icon.profile}
                className={styled.profileImg}
                alt="Profile"
              />
              <div className={styled.profileListCon}>
                {token ? (
                  <div className={styled.pfActive}>
                    <Link
                      to="/profile?category=profile"
                      className={styled.profileList}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.person}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("profile", "Profile")}
                    </Link>
                    <Link
                      to="/profile?category=shipping-address"
                      className={styled.profileList}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.mapPin}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("shipping-address", "Shipping Address")}
                    </Link>
                    <Link
                      to="/profile?category=wishlist"
                      className={styled.profileList}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.favorite}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("wishlist", "Wishlist")}
                    </Link>
                    <Link
                      to="/profile?category=order-history"
                      className={styled.profileList}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.receipt}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("order-history", "Order History")}
                    </Link>
                    <Link to="/change" className={styled.profileList}>
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.key}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("change-password", "Change Password")}
                    </Link>
                    <span className={styled.profileList} onClick={handleLogout}>
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.logout}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("logout", "Log Out")}
                    </span>
                  </div>
                ) : (
                  <div className={styled.pfActive}>
                    <Link to="/login" className={styled.profileList}>
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.loginIcon}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("login", "Login")}
                    </Link>
                    <Link to="/sign-up" className={styled.profileList}>
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.registerIcon}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("register", "Register")}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div
              className={styled.cartCon}
              onClick={() => navigate("/add-to-card")}
            >
              <img src={Icon.cart} className={styled.cartImg} alt="Cart" />
              {cardQty ? (
                <div className={styled.cartCountCon}>
                  <p className={styled.cartCount}>{cardQty || null}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styled.secondHeader}>
          <a href="/" className={styled.logoCon}>
            {/* <img src={Icon.shape} className={styled.shape} alt="shape" /> */}
            {/* <div className={styled.s_curved}></div> */}
            <div className={styled.scurvedbg}>
              <div className={styled.scurvedImgContainer}>
                <img
                  src={contactInfo?.logo1}
                  className={styled.logo}
                  alt="Logo"
                />
              </div>
              <div className={styled.scurvedbgbottom}></div>
              <div className={styled.scurvedtop}></div>
              <div className={styled.scurvedbottom}></div>
            </div>
          </a>
          <ul className={styled.menuCon}>
            <li
              className={styled.li}
              onClick={() => {
                ChooseType("Home");
              }}
            >
              <Link
                to="/"
                className={`${
                  // title === "Home" &&
                  location.pathname === "/" && !product && !brand
                    ? styled.active
                    : ""
                  } ${language === "my"
                    ? "lg:text-sm 2xl:text-base"
                    : "text-base font-righteous"
                  }`}
              >
                {translate("home", "Home")}
              </Link>
            </li>
            <li
              className={`${title === "Product" && product
                ? //  &&
                // (location.pathname === "/" ||
                //   location.pathname === "/about-us" ||
                //   location.pathname === "/contact-us" ||
                //   location.pathname === "/branches")
                styled["li"] + " " + styled.active
                : styled.li
                }`}
              onClick={(_) => ChooseType("Product")}
            >
              <p
                className={`${language === "my"
                  ? "lg:text-sm 2xl:text-base"
                  : "text-base font-righteous"
                  }`}
              >
                {translate("product", "Product")}
              </p>
              <div className="cursor-pointer ms-2">
                {product ? (
                  <BsChevronUp size={16} />
                ) : (
                  <BsChevronDown size={16} />
                )}
              </div>
            </li>
            <li
              className={`${title === "Brand" && brand
                ? //  &&
                // (location.pathname === "/" ||
                //   location.pathname === "/about-us" ||
                //   location.pathname === "/contact-us" ||
                //   location.pathname === "/branches")
                styled["li"] + " " + styled.active
                : styled.li
                }`}
              onClick={(_) => ChooseType("Brand")}
            >
              <p
                className={`${language === "my"
                  ? "lg:text-sm 2xl:text-base"
                  : "text-base font-righteous"
                  }`}
              >
                {translate("brand", "Brand")}
              </p>
              <div className="cursor-pointer ms-2">
                {brand ? (
                  <BsChevronUp size={16} />
                ) : (
                  <BsChevronDown size={16} />
                )}
              </div>
            </li>
            <li
              className={styled.li}
              onClick={() => {
                ChooseType("About Us");
              }}
            >
              <Link
                to="/about-us"
                className={`${(title === "About Us" || (!product && !brand)) &&
                  location.pathname === "/about-us"
                  ? styled.active
                  : ""
                  } ${language === "my"
                    ? "lg:text-sm 2xl:text-base"
                    : "text-base font-righteous"
                  }`}
              >
                {translate("about-us", "About Us")}
              </Link>
            </li>
            <li
              className={styled.li}
              onClick={() => {
                ChooseType("Contact Us");
              }}
            >
              <Link
                to="/contact-us"
                className={`${(title === "Contact Us" || (!product && !brand)) &&
                  location.pathname === "/contact-us"
                  ? styled.active
                  : ""
                  } ${language === "my"
                    ? "lg:text-sm 2xl:text-base"
                    : "text-base font-righteous"
                  }`}
              >
                {translate("contact-us", "Contact Us")}
              </Link>
            </li>
            <li
              className={styled.li}
              onClick={() => {
                ChooseType("Branches");
              }}
            >
              <Link
                to="/branches"
                className={`${(title === "Branches" ||
                  title === "Contact Us" ||
                  (!product && !brand)) &&
                  location.pathname === "/branches"
                  ? styled.active
                  : ""
                  } ${language === "my"
                    ? "lg:text-sm 2xl:text-base"
                    : "text-base font-righteous"
                  }`}
              >
                {translate("branches", "Branches")}
              </Link>
            </li>
          </ul>
          <div className={styled.search}>
            <div className="relative flex w-full flex-wrap items-stretch rounded-lg border border-solid border-neutral-300">
              <input
                type="search"
                className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out dark:border-neutral-600 dark:text-[#252525] dark:placeholder:text-[#252525]"
                placeholder={translate("search", "Search") + "..."}
                aria-label="Search"
                aria-describedby="button-addon1"
                value={searchProduct}
                onKeyDown={handleKeyDown}
                onChange={(e) => setSearchProduct(e.target.value)}
              />
              {/*Search button*/}
              <button
                className="relative z-[2] flex items-center rounded-r bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white  focus:bg-primary-700 focus:outline-none focus:ring-0 active:bg-primary-800"
                type="button"
                id="button-addon1"
                data-te-ripple-init=""
                data-te-ripple-color="light"
                onClick={handleSearch}
              >
                <img src={Icon.search} className="h-5 w-5" alt="search" />
              </button>
            </div>
          </div>
        </div>
        {
          /* Product Listing */
          product ? (
            <ProductMenu
              categories={categories}
              ChooseType={ChooseType}
              setProduct={setProduct}
            />
          ) : /* Brand Listing */
            brand ? (
              <Brand item={brandLists} />
            ) : null
        }
      </div>
      {/* Mobile View */}
      <div className={styled.mbHeader}>
        {/* Main Logo Header */}
        <div className="w-full flex items-center justify-center bg-red-4">
          <div className="container">
            <div className={styled.mbFirstHeader}>
              <a href="/">
                <img
                  src={Icon.Mb_logo}
                  alt="Mobile Logo"
                  title="NPT mobile logo"
                />
              </a>
              {/* <div className={styled.info}> */}
              <div
                className=" flex"
              // className={`${styled.profile}`}
              >
                <div
                  className={`${styled.cartCon}  -right-[24px]`}
                  ref={myRef}
                  onClick={() =>
                    dispatch(
                      ProfilePopupSlice.actions.setShowMbProfile(!showMbProfile)
                    )
                  }
                >
                  <img
                    src={Icon.profile}
                    className={styled.cartImg}
                    alt="Profile"
                  />
                </div>
                <div
                  className={styled.cartCon}
                  onClick={() => {
                    navigate("/add-to-card");
                    dispatch(ProfilePopupSlice.actions.setShowMbProfile(false));
                  }}
                >
                  <img src={Icon.cart} className={styled.cartImg} alt="Cart" />
                  {cardQty ? (
                    <div className={styled.cartCountCon}>
                      <p className={styled.cartCount}>{cardQty || null}</p>
                    </div>
                  ) : null}
                </div>
                <div className={styled.burger} onClick={onChangeMenu}>
                  {menu ? (
                    <AiOutlineClose size={22} />
                  ) : (
                    <GiHamburgerMenu size={22} />
                  )}
                </div>
              </div>
            </div>
            {showMbProfile ? (
              <div
                // className={styled.profileListCon}
                className="absolute top-[65px] right-[16px] z-30 bg-white"
              >
                {token ? (
                  <div className={styled.pfActive}>
                    <Link
                      to="/profile?category=profile"
                      className={styled.profileList}
                      onClick={closePopup}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.person}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("profile", "Profile")}
                    </Link>
                    <Link
                      to="/profile?category=shipping-address"
                      className={styled.profileList}
                      onClick={closePopup}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.mapPin}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("shipping-address", "Shipping Address")}
                    </Link>
                    <Link
                      to="/profile?category=wishlist"
                      className={styled.profileList}
                      onClick={closePopup}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.favorite}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("wishlist", "Wishlist")}
                    </Link>
                    <Link
                      to="/profile?category=order-history"
                      className={styled.profileList}
                      onClick={closePopup}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.receipt}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("order_history", "Order History")}
                    </Link>
                    <Link
                      to="/change"
                      className={styled.profileList}
                      onClick={closePopup}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.key}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("change-password", "Change Password")}
                    </Link>
                    <span className={styled.profileList} onClick={handleLogout}>
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.logout}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("logout", "Log Out")}
                    </span>
                  </div>
                ) : (
                  <div className={styled.pfActive}>
                    <Link
                      to="/login"
                      className={styled.profileList}
                      onClick={closePopup}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.loginIcon}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("login", "Login")}
                    </Link>

                    <Link
                      to="/sign-up"
                      className={styled.profileList}
                      onClick={closePopup}
                    >
                      <div className={styled.iconCon}>
                        <img
                          src={Icon.registerIcon}
                          className={styled.profileImg}
                          alt="Profile"
                        />
                      </div>
                      {translate("register", "Register")}
                    </Link>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>

        {/* Search Field */}
        <div className="flex items-center justify-center w-full bg-white border-b border-b-black-1">
          <div className="container">
            <div className={styled.search}>
              <div className="relative flex w-full flex-wrap items-stretch rounded-lg border border-solid border-neutral-300">
                <input
                  type="search"
                  className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out dark:border-neutral-600 dark:text-[#252525] dark:placeholder:text-[#252525]"
                  placeholder={translate("search", "Search...")}
                  aria-label="Search"
                  aria-describedby="button-addon1"
                  value={searchProduct}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setSearchProduct(e.target.value)}
                />
                {/*Search button*/}
                <button
                  className="relative z-[2] flex items-center rounded-r bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white  focus:bg-primary-700 focus:outline-none focus:ring-0 active:bg-primary-800"
                  type="button"
                  id="button-addon1"
                  data-te-ripple-init=""
                  data-te-ripple-color="light"
                  onClick={handleSearch}
                >
                  <img src={Icon.search} className="h-5 w-5" alt="search" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Menu List */}
        {menu ? (
          <div className={styled["mbMenuCon"] + " " + styled.active}>
            {!productMb && !brandMb ? (
              <MainMenuMb />
            ) : productMb ? (
              <ProductMobile />
            ) : brandMb ? (
              <BrandMb />
            ) : null}
          </div>
        ) : (
          <div className={styled.mbMenuCon}></div>
        )}
      </div>
    </>
  );
};
export default Header;
