import React from "react";
import Icons from "../../assets/icons/index";
import Button from "../../components/common/button/Button";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useLanguage from "../../hooks/lang/useLanguage";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const lang = useLanguage();
  return (
    <div className=" bg-not_found w-full h-screen flex flex-col justify-center items-center">
      <img className="w-[500px] h-[400px]" src={Icons.NotFoundLogo} alt="" />
      <span className=" font-muliBold text-[24px] block mt-[45px]">
        {lang === "en" ? "Oops.. Page not Found" : "စာမျက်နာကို ရှာမတွေ့ပါ"}
      </span>
      <Button
        className={
          "flex items-center gap-5 lg:text-2xl text-sky_blue font-righteous mt-[40px]"
        }
        onClick={() => navigate("/")}
      >
        <FaAngleDoubleLeft />{" "}
        {lang === "en" ? "Go to Home Page" : "မူလသို့ သွားရန်"}
      </Button>
    </div>
  );
};

export default NotFoundPage;
