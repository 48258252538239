import LoginPage from "../screens/auth/LoginPage";
import RegisterSuccessPage from "../screens/auth/RegisterSuccessPage";
import SignUpPage from "../screens/auth/SignUpPage";
import VerifyPage from "../screens/auth/VerifyPage";
import HomePage from "../screens/home/HomePage";
import NotFoundPage from "../screens/notFound/NotFoundPage";
import SubCategory from "../screens/product/subCategory";
import ForgotPasswordPage from "../screens/auth/ForgotPasswordPage";
import ChangePasswordPage from "../screens/auth/ChangePasswordPage";
import ProductListing from "../screens/product/productListing";
import ProductDetails from "../screens/product/productDetails";
import BrandCategory from "../screens/brand/brand_category";
import ProtectRoute from "../components/routes/ProtectRoute";
import AddToCardPage from "../screens/addToCard/AddToCardPage";
import CheckoutPage from "../screens/checkout/CheckoutPage";
import ContactUs from "../screens/contact_us/ContactUs";
import TermConditionPage from "../screens/term_condition/TermConditionPage";
import PrivacyPolicyPage from "../screens/privacy_policy/PrivacyPolicyPage";
import FaqPage from "../screens/faq/FaqPage";
import BranchesPage from "../screens/branches/BranchesPage";
import AboutUsPage from "../screens/about_us/AboutUsPage";
import PromotionPage from "../screens/product/PromotionPage";
import ProfilePage from "../screens/profile/ProfilePage";
import ResetPasswordPage from "../screens/auth/ResetPasswordPage";
import ChangePasswordSuccess from "../screens/auth/ChangePasswordSuccess";
import SearchPage from "../screens/search/SearchPage";

export const RouteConfig = [
  {
    path: "/",
    element: (
      <ProtectRoute>
        <HomePage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  // {
  //   path: "/product",
  //   element: (
  //     <ProtectRoute>
  //       <SubCategory />
  //     </ProtectRoute>
  //   ),
  //   special_access: "user",
  // },
  {
    path: "/filter?",
    element: (
      <ProtectRoute>
        <ProductListing />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/product/detail/:productId",
    element: (
      <ProtectRoute>
        <ProductDetails />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/search",
    element: (
      <ProtectRoute>
        <SearchPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/filter?brand_id=:brandId",
    element: (
      <ProtectRoute>
        <BrandCategory />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/promotion",
    element: (
      <ProtectRoute>
        <PromotionPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/add-to-card",
    element: (
      <ProtectRoute>
        <AddToCardPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/checkout",
    element: (
      <ProtectRoute>
        <CheckoutPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/contact-us",
    element: (
      <ProtectRoute>
        <ContactUs />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/about-us",
    element: (
      <ProtectRoute>
        <AboutUsPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/terms-conditions",
    element: (
      <ProtectRoute>
        <TermConditionPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/privacy-policy",
    element: (
      <ProtectRoute>
        <PrivacyPolicyPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/faq",
    element: (
      <ProtectRoute>
        <FaqPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/branches",
    element: (
      <ProtectRoute>
        <BranchesPage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/profile",
    element: (
      <ProtectRoute isAccessToken={true}>
        <ProfilePage />
      </ProtectRoute>
    ),
    special_access: "user",
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/sign-up",
    element: <SignUpPage />,
  },
  {
    path: "/verify",
    element: <VerifyPage />,
  },
  {
    path: "/forgot",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/change",
    element: <ChangePasswordPage />,
  },
  {
    path: "/reset",
    element: <ResetPasswordPage />,
  },
  {
    path: "/register-success",
    element: <RegisterSuccessPage />,
  },
  {
    path: "/change-success",
    element: <ChangePasswordSuccess />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];
