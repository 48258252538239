import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const InputSelect = ({ value, onChange, label, className, data,name }) => {
  return (
    <FormControl variant="standard" className={className}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        id={label}
        value={value}
        onChange={onChange}
        label={label}
        name={name}
      >
        {data?.map((item,id) => (
            <MenuItem value={item.id} key={id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InputSelect;
