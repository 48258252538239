import React from "react";
import AuthWrapper from "../../components/common/auth/AuthWrapper";
import styled from "./password.module.css";
import Icons from "../../assets/icons/index";
import TextFieldInput from "../../components/common/input/TextField";
import Button from "../../components/common/button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkUser } from "../../components/common/api/auth/checkUser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import useDictionary from "../../hooks/lang/useDictionary";

const forgotSchema = Yup.object().shape({
  phone: Yup.string().required("Phone number is required"),
});

const ForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [number, setNumber] = useState();
  const [isTrue, setIsTrue] = useState(null);
  const navigate = useNavigate();
  const { translate } = useDictionary();
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: forgotSchema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setNumber(values.phone);
      setTimeout(() => {
        checkUser(values.phone, setIsTrue);
        setIsLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
      }, 2000);
    },
  });

  useEffect(() => {
    if (isTrue == "Yes") {
      navigate("/verify?type=forgot", {
        state: { mobile: number },
      });
    }
  }, [isTrue]);
  return (
    <AuthWrapper>
      <div className={styled.box}>
        <div className=" absolute left-0 right-0 top-0">
          <div className={`${styled.img_box}`}>
            <img src={Icons.AuthLogo} alt="" className="w-[70px] 2xl:w-auto" />
          </div>
        </div>
        <h3 className={styled.heading}>
          {translate("forgot-password", "Forgot password")}
        </h3>
        <p className=" text-center">
          {translate(
            "enter-phone-associated-account",
            "Enter phone number associated with your account"
          )}
        </p>
        <form action="" onSubmit={formik.handleSubmit}>
          <div className=" mt-[20px]">
            <TextFieldInput
              name={"phone"}
              type="tel"
              label={translate("phone", "Phone") + "*"}
              className={"w-full"}
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <span className=" text-sm text-red-2">
                {translate("mobile-require-error", formik.errors.phone)}
              </span>
            ) : isTrue === "No" ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "not-register-phone",
                  "The phone number is not registered, try another one."
                )}
              </span>
            ) : null}
          </div>
          <div className=" flex items-center justify-center">
            <Button
              type={"submit"}
              className=" bg-red-3 text-white h-[47px] w-[180px] 2xl:w-[230px] p-[8px] 2xl:p-[12px] rounded-[8px] lg:text-md 2xl:text-lg flex items-center justify-center mt-[20px] lg:mt-[30px]"
            >
              {formik.isSubmitting || isLoading ? (
                <BeatLoader color="#ffffff" size={13} />
              ) : (
                translate("submit", "Submit")
              )}
            </Button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPasswordPage;
