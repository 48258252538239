import React from "react";
import Button from "../common/button/Button";
import { useNavigate } from "react-router-dom";
import Icons from "../../assets/icons/index";

const AlertPopup = ({ text, route, btnLabel, type, onClick }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    type === "message" ? onClick() : navigate(route);
  };
  return (
    <div className=" fixed w-full h-screen bg-[#333333e5] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className="w-[90%] md:w-[450px] min-h-[150px] bg-white border-t-[10px] border-red-1 rounded-[5px] p-[20px] md:p-[40px] relative text-center">
        <div className=" flex justify-center">
          <img
            src={text ? Icons.warnningIcon : Icons.toLoginIcon}
            alt=""
            className=" w-[50px]"
          />
        </div>
        <p className=" my-[20px]">
          {text || "You need to login to add wishlist !"}{" "}
        </p>
        <Button
          onClick={handleNavigate}
          className={
            " h-[47px] min-w-[100px] px-[10px] rounded-[8px] bg-red-3 hover:bg-red-4 text-white"
          }
        >
          {btnLabel}
        </Button>
      </div>
    </div>
  );
};

export default AlertPopup;
