// change array to string with comma (,)
export const arrayToStringWithComma = (array) => {
  let arr = [...array];
  arr = arr?.join(",");
  return String(arr);
};

// check some array index is empty or null or undefined
export const isArrayIndexEmpty = (array) => {
  let arr = [...array];
  let isEmpty;
  if (arr.length > 0) {
    isEmpty = arr?.some((id) => id == null);
  } else {
    isEmpty = true;
  }
  return isEmpty;
};

// get the first index of variants that value equal with null or undefined
export const neededVariantToProcess = (array) => {
  let arr = [...array];
  let firstIndex;
  if (arr.length > 0) {
    firstIndex = arr?.findIndex((vari) => vari == null);
  } else {
    firstIndex = false;
  }
  return firstIndex === -1 ? false : firstIndex;
};

// array to change require message
export const toVariantRequireMessage = (array) => {
  let arr = [...array];
  let names = arr.map((item) => item.name);
  let firstNames = names.length > 1 ? names.slice(0, names.length - 1) : names;
  return arr.length > 1
    ? `${arrayToStringWithComma(firstNames)} and ${names[names.length - 1]}`
    : arrayToStringWithComma(names);
};
