import { memo } from "react";
import ReactPlayer from "react-player";

const ProductVideo = ({ videoUrl }) => {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full lg:w-[80%] h-[250px] lg:h-[530px]">
        <ReactPlayer url={videoUrl} controls width={"100%"} height={"100%"} />
      </div>
    </div>
  );
};

export default memo(ProductVideo);
