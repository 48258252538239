import React from "react";

const TextArea = ({ label, onChange, value, className, rows,name }) => {
  return (
    <>
      <label
        htmlFor={label}
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        <h5>{label}</h5>
      </label>
      <textarea
        id={label}
        name={name}
        rows={rows}
        className={className}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default TextArea;
