import React, { useEffect, useState } from "react";
import styled from "./profile.module.css";
import Button from "../../components/common/button/Button";
import { FormControl, MenuItem, Pagination, Select } from "@mui/material";
import { MdRestore } from "react-icons/md";
import OrderItem from "./OrderItem";
import Icons from "../../assets/icons/index";
import FileUploader from "../checkout/FileUploader";
import callApi from "../../services/api/apiClient";
// import { Pagination } from "@mantine/core";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
import PayNowPopup from "../../components/common/popup/PayNowPopup";
import { useNavigate } from "react-router-dom";
import useLanguage from "../../hooks/lang/useLanguage";
import { formatNumber } from "../../utils/generals";
// import Pagination from "../../components/common/pagination/pagination";

const history = [
  { id: 1, status: "paid" },
  { id: 2, status: "cancel" },
  { id: 3, status: "paid" },
  { id: 4, status: "unpaid" },
  { id: 5, status: "paid" },
];

const OrderHistory = () => {
  const arr = Array(5).fill("");
  const navigate = useNavigate();
  const [detailId, setDetailId] = useState("");
  const [selected, setSelected] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(8);
  const { translate } = useDictionary();
  const lang = useLanguage();
  const [showPopup, setShowPopup] = useState(false);
  const [status, setStatus] = useState("");

  const orderLists = useSelector((state) => state?.profile?.orderLists?.data);

  const orderDetail = useSelector((state) => state?.profile?.orderDetail?.data);

  const userInfo = useSelector((state) => state?.auth?.getUser?.data);

  const productOnly = orderDetail?.order_lines?.filter(
    (cart) => cart?.product?.line_ref == false
  );

  const orderStatus = useSelector((state) => state?.profile?.orderStatus?.data);

  const handleChange = (value) => {
    setSelected(value);
    // console.log(value);
  };

  const handleClick = (item) => {
    setDetailId(item?.id);
    setStatus(item);
    navigate("/profile?category=order-history-detail", {
      state: { item: item },
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    setPage(1);
  }, [selected]);

  useEffect(() => {
    setTimeout(() => {
      callApi("profile/orderLists")
        .withParam({
          page_no: page,
          per_page: 8,
          status_id: selected == 0 ? null : selected,
        })
        // .loadingGroup("order")
        .executeDispatch();
    }, 100);
  }, [page, selected]);

  // retive payment types data
  useEffect(() => {
    callApi("checkout/getPaymentTypes")
      .loadingGroup("checkout")
      .executeDispatch();
    callApi("profile/orderStatus").loadingGroup("order").executeDispatch();
  }, []);

  useEffect(() => {
    if (detailId) {
      callApi("profile/orderDetail")
        .withSegment(detailId)
        .loadingGroup("order")
        .executeDispatch();
    }
  }, [detailId]);

  // useEffect(() => {
  //   if (orderLists?.total_count) {
  //     setTotalPage(Math.ceil(orderLists?.total_count / 8));
  //   }
  // }, [orderLists]);

  window.addEventListener("popstate", (event) => {
    return;
  });

  return (
    <LoadingComponent loadingGroup={"order"}>
      {showPopup && (
        <PayNowPopup onClick={() => setShowPopup(false)} orderId={detailId} />
      )}
      <>
        <div className={styled.right_box}>
          {!detailId && (
            <div className=" bg-white p-[15px] md:p-[30px] rounded-[8px]">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <h5>{translate("order_history", "Order History")}</h5>
                <div className=" flex items-center gap-3">
                  <span className="body">
                    {translate("filter-by-status", "Filter By Status")}
                  </span>
                  <div className="">
                    <FormControl
                      sx={{
                        minWidth:
                          window.innerWidth > 600
                            ? 200
                            : window.innerWidth < 461
                              ? 130
                              : 160,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "red",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          height: window.innerWidth > 460 ? 50 : 45,
                          top: window.innerWidth > 460 ? -4 : -1,
                        },
                        "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                        {
                          fontFamily: "MuliRegular",
                        },
                        "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                        {
                          fontFamily: "MuliRegular",
                        },
                      }}
                    >
                      <Select
                        value={selected}
                        onChange={(e) => handleChange(e.target.value)}
                      >
                        <MenuItem value={0} className="body" key={0}>
                          All
                        </MenuItem>
                        {orderStatus?.map((item) => (
                          <MenuItem
                            value={item.id}
                            className="body"
                            key={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {/* <MdRestore size={25} className=" cursor-pointer" /> */}
                </div>
              </div>
              <div className=" mt-[30px] pb-[20px] overflow-scroll md:overflow-hidden scrollable scrollbarbackgroundred">
                {orderLists?.order?.length > 0 ? (
                  <table className="w-[700px] md:w-full">
                    <thead>
                      <tr className=" border-b-2">
                        <th className="pb-[15px]">
                          {translate("order_no", "Order No.")}
                        </th>
                        <th className="pb-[15px]">
                          {translate("order_date", "Order Date")}
                        </th>
                        <th className="pb-[15px]">
                          {translate("total_amount", "Total Amount")}
                        </th>
                        <th className="pb-[15px]">
                          {translate("shipping", "Shipping")}
                        </th>
                        <th className="pb-[15px]">
                          {translate("status", "Status")}
                        </th>
                        <th className="pb-[15px]"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderLists?.order?.map((item, id) => (
                        <tr key={id}>
                          <td className=" text-center pt-[15px]">
                            {item?.name}
                          </td>
                          <td className=" text-center pt-[15px]">
                            {formatDate(item?.date)}
                          </td>
                          <td className=" text-center pt-[15px]">
                            {formatNumber(item?.amount, lang)}{" "}
                            {translate("ks", "Ks")}
                          </td>
                          <td className=" text-center pt-[15px]">
                            {item?.pickup_status ? "Pickup" : "Delivery"}
                          </td>
                          {/* {item.status === "paid" ? (
                            <td className=" text-center pt-[15px] text-[#2F8F00]">
                              Delivered, Paid
                            </td>
                          ) : item.status === "unpaid" ? (
                            <td className=" text-center pt-[15px] text-orange-400">
                              Pending, Unpaid
                            </td>
                          ) : (
                            <td className=" text-center pt-[15px] text-black-3">
                              Cancel, Refund
                            </td>
                          )} */}
                          {item?.status == "Cancelled" ? (
                            <td
                              className={`text-center pt-[15px] ${"text-black-3"}`}
                            >
                              {item?.status} | {item?.payment_status}
                            </td>
                          ) : (
                            <td
                              className={`text-center pt-[15px] ${item?.payment_status == "Unpaid"
                                  ? "text-orange-400"
                                  : item?.payment_status == "Paid"
                                    ? "text-[#2F8F00]"
                                    : "text-black-3"
                                }`}
                            >
                              {item?.status} | {item?.payment_status}
                            </td>
                          )}
                          <td className=" text-center pt-[15px]">
                            <Button
                              className={
                                "border-[1px] border-black-3 px-5 rounded-[5px] hover:bg-black-1 transition-all duration-150 active:scale-95"
                              }
                              onClick={() => handleClick(item)}
                            >
                              {translate("detail", "Detailed")}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className=" min-h-[30vh] bg-white p-[15px] md:p-[30px] rounded-[8px] w-full flex justify-center items-center">
                    <div className="">
                      {selected ? (
                        <p className=" text-center">
                          {translate(
                            "no-result-match",
                            "No results match the filter criteria. Remove filter or clear all filters to show results"
                          )}
                        </p>
                      ) : (
                        <>
                          <p className=" text-center">
                            {translate("empty-order", "Empty Order")} !
                          </p>
                          <button
                            className=" underline text-blue-600 mt-3"
                            onClick={() => navigate("/")}
                          >
                            {translate(
                              "continue-shopping",
                              "Continue Shopping"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className=" pt-[30px]"></div>
              {orderLists?.order?.length > 0 &&
                Math.ceil(orderLists?.total_count / 8) > 1 && (
                  <div className="lg:ml-[20px]">
                    {/* <Pagination
                      value={page}
                      onChange={setPage}
                      // total={totalPage}
                      total={Math.ceil(orderLists?.total_count / 8)}
                    /> */}
                    <Pagination
                      page={page}
                      onChange={(event, value) => {
                        window.scrollTo({ top: 0, left: 0 })
                        setTimeout(() => {
                          setPage(value)
                        }, 700);
                      }}
                      count={Math.ceil(orderLists?.total_count / 8)}
                      variant="outlined"
                      shape="rounded"
                    />
                  </div>
                )}
            </div>
          )}
          {detailId && (
            <>
              <div className="bg-white p-[15px] md:p-[30px] rounded-[8px]">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                  <h5>{translate("order-detail", "Order Detail")}</h5>
                  <div className=" flex items-center justify-between sm:justify-normal gap-3">
                    {/* {history[detailId - 1]?.status === "paid" ? (
                        <span className="body text-[#2F8F00]">
                          Delivered, Paid
                        </span>
                      ) : history[detailId - 1]?.status === "unpaid" ? (
                        <span className="body text-orange-400">
                          Pending, Unpaid
                        </span>
                      ) : (
                        <span className="body text-black-3">
                          Cancel, Refund
                        </span>
                      )} */}
                    {orderDetail?.status == "Cancelled" ? (
                      <span className={`body ${"text-black-3"}`}>
                        {orderDetail?.status} | {status?.payment_status}
                      </span>
                    ) : (
                      <span
                        className={`body ${status?.payment_status == "Unpaid"
                            ? "text-orange-400"
                            : status?.payment_status == "Paid"
                              ? "text-[#2F8F00]"
                              : "text-black-3"
                          }`}
                      >
                        {orderDetail?.status} | {status?.payment_status}
                      </span>
                    )}

                    {/* <MdRestore size={25} className=" cursor-pointer" /> */}
                  </div>
                </div>
                <div className=" flex justify-between items-start">
                  <div className=" mt-[30px] flex flex-col gap-[5px] md:gap-[15px]">
                    <span className="title2 block">
                      {translate("order-no", "Order No.")} -{" "}
                      {/* {orderDetail?.order_id} */}
                    </span>
                    <span className="title2 block">
                      {translate("order_date", "Order Date")} -{" "}
                      {formatDate(orderDetail?.date)}
                    </span>
                    <span className="title2 block">
                      {/*  No.97, Xxxxxxxxx Street, Lanmadaw, Yangon */}
                      {translate("delivery-to", "Deliver To")} :{" "}
                      {`${orderDetail?.shipping_info?.street}, ${orderDetail?.shipping_info?.township?.name},
                      ${orderDetail?.shipping_info?.state?.name}`}
                    </span>
                  </div>
                  {status?.status == "Cancelled" ||
                    (status?.payment_status !== "Paid" && (
                      <Button
                        className={
                          " w-[130px] sm:w-[200px] h-[35px] text-sm sm:text-base sm:h-[47px] bg-red-3 hover:bg-red-4 rounded-[8px] text-white mt-8 sm:mt-5"
                        }
                        onClick={() => setShowPopup(true)}
                      >
                        {translate("make-payment", "Make Payment")}
                      </Button>
                    ))}
                </div>
                <div className="mt-[20px]">
                  <table className=" w-full">
                    <thead className=" hidden sm:contents">
                      <tr className=" border-b-2">
                        <th className="w-[100px] text-center pb-5">
                          {translate("items", "Item")}
                        </th>
                        <th className="text-start pb-5"></th>
                        <th className="text-start pb-5 md:w-[110px]">
                          {translate("price", "Price")}
                        </th>
                        <th className="text-center pb-5 md:w-[90px] 2xl:w-[120px]">
                          {translate("quantity", "Quantity")}
                        </th>
                        <th className="text-end pb-5 md:w-[130px]">
                          {translate("subtotal", "Subtotal")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productOnly?.map((card, i) => (
                        <OrderItem key={i} item={card} />
                      ))}
                      <tr className="sm:border-b-2">
                        <td className="pb-5"></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="" colSpan={2}></td>
                        <td className=" font-semibold pt-3" colSpan={2}>
                          {translate("order-total", "Order Total")}
                        </td>
                        <td className="text-end font-semibold pt-3">
                          {orderDetail?.subtotal} {translate("ks", "Ks")}
                        </td>
                      </tr>
                      {orderDetail?.tax ? (
                        <tr>
                          <td className="" colSpan={2}></td>
                          <td className=" font-semibold pt-3" colSpan={2}>
                            {translate("tax", "Tax")}
                          </td>
                          <td className="text-end font-semibold pt-3">
                            {formatNumber(orderDetail?.tax, lang)}{" "}
                            {translate("ks", "Ks")}
                          </td>
                          <td className=" border-b-2"></td>
                        </tr>
                      ) : null}
                      {userInfo?.user_type !== "b2b" &&
                        orderDetail?.member_discount_amount ? (
                        <tr>
                          <td className="" colSpan={2}></td>
                          <td className=" border-b-2 py-2" colSpan={2}>
                            {translate("member-discount", "Member Discount")} (
                            {formatNumber(
                              orderDetail?.member_discount_percent,
                              lang
                            )}{" "}
                            %)
                          </td>
                          <td className="text-end border-b-2 py-2">
                            -{" "}
                            {formatNumber(
                              orderDetail?.member_discount_amount,
                              lang
                            )}{" "}
                            {translate("ks", "Ks")}
                          </td>
                          <td className=" border-b-2"></td>
                        </tr>
                      ) : (
                        <tr
                          className={`${orderDetail?.member_discount_amount ? "" : "hidden"
                            }`}
                        >
                          <td className="" colSpan={2}></td>
                          <td className=" border-b-2 py-2" colSpan={2}></td>
                          <td className="text-end border-b-2 py-2"></td>
                          <td className=" border-b-2"></td>
                        </tr>
                      )}
                      {orderDetail?.member_discount_amount ? (
                        <tr>
                          <td className="" colSpan={2}></td>
                          <td className=" font-bold pt-2" colSpan={2}>
                            {translate("total", "Total")}
                          </td>
                          <td className="text-end font-bold pt-2">
                            {formatNumber(orderDetail?.total, lang)}{" "}
                            {translate("ks", "Ks")}
                          </td>
                        </tr>
                      ) : null}
                    </tfoot>
                  </table>
                </div>
                {history[detailId - 1]?.status === "unpaid" && (
                  <div className="flex flex-col md:flex-row gap-3 md:gap-[24px] md:items-end mt-[20px]">
                    <FileUploader className={"md:w-[480px] h-[48px]"} />
                    <Button
                      className={
                        " w-[200px] h-[48px] bg-red-3 text-white rounded-[8px] transition-all duration-150 hover:bg-red-4 active:scale-95"
                      }
                    >
                      {translate("submit", "Submit")}
                    </Button>
                  </div>
                )}
              </div>
              <Button
                className={
                  "flex items-center gap-3 mt-[30px] transition-all duration-150 active:gap-2"
                }
                onClick={() => setDetailId("")}
              >
                <img
                  src={Icons.dropArrowIcon}
                  alt=""
                  className=" w-[23px] sm:w-auto"
                />{" "}
                <span className=" sm:text-[20px]">
                  {translate("back-to-order-history", "Back to Order History")}
                </span>
              </Button>
            </>
          )}
        </div>
      </>
    </LoadingComponent>
  );
};

export default OrderHistory;
