import React, { useEffect, useState } from "react";
import styled from "./product_card.module.css";
import { Link } from "react-router-dom";
import useLanguage from "../../../hooks/lang/useLanguage";
import { formatNumber } from "../../../utils/generals";
import useDictionary from "../../../hooks/lang/useDictionary";
import defaultImage from "../../../assets/images/default_img.jpg";
import { useSelector } from "react-redux";

const ProductCard = ({ item }) => {
  const [promoData, setPromoData] = useState("");
  const lang = useLanguage();
  const { translate } = useDictionary();

  const userInfo = useSelector((state) => state?.auth?.getUser?.data);

  useEffect(() => {
    if (item?.promotion?.length > 0) {
      setPromoData(item?.promotion[0]);
    }
  }, [item]);

  return (
    <Link to={`/product/detail/${item.id}`}>
      <div className={styled.main}>
        <div className={`${styled.img_box}`}>
          <img src={item?.image || defaultImage} alt="" className=" w-full" />
          {promoData?.promotion_type === "discount" && (
            <div
              className={`bg-[url('./assets/icons/promo_icon.svg')] ${styled.promo_icon}`}
            >
              <p className=" text-white text-sm md:text-base">
                {formatNumber(promoData?.discount_percent, lang)} %{" "}
              </p>
              {/* <p className=" text-white uppercase text-xs md:text-sm">
                {translate("off", "off")}
              </p> */}
            </div>
          )}
          {promoData?.promotion_type === "product" && (
            <div
              className={`bg-[url('./assets/icons/promo_icon.svg')] ${styled.promo_icon}`}
            >
              <span
                className={` text-white text-center leading-4 ${
                  lang === "en" ? "text-[13px]" : "text-[12px]"
                }`}
              >
                {lang === "en"
                  ? `Buy ${promoData?.buy_product[0]?.min_qty}`
                  : `${formatNumber(
                      promoData?.buy_product[0]?.min_qty,
                      lang
                    )}ခုဝယ်`}
              </span>
              <span
                className={`text-white text-center leading-4 ${
                  lang === "en" ? "text-[13px]" : "text-[12px]"
                }`}
              >
                {lang === "en"
                  ? `Get ${promoData?.reward_product[0].reward_qty}`
                  : `${formatNumber(
                      promoData?.reward_product[0].reward_qty,
                      lang
                    )}ခု လက်ဆောင်`}
              </span>
              {lang === "en" ? (
                <p
                  className={`text-white uppercase ${
                    lang === "en" ? "text-[13px]" : "text-[12px]"
                  } leading-4`}
                >
                  {translate("free", "FREE")}
                </p>
              ) : null}
            </div>
          )}

          {userInfo?.user_type !== "b2b" && (
            <>
              {item?.in_stock ? null : (
                <div className=" absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[#898888b3]">
                  <span className=" bg-red-2 text-white py-1 px-3 rounded-full">
                    {translate("out-of-stock", "out of stock")}
                  </span>
                </div>
              )}
            </>
          )}
        </div>

        <div className=" py-[12px] px-[13px]">
          <div className="text-base sm:text-base mb-[5px] font-normal text-[#252525]">
            {item.name}
          </div>
          <div
            className={ "flex lg:flex-row lg:gap-3 flex-col"
            }
          >
            {item?.promotion && item?.promotion?.length != 0 ? (
              <span className="font-medium text-base md:text-lg text-black-4 font-muliBold">
                {formatNumber(
                  item.price -
                    (item.price * item.promotion?.[0]?.discount_percent) / 100,
                  lang
                )}{" "}
                {translate("ks", "Ks")}
              </span>
            ) : (
              <span className=" font-medium text-base md:text-lg text-black-4 font-muliBold">
                {formatNumber(item.price, lang)} {lang === "en" ? "Ks" : "ကျပ်"}
              </span>
            )}
            {item?.promotion &&
              item?.promotion?.length != 0 &&
              item.promotion?.[0].promotion_type == "discount" && (
                <span className="text-xs sm:text-sm line-through">
                  {formatNumber(item.price, lang)}
                  {translate("ks", "Ks")}
                </span>
              )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
