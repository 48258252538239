import React, { useEffect, useState } from "react";
import Button from "../../components/common/button/Button";
import { BsPlus } from "react-icons/bs";
import styled from "./profile.module.css";
import ShippingAddressPopup from "../checkout/ShippingAddressPopup";
import callApi from "../../services/api/apiClient";
import useLanguage from "../../hooks/lang/useLanguage";
import useAuth from "../../hooks/lang/useAuth";
import { useSelector } from "react-redux";
import ConfirmPopup from "../../components/common/popup/ConfirmPopup";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";

const ShippingAddress = () => {
  const [check, setCheck] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const language = useLanguage();
  const token = useAuth();
  const { translate } = useDictionary();
  const shippingAddress = useSelector(
    (state) => state?.checkout?.getShippingAddress?.data
  );
  const editShippingAddress = useSelector(
    (state) => state?.checkout?.editShippingAddress?.data
  );
  const deleteShippingAddress = useSelector(
    (state) => state?.checkout?.deleteShippingAddress?.data
  );
  const addShippingAddress = useSelector(
    (state) => state?.checkout?.addShippingAddress?.data
  );

  const setDefaultAddress = (id) => {
    callApi("checkout/setDefaultAddress")
      .withSegment(id)
      .loadingGroup("checkout")
      .executeDispatch();
  };

  useEffect(() => {
    callApi("checkout/getShippingAddress")
      .withParam({ lang: language })
      .loadingGroup("address")
      .executeDispatch();
  }, [
    language,
    editShippingAddress,
    deleteShippingAddress,
    addShippingAddress,
  ]);

  const handleEdit = (id) => {
    setAddressId(id);
    setIsEdit(true);
  };

  const handleDelete = (id) => {
    callApi("checkout/deleteShippingAddress")
      .withSegment(id)
      .loadingGroup("removeCart")
      .executeDispatch();
  };

  const handleClick = (id) => {
    setAddressId(id);
    setIsShow(true);
  };

  useEffect(() => {
    setIsShow(false);
    setCheck(
      shippingAddress?.find(
        (address) => address?.default_shipping_address == true
      )?.id
    );
  }, [deleteShippingAddress, shippingAddress]);

  // console.log(check);

  useEffect(() => {
    if (check) {
      setDefaultAddress(check);
    }
  }, [check]);

  return (
    <LoadingComponent loadingGroup={"address"}>
      <div className={styled.right_box}>
        {isAdd && <ShippingAddressPopup onClick={() => setIsAdd(false)} />}
        {isEdit && (
          <ShippingAddressPopup
            onClick={() => setIsEdit(false)}
            addressId={addressId}
            type="edit"
          />
        )}
        {isShow && (
          <ConfirmPopup
            close={() => setIsShow(false)}
            onClick={() => handleDelete(addressId)}
            type={"address"}
          />
        )}
        <div className=" bg-white rounded-[8px] p-[15px] sm:p-[30px]">
          <h5 className=" mb-[30px]">
            {translate("shipping-address", "Shipping Address")}
          </h5>
          <div className="flex flex-col gap-[20px]">
            {shippingAddress?.map((address, id) => (
              <div className=" border-b relative" key={id}>
                <span className="body block">{address?.name || "Thandar"}</span>
                <span className="body block">
                  {address?.mobile || "09123456799"}
                </span>
                <span className="body block">
                  {/* No. 75, Yadanar Street, Kamayut Township, Yangon */}
                  {address?.address1}, {address?.township["name"]},{" "}
                  {address?.state["name"]}
                </span>
                <div className="flex items-center">
                  <input
                    id={`bordered-radio-${id}`}
                    type="radio"
                    defaultValue=""
                    name="bordered-radio"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-red-4"
                    checked={check === address?.id}
                    onChange={() => setCheck(address?.id)}
                  />
                  <label
                    htmlFor={`bordered-radio-${id}`}
                    className={`w-full py-2 ml-2 font-medium ${
                      check === address?.id ? "text-red-4" : null
                    }`}
                  >
                    {translate(
                      "use-my-default-address",
                      "Use as my default address "
                    )}
                  </label>
                </div>
                <div className=" absolute top-0 right-0 flex gap-3">
                  <button
                    className=" text-sky_blue cursor-pointer"
                    onClick={() => handleEdit(address?.id)}
                  >
                    {translate("edit", "Edit")}
                  </button>
                  <button
                    className={`text-black-3 cursor-pointer ${
                      check === address?.id && " opacity-50"
                    }`}
                    onClick={() => handleClick(address?.id)}
                    disabled={check === address?.id}
                  >
                    {translate("delete", "Delete")}
                  </button>
                </div>
              </div>
            ))}
          </div>

          <Button
            className={
              "flex text-sky_blue items-center justify-center gap-[10px] border border-dashed border-sky_blue hover:border-[#19c1b8] rounded-[5px] p-[16px] w-full sm:w-[300px] mt-[16px]"
            }
            onClick={() => setIsAdd(true)}
          >
            <BsPlus size={24} />{" "}
            <span className=" font-muli text-sky_blue">
              {translate("add-address", "Add Address")}
            </span>
          </Button>
        </div>
      </div>
    </LoadingComponent>
  );
};

export default ShippingAddress;
