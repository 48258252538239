import React, { memo } from "react";
import styled from "./banner.module.css";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images";

const BannerCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      className="relative h-[220px] md:h-[430px] lg:h-[380px] 2xl:h-[535px]"
      onClick={() =>
        navigate(
          `/promotion?promoId=${item?.ID}&promoType=${item?.promotion_type}`
        )
      }
    >
      <div className={styled.banner_tone_one_hover}>
        <div className={styled.banner_tone_one}>
          <p className="text-white md:text-[25px] lg:text-[20px] 2xl:text-[25px] font-righteous">
            {item.name}
          </p>
        </div>
      </div>
      <div className={styled.banner_tone_two}></div>
      <div
        className={styled.banner_img}
        style={{
          backgroundImage: item?.promotion_highlight
            ? `url("${item?.promotion_highlight}")`
            : `url(${Images.promotionDefaultImg})`,
        }}
      ></div>
    </div>
  );
};

export default memo(BannerCard);
