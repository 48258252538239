import React from "react";
import Icons from "../../../assets/icons/index";
import { useNavigate } from "react-router-dom";
import useDictionary from "../../../hooks/lang/useDictionary";

const Breadcrumb = ({ item, title }) => {
  console.log(item, title, "bread")
  const navigate = useNavigate();
  const { translate } = useDictionary();
  return (
    <div className=" bg-black-6 flex justify-center">
      <div className="py-[12px] md:py-[20px] container mx-auto">
        <h3 className=" font-righteous text-lx md:text-xl text-center">
          {translate(title, title)}
        </h3>
        <ul className=" flex flex-wrap mt-[12px] gap-[8px] justify-center">
          <li className="body cursor-pointer hover:text-red-4" onClick={() => navigate("/")}>
            {translate("home", "Home")}
          </li>
          {/* <li className="flex gap-[8px] items-center body cursor-pointer hover:text-red-4" */}


          <li className={`flex gap-[8px] items-center body ${title == "product" ? "cursor-pointer hover:text-red-4" : "cursor-default"}`}
            onClick={() => {
              if (title == "product") {
                navigate("/filter?category_id=0")
              }
            }}
          >
            <img src={Icons.sparkieIcon} alt="" />{" "}
            <span className="body">{translate(title, title)}</span>
          </li>
          {typeof item == "object" && item?.map((i, index) =>
            i.name != title && i.name ? (
              <li key={index} className="flex gap-[8px] items-center body cursor-pointer hover:text-red-4"
                onClick={() => {
                  title == "product"
                    ?
                    navigate("/filter?category_id=" + i.id)
                    :
                    title == "brand"
                    &&
                    navigate("/filter?brand_id=" + i.ID)
                }
                }>
                <img src={Icons.sparkieIcon} alt="" />{" "}
                <span className="body">{translate(i.name, i.name)}</span>
              </li>
            ) : null
          )}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
