import React from "react";
import AuthWrapper from "../../components/common/auth/AuthWrapper";
import styled from "./password.module.css";
import Icons from "../../assets/icons/index";
import TextFieldInput from "../../components/common/input/TextField";
import Button from "../../components/common/button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import callApi from "../../services/api/apiClient";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import useDictionary from "../../hooks/lang/useDictionary";

const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(3, "New password must be at least 3 characters long")
    .required("New password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const ResetPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useDictionary();
  const { otp, mobile } = location.state;
  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setTimeout(() => {
        callApi("auth/resetPassword")
          .withBody({
            otp: otp,
            mobile: mobile,
            password: values.new_password,
          })
          .loadingGroup(1)
          .executeDispatch();
        setIsLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
        navigate("/register-success?type=resetpassword");
      }, 2000);
    },
  });
  return (
    <AuthWrapper>
      <div className={styled.box}>
        <div className=" absolute left-0 right-0 top-0">
          <div className={`${styled.img_box}`}>
            <img src={Icons.AuthLogo} alt="" className="w-[70px] 2xl:w-auto" />
          </div>
        </div>
        <h3 className={styled.heading}>
          {translate("reset_pass", "Reset Password")}
        </h3>
        <form action="" onSubmit={formik.handleSubmit}>
          <div className=" mt-[10px]">
            <TextFieldInput
              name={"new_password"}
              type={"password"}
              label={translate("new-password", "New password ")}
              className={"w-full"}
              value={formik.values.new_password}
              onChange={formik.handleChange}
            />
            {formik?.touched.new_password && formik?.errors.new_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "new-password-is-required",
                  formik.errors.new_password
                )}
              </span>
            ) : null}
          </div>
          <div className=" mt-[10px]">
            <TextFieldInput
              name={"confirm_password"}
              type={"password"}
              label={translate("confirm-password", "Confirm password ")}
              className={"w-full"}
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
            />
            {/* {formik?.touched.confirm_password &&
            formik?.errors.confirm_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "confirm_password_required",
                  formik.errors.confirm_password
                )}
              </span>
            ) : null} */}
            {formik?.touched.confirm_password &&
            !formik?.values?.confirm_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "confirm_password_required",
                  formik.errors.confirm_password
                )}
              </span>
            ) : formik?.touched?.confirm_password &&
              formik?.values?.new_password !==
                formik?.values?.confirm_password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "newpassword_and_confirmpassword_not_equal",
                  "New password and confirm password must be same."
                )}
              </span>
            ) : null}
          </div>
          <div className=" flex justify-center items-center">
            <Button
              type={"submit"}
              className=" bg-red-3 text-white h-[47px] w-[180px] 2xl:w-[230px] p-[8px] 2xl:p-[12px] rounded-[8px] lg:text-md 2xl:text-lg flex justify-center items-center mt-[20px] lg:mt-[30px]"
            >
              {formik.isSubmitting || isLoading ? (
                <BeatLoader color="#ffffff" size={13} />
              ) : (
                translate("save", "Save")
              )}
            </Button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ResetPasswordPage;
