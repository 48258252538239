import React, { useEffect, useState } from "react";
import AuthWrapper from "../../components/common/auth/AuthWrapper";
import styled from "./login.module.css";
import Icons from "../../assets/icons/index";
import TextFieldInput from "../../components/common/input/TextField";
import Button from "../../components/common/button/Button";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";

import callApi from "../../services/api/apiClient";
import { BeatLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/lang/useAuth";
import { slice } from "../../redux/reducers/reducer";
import useDictionary from "../../hooks/lang/useDictionary";
import LoadingComponent from "../../components/loading/LoadingComponent";

const loginSchema = Yup.object().shape({
  phone: Yup.string().required("Phone number is required"),
  password: Yup.string()
    .min(3, "Password must be at least 3 characters long")
    .required("Password is required"),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { translate } = useDictionary();
  const [isLoading, setIsLoading] = useState(false);
  const token = useAuth();
  const dispatch = useDispatch();
  const loginInfo = useSelector((state) => state?.auth?.login?.data);
  const [loginStatus, setloginStatus] = useState(null);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values, actions) => {
      setIsLoading(true);
      setTimeout(() => {
        callApi("auth/login")
          .withHeaders({
            login: encodeURIComponent(values.phone),
            password: encodeURIComponent(values.password),
          })
          .loadingGroup(1)
          .execute()
          .then((res) => {
            if (res?.error) {
              setloginStatus(res?.error);
            }
            else if (res?.status == "fail") {
              if (res?.message) {
                setloginStatus(res?.message);
              }
            }
            handleClear();
            clearCarts();
          });

        setIsLoading(false);
        actions.setSubmitting(false);
        actions.resetForm();
        // navigate("/");
      }, 1000);
    },
  });

  const handleClear = () => {
    dispatch(slice.persistSlice.actions.clearSliceElement("addToCart"));
  };

  const clearCarts = () => {
    dispatch(slice.cart.actions.clearSliceElement("getCart"));
  };

  useEffect(() => {
    return () => {
      setloginStatus(null);
    };
  }, []);

  return (

    <LoadingComponent loadingGroup={"dictionary"}>
    <AuthWrapper>
      <div className={styled.box}>
        <div className=" absolute left-0 right-0 top-0">
          <div className={`${styled.img_box}`}>
            <img src={Icons.AuthLogo} alt="" className="w-[70px] 2xl:w-auto" />
          </div>
        </div>
        <h3 className={styled.heading}>{translate("login", "Log in")}</h3>
        {loginStatus && (
          <p className=" text-red-2 text-center mb-2">{loginStatus} !</p>
        )}
        {loginInfo?.message && (
          <p className=" text-red-2 text-center mb-2">{loginInfo?.message} !</p>
        )}
        <form action="" onSubmit={formik.handleSubmit}>
          <div>
            <TextFieldInput
              name={"phone"}
              type="tel"
              label={translate("phone", "Phone") + "*"}
              className={"w-full"}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
            {formik?.touched.phone && formik?.errors.phone ? (
              <span className=" text-sm text-red-2">
                {" "}
                {translate("mobile-require-error", formik.errors.phone)}
              </span>
            ) : null}
          </div>
          <div className="mt-[15px]">
            <TextFieldInput
              name={"password"}
              type="password"
              label={translate("password", "Password") + "*"}
              className={"w-full"}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik?.touched.password && formik?.errors.password ? (
              <span className=" text-sm text-red-2">
                {translate(
                  "password_field_is_required",
                  formik.errors.password
                )}
              </span>
            ) : null}
          </div>
          <p
            className=" text-sky_blue text-center mt-[16px] body cursor-pointer"
            onClick={() => navigate("/forgot")}
          >
            {translate("forgot-password", "Forgot password")} ?
          </p>
          <div className="flex justify-center">
            <Button
              type={"submit"}
              className=" bg-red-3 text-white text-lg h-[47px] w-[180px] 2xl:w-[240px] p-[8px] 2xl:p-[12px] rounded-[8px] lg:text-md 2xl:text-lg flex items-center justify-center mt-[20px] lg:mt-[30px]"
              disabled={formik.isSubmitting || isLoading}
            >
              {formik.isSubmitting || isLoading ? (
                <BeatLoader color="#ffffff" size={13} />
              ) : (
                translate("login", "Login")
              )}
            </Button>
            {/* <button onClick={handleClear}>clear</button> */}
          </div>
        </form>
        <p className="text-center mt-[20px] 2xl:mt-[30px] body">
          {translate("don't-have-an-account", "Don't have an account")} ?{" "}
          <span
            className=" text-sky_blue body cursor-pointer"
            onClick={() => navigate("/sign-up")}
          >
            {translate("register", "Register")}
          </span>
        </p>
      </div>
    </AuthWrapper>

    </LoadingComponent>
  );
};

export default LoginPage;
