import React, { useEffect, useState } from "react";
import AuthWrapper from "../../components/common/auth/AuthWrapper";
import styled from "./verify.module.css";
import Icons from "../../assets/icons/index";
import { usePinInput } from "react-pin-input-hook";
import Button from "../../components/common/button/Button";
import { useLocation, useNavigate } from "react-router";
import callApi from "../../services/api/apiClient";
import { BeatLoader } from "react-spinners";
import { checkUser } from "../../components/common/api/auth/checkUser";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";

const VerifyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useDictionary();
  const lang = useLanguage();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const { mobile, user_type } = location.state;
  const [values, setValues] = useState(Array(6).fill(""));
  const [sentCode, setSentCode] = useState(false);
  const [countTime, setCountTime] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidText, setInvalidText] = useState("");

  const { fields } = usePinInput({
    values,
    onChange: (values) => {
      setValues(values);
      setInvalidText("");
    },
  });

  const isButtonDisabled = values.some((value) => value === "");

  useEffect(() => {
    let timer;

    if (sentCode && countTime > 0) {
      timer = setTimeout(() => {
        setCountTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setSentCode(false);
      setCountTime(60);
    }

    return () => clearTimeout(timer);
  }, [sentCode, countTime]);

  const handleSendCode = () => {
    setSentCode(true);
    checkUser(mobile);
    // Add your logic to send the OTP code here
    setValues(Array(6).fill(""));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setInvalidText("");
    setIsLoading(true);
    setTimeout(() => {
      callApi("auth/verify")
        .withBody({ otp: values.join(""), mobile })
        .loadingGroup(1)
        .execute()
        .then((res) => {
          if (res?.status === "success") {
            if (type == "signup") {
              navigate(`/register-success?type=signup&user-type=${user_type}`);
            } else {
              navigate("/reset", {
                state: { otp: values.join(""), mobile: mobile },
              });
            }
          } else {
            setInvalidText(res.error);
            setValues(Array(6).fill(""));
          }
        })
        .catch((err) => console.log(err, "verify error"));
      setIsLoading(false);
    }, 2000);
  };

  return (
    <AuthWrapper type="verify" backBtnLink={"/"}>
      <div className={styled.box}>
        <div className="">
          <img src={Icons.VerifyLogo} alt="" className={styled.img_box} />
        </div>
        <h3 className={styled.heading}>
          {translate("otp-verification", "OTP Verification")}
        </h3>
        <p className="text-center">
          {lang === "en"
            ? `OTP number is send to your phone number ${mobile.replace(
                mobile.substr(2, 7),
                "*******"
              )}. Please get
          the number and input here.`
            : `အတည်ပြုကုဒ်နံပါတ်ကို သင့်ဖုန်း ${mobile.replace(
                mobile.substr(2, 7),
                "*******"
              )} သို့ပို့ပြီးပါပြီ။ ကျေးဇူးပြု၍ ကုဒ်နံပါတ်ကို ဒီနေရာမှာထည့်ပါ။`}
        </p>
        <form action="" onSubmit={handleSubmit}>
          <div className=" flex justify-between items-center mt-[30px]">
            {fields.map((propsField, index) => (
              <input
                key={index}
                className={styled.input}
                {...propsField}
                placeholder="0"
                autoFocus={index === 0}
              />
            ))}
          </div>
          {invalidText && (
            <p className="text-red-4 mt-[10px]">
              {translate("invalid-otp", "Invalid OTP Number. Please double-check the code and try again.")}
            </p>
          )}
          {sentCode ? (
            <p className="text-center my-[30px] py-1 px-5 border-black-2 border-[1px] w-fit mx-auto rounded-[10px]">
              {countTime} s
            </p>
          ) : (
            <p
              className="text-center underline my-[30px] cursor-pointer"
              onClick={handleSendCode}
            >
              {translate("resent-code", "Resent Code")}
            </p>
          )}

          <div className=" flex justify-center">
            <Button
              type={"submit"}
              className={`${
                isButtonDisabled ? "bg-red-1" : "bg-red-3"
              } text-white h-[47px] w-[180px] 2xl:w-[230px] p-[8px] 2xl:p-[12px] rounded-[8px] lg:text-md 2xl:text-lg flex items-center justify-center mt-[20px] lg:mt-[30px]`}
              disabled={isButtonDisabled}
            >
              {isLoading ? (
                <BeatLoader color="#ffffff" size={13} />
              ) : (
                translate("verify", "Verify")
              )}
            </Button>
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default VerifyPage;
