import React, { useEffect } from "react";
import RegisterSuccessWrapper from "../../components/common/auth/RegisterSuccessWrapper";
import Images from "../../assets/images/index";
import Button from "../../components/common/button/Button";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import useDictionary from "../../hooks/lang/useDictionary";
import useAuth from "../../hooks/lang/useAuth";

const ChangePasswordSuccess = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const user = params.get("user-type") || "";
  const type = params.get("type") || "";
  const navigate = useNavigate();
  const { translate } = useDictionary();
  const token = useAuth();

  useEffect(() => {
    if (token) {
      window.location.reload();
    }
  }, [token]);
  return (
    <RegisterSuccessWrapper backBtnLink={"/"}>
      <div className=" w-[90%] sm:w-[80%] lg:w-[800px] lg:h-[500px] 2xl:w-[865px] 2xl:h-[555px] bg-white flex rounded-[12px] overflow-hidden">
        <div className="lg:w-[46%] relative">
          <img src={Images.successPet} alt="" className=" absolute bottom-0" />
        </div>
        <div className="lg:w-[54%] flex lg:pt-[10%] 2xl:pt-[15%] flex-col p-[30px] md:p-[60px] mx-auto lg:mx-0">
          <h1 className=" font-righteous text-center lg:text-start lg:text-3xl leading-normal">
            {translate("change-successful", "Change successful!")}
          </h1>
          <span className="body block my-[30px] text-center lg:text-start">
            {translate(
              "change-password-description",
              "Your password is changed. Please Login with new password."
            )}
          </span>
          <Button
            className={
              "bg-red-4 w-[240px] h-[47px] rounded-[8px] text-white mb-[30px] mx-auto lg:mx-0"
            }
            onClick={() => navigate("/login")}
          >
            {translate("login", "Login")}
          </Button>
          {/* <Button
            className={
              "flex items-center gap-3 font-muli text-[18px] md:text-[24px] text-sky_blue"
            }
            onClick={() => navigate("/")}
          >
            <FaAngleDoubleLeft />{" "}
            {translate("go-to-home-page", "Go to Home Page")}
          </Button> */}
        </div>
      </div>
    </RegisterSuccessWrapper>
  );
};

export default ChangePasswordSuccess;
