import React from "react";
import useDictionary from "../../hooks/lang/useDictionary";
import SummaryItem from "./SummaryItem";
import { formatNumber } from "../../utils/generals";
import { memo } from "react";
import useSelectorStore from "../../hooks/useSelectorStore";
import useLanguage from "../../hooks/lang/useLanguage";

const CheckoutSummary = () => {
  const { translate } = useDictionary();
  const language = useLanguage();
  const { cartInfo, userInfo } = useSelectorStore();

  const productOnly = cartInfo?.order_lines?.filter(
    (cart) => cart?.line_ref === false
  );

  return (
    <div className="lg:col-span-2 mt-[20px] lg:mt-0">
      <div className=" bg-white p-[30px] rounded-[8px]">
        <table className=" w-full">
          <thead>
            <tr>
              <td className=" border-b-[1px]" colSpan={3}>
                <h5 className=" mb-3">
                  {translate("order-summary", "Summary")}
                </h5>
              </td>
            </tr>
          </thead>
          <tbody>
            {productOnly?.map((item, i) => (
              <SummaryItem item={item} key={i} />
            ))}
          </tbody>
          <tfoot>
            {userInfo?.user_type == "b2c" && (
              <>
                <tr className=" border-t-[1px]">
                  <td className="py-3 body font-bold">
                    {translate("order-total", "Order Total")}
                  </td>
                  <td className="py-3"></td>
                  <td className=" text-end py-3 text-lg text-black-4 font-bold font-muli">
                    {formatNumber(cartInfo?.untax_amount, language)}{" "}
                    {translate("ks", "Ks")}
                  </td>
                </tr>
                {cartInfo?.tax ? (
                  <tr className="">
                    <td className="py-3 body font-bold">
                      {translate("tax", "Tax")}
                    </td>
                    <td className="py-3"></td>
                    <td className=" text-end py-3 text-lg text-black-4 font-bold font-muli">
                      {formatNumber(cartInfo?.tax, language)}{" "}
                      {translate("ks", "Ks")}
                    </td>
                  </tr>
                ) : null}
                {cartInfo?.member_discount_percent ? (
                  <tr className="">
                    <td className="pt-3 body pb-3">
                      {translate("member-discount", "Member Discount")} (
                      {formatNumber(
                        cartInfo?.member_discount_percent,
                        language
                      )}{" "}
                      %)
                    </td>
                    <td className="pt-3"></td>
                    <td className=" text-end pt-3 body pb-3">
                      -{" "}
                      {formatNumber(cartInfo?.member_discount_amount, language)}{" "}
                      {translate("ks", "Ks")}
                    </td>
                  </tr>
                ) : null}
              </>
            )}

            <tr className=" border-t-[1px]">
              <td className="pt-3">
                <h5>{translate("total", "Total")}</h5>
              </td>
              <td className="pt-3"></td>
              <td className="text-end pt-3 w-1/3">
                <h5>
                  {formatNumber(cartInfo?.total, language)}{" "}
                  {translate("ks", "Ks")}
                </h5>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default memo(CheckoutSummary);
