import React from "react";
import styled from "./register_success_wrapper.module.css";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import useDictionary from "../../../hooks/lang/useDictionary";

const RegisterSuccessWrapper = ({ backBtnLink = -1, children }) => {
  const navigate = useNavigate();
  const { translate } = useDictionary();
  return (
    <div className={styled.main}>
      <div className="w-full h-screen flex justify-center items-center">
        {children}
      </div>
      <Button
        className={
          "py-2 px-5 bg-white w-fit rounded-[5px] absolute top-[5%] left-[5%] flex items-center gap-2 text-black-3"
        }
        onClick={() => navigate(backBtnLink)}
      >
        <BsChevronLeft /> {translate("back-to-home", "Back to Home")}
      </Button>
    </div>
  );
};

export default RegisterSuccessWrapper;
