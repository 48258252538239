import React from "react";
import { SyncLoader } from "react-spinners";

const LoadingPage = () => {
  return (
    <div className=" w-full h-screen fixed top-0 left-0 bottom-0 bg-[#ffffffcd] z-50">
      <div className="container mx-auto h-full flex justify-center items-center">
        <SyncLoader color="#c8040b" size={13}/>
      </div>
    </div>
  );
};

export default LoadingPage;
