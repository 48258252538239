import React from "react";
import styled from "./branches.module.css";
import Images from "../../assets/images";
import Icons from "../../assets/icons/index";
import Button from "../../components/common/button/Button";
import useDictionary from "../../hooks/lang/useDictionary";

const BranchCard = ({ item }) => {
  const { translate } = useDictionary();
  return (
    <div className=" w-full min-h-[480px] relative">
      <div className="h-[80px] md:h-[130px] 2xl:h-[170px]">
        <div className="flex justify-center flex-col absolute top-[0px] left-0 right-0">
          <img
            src={item.store_image || Images.defaultImg}
            alt=""
            className="rounded-full w-[150px] h-[150px] md:h-[250px] 2xl:h-[300px] md:w-[250px] 2xl:w-[300px] mx-auto shadow-lg"
          />
          <div className=" text-center p-[20px]">
            <h4 className=" md:mt-[15px] mb-[10px] md:mb-[20px]">
              {item.shop_name}
            </h4>
            <span className="body">{item.address}</span>
            <div className="flex gap-3 justify-center my-[10px] md:my-[20px]">
              <a href={`tel:${item.phone}`} className="link underline">
                {item.phone}
              </a>
            </div>
            <span className="font-muliBold">
              {translate("opening-hour", "Opening Hour")} :{" "}
              {item.opening_hours || " 9am - 17pm | Mon-Sat"}
            </span>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${item.latitude},${item.longitude}`}
              className={
                " w-fit h-[50px] bg-black-1 rounded-[10px] p-[12px] flex items-center gap-1 mx-auto my-[30px] hover:bg-black-2 transition-all cursor-pointer"
              }
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={Icons.GoogleMapLogo} alt="" className="w-[30px]" />
              {translate("view-on-map", "View On Map")}
            </a>
          </div>
        </div>
      </div>
      <div className={styled.branch_card}></div>
    </div>
  );
};

export default BranchCard;
