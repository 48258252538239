import React, { useEffect, useState } from "react";
import Images from "../../assets/images/index";
import { useSelector } from "react-redux";
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import useDictionary from "../../hooks/lang/useDictionary";
import useLanguage from "../../hooks/lang/useLanguage";
import Icon from "../../assets/icons";

const AboutUsPage = () => {
  const [data, setData] = useState({});

  const { translate } = useDictionary();
  const language = useLanguage();

  const others = useSelector((state) => state?.others?.aboutUs?.data);

  useEffect(() => {
    callApi("others/aboutUs")
      .withParam({ lang: language })
      .loadingGroup("about")
      .executeDispatch();
  }, [language]);

  useEffect(() => {
    if (others) {
      setData(others);
    }
  }, [others]);
  return (
    <LoadingComponent loadingGroup={"about"}>
      <div className=" w-full h-auto lg:h-[100vh] 2xl:h-[75vh] relative">
        <div className=" grid grid-cols-1 lg:grid-cols-2 h-auto lg:h-full">
          <div className=""></div>
          <div
            className=" bg-center bg-cover bg-no-repeat h-[calc(100vh-70vh)] sm:h-[calc(100vh-60vh)] lg:h-full order-first lg:order-last"
            style={{ backgroundImage: `url('${data?.image}')` }}
          ></div>
        </div>
        <div className=" lg:absolute top-[calc(100vh-70vh)] sm:top-[calc(100vh-60vh)] lg:top-0 left-0 bottom-0 flex lg:items-center">
          <div className=" h-fit lg:w-[700px] lg:h-auto 2xl:w-[860px] 2xl:h-auto border-t-[17px] border-pink shadow-lg lg:translate-x-[20%] 2xl:translate-x-[30%] bg-[#fffffff3] p-[20px] md:p-[60px]">
            <div className="flex flex-row items-center	">
              <h4>{data ? translate("about-us", data.name) : "About Us"}</h4>
              <div className="ml-auto">
                <img src={Icon.petfoot} alt="" />
                <img src={Icon.petfoot} className="ml-6" alt="" />
              </div>
            </div>
            {data ? (
              <span
                dangerouslySetInnerHTML={{ __html: data.description }}
                className="body block mt-[20px] md:mt-[40px]"
              ></span>
            ) : (
              <span className="body block mt-[20px] md:mt-[40px]">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea
                rebum. Stet clita kasd gubergren, no sea takimata sanctus est
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea
                rebum. Stet clita kasd
              </span>
            )}
          </div>
        </div>
      </div>
    </LoadingComponent>
  );
};

export default AboutUsPage;
