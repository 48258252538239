import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "./productDetails.module.css";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import Image from "../../assets/images";
import ProductCard from "../../components/common/card/ProductCard";
import FavButton from "../../components/common/button/FavButton";
import QuantityCard from "../../components/common/quantity_card/quantityCard";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { HiTag } from "react-icons/hi";
import { AiFillCloseCircle } from "react-icons/ai";
import ReactImageZoom from "react-image-zoom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import Button from "../../components/common/button/Button";
import callApi from "../../services/api/apiClient";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import useLanguage from "../../hooks/lang/useLanguage";
import { priceHandler } from "../../utils/priceHandler";
import { MenuItem, Select } from "@mui/material";
import useAuth from "../../hooks/lang/useAuth";
import { formatNumber, smartClick } from "../../utils/generals";
import AlertPopup from "../../components/loading/AlertPopup";
import useDictionary from "../../hooks/lang/useDictionary";
import CartSuccess from "../../components/common/popup/CartSuccess";
import defaultImage from "../../assets/images/detail_default.jpg";
import ProductVariant from "./ProductVariant";
import {
  arrayToStringWithComma,
  isArrayIndexEmpty,
  neededVariantToProcess,
  toVariantRequireMessage,
} from "../../utils/arrayHandler";
import ProductVideo from "./ProductVideo";
import DelayHoverImage from "./delayProductDetail-img-hover";
import getBreadcrumb from "../../utils/getBreadcrumb";

let start = 0;
let end = 5;
const ProductDetails = () => {
  const language = useLanguage();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [selectedUom, setSelectedUom] = useState("");
  const [pricesList, setPricesList] = useState(null);
  const [qty, setQty] = useState(1);
  const [isActive, setIsActive] = useState(0);
  const token = useAuth();
  const { translate } = useDictionary();
  const [variants, setVariants] = useState([]);
  const [variantPriceLists, setVariantPriceLists] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [uoms, setUoms] = useState([]);
  const [photos, setPhotos] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();

  // console.log("prices", variantPriceLists);

  const productDetail = useSelector(
    (state) => state?.products?.getProductDetail?.data
  );
  const categories = useSelector(
    (state) => state?.products?.MyCategories?.data
  );

  const qtyInfo = useSelector((state) => state?.products?.checkQuantity?.data);

  const orderInfo =
    useSelector((state) => state?.persistSlice?.addToCart?.data) || "";

  const addToCartStatus =
    useSelector((state) => state?.persistSlice?.addToCart?.frame_status) || "";

  const userInfo = useSelector((state) => state?.auth?.getUser?.data) || "";

  const existWishList = useSelector(
    (state) => state?.wishList?.isExistWishList?.data
  );

  // console.log(variants, "variants");

  // retrive product detail
  useEffect(() => {
    callApi("products/getProductDetail")
      .withSegment(productId)
      .withParam({ lang: language })
      .loadingGroup("product")
      .executeDispatch();
  }, [productId]);

  useEffect(() => {
    if (productId) {
      setPhotos([]);
      setBuy(false);
    }
  }, [productId]);

  useEffect(() => {
    let gallery = [];

    if (productDetail?.image) {
      gallery.push(productDetail?.image);
    }
    if (productDetail?.gallery?.length > 0) {
      for (let img of productDetail?.gallery) {
        gallery.push(img.image_url);
      }
    }
    setPhotos(gallery);

    return () => {
      setPhotos([]);
    };
  }, [productDetail]);

  useEffect(() => {
    if (photos?.length > 0) {
      setViewImage(photos[0]);
    } else {
      setViewImage(defaultImage);
    }
  }, [photos]);

  // for add to cart popup
  useEffect(() => {
    if (
      addToCartStatus == "success" &&
      orderInfo?.status == "success" &&
      isAdd
    ) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 1500);
    }
  }, [addToCartStatus]);

  useEffect(() => {
    if (productDetail) {
      let uomList = productDetail?.uom?.filter((p) => p.display === true);
      setUoms(uomList);
      if (uomList?.length > 0) {
        setSelectedUom(uomList[0]?.id);
      }

      // let a = productDetail?.uom
      //   ?.filter((p) => p.display == true)
      //   .filter((item) => item.default == true)[0];
      // if (a?.length > 0) {
      //   setSelectedUom(a);
      // } else {
      //   setSelectedUom(productDetail?.uom?.find((item) => item.display));
      // }
    }
  }, [productDetail]);

  const getCartFun = () => {
    if (orderInfo?.id) {
      callApi("cart/getCart")
        .withSegment(orderInfo?.id)
        .withParam({ lang: language })
        .loadingGroup("c")
        .executeDispatch();
    }
  };

  let result = getBreadcrumb(productDetail?.category?.[0]?.id, categories);

  console.log(result, "resultresult");
  const item = [
    {
      name: translate("product", "Product"),
    },
  ];
  

  const product_Info = {
    product_name: "Prouct Name",
    original_price: "1,000",
    disc_price: "900",
    recom_price: "12000",
    product_detail:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    brand_name: "Brand Name",
    sku: "1235566",
    size: ["S", "M", "L"],
    colors: [
      {
        color_name: "Blue",
        color_code: "#001A57",
      },
      {
        color_name: "Black",
        color_code: "#000000",
      },
      {
        color_name: "Purple",
        color_code: "#602383",
      },
    ],
  };

  const [check, setCheck] = useState(false);
  const [promotionGroup, setPromotionGroup] = useState("");
  const [buy, setBuy] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [imgName, setImgName] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const refImg = useRef(null);
  const refInfo = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [zoomWidth, setZoomWidth] = useState(0);
  const [isNeedVariant, setIsNeedVariant] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [start, setStart] = useState(0);
  // const [end, setEnd] = useState(5);
  const ref = useRef(null);

  useEffect(() => {
    if (addToCartStatus) {
      getCartFun();
    }
  }, [addToCartStatus]);

  // useEffect(() => {
  //   if (productDetail) {
  //     setViewImage((photos?.length && photos[0]?.image_url) || defaultImage);
  //   }
  // }, [productDetail]);

  useEffect(() => {
    let productInfo;
    if (productDetail) {
      productInfo = priceHandler(
        productDetail?.uom,
        productDetail?.price,
        qty,
        productDetail?.promotion?.[0],
        selectedUom,
        variantPriceLists
      );
      console.log(productInfo, "productInfo")
    }
    if (productInfo) {
      setPricesList(productInfo);
    }
  }, [productDetail, selectedUom]);

  useEffect(() => {
    if (productDetail?.video_link) {
      setVideoUrl(productDetail?.video_link);
    }
    if (productDetail?.youtube) {
      setYoutubeUrl(productDetail?.youtube);
    }
  }, [productDetail?.video_link, productDetail?.youtube]);

  useEffect(() => {
    if (!productDetail?.in_stock) {
      setQty(1);
    } else {
      setQty(Number(productDetail?.min_buy_limit));
    }
  }, [productDetail]);

  useEffect(() => {
    if (existWishList?.exist) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [existWishList]);

  useEffect(() => {
    if (productDetail) {
      setPromotionGroup(
        productDetail?.promotion?.find(
          (promo) => promo?.promotion_type == "group"
        )?.group_promotion_details[0]
      );
    }
  }, [productDetail]);

  useEffect(() => {
    if (productDetail?.attributes?.length > 0) {
      setVariants(new Array(productDetail?.attributes?.length).fill(null));
    } else {
      setVariants([]);
    }
  }, [productDetail?.attributes?.length, productId]);

  // console.log(promotionGroup);

  const isExistWishList = (id, vals) => {
    let datas = { product_id: id };

    if (vals != "false") {
      datas.variant_id = vals;
    }

    callApi("wishList/isExistWishList")
      .withParam(datas)
      .loadingGroup("p")
      .executeDispatch();
  };

  const productAddToWishListApiCall = (id, vals) => {
    // console.log("add to wishlist");
    if (token) {
      let datas = { product_id: Number(id) };
      if (vals != "false") {
        datas.variant_id = vals;
      }
      if (!check) {
        callApi("wishList/addToWishList")
          .withBody(datas)
          .loadingGroup("wishlist")
          .executeDispatch();
        // console.log(id, vals);
      } else {
        callApi("wishList/deleteWishList")
          .withBody({ wishlist_id: existWishList?.wishlist_id })
          .loadingGroup("wishlist")
          .executeDispatch();
      }
      setCheck(!check);
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  const handleAddToWishList = (productId) => {
    if (productDetail?.attributes?.length > 0) {
      !isArrayIndexEmpty(variants)
        ? productAddToWishListApiCall(
            productId,
            arrayToStringWithComma(variants)
          )
        : setIsNeedVariant(true);
    } else {
      productAddToWishListApiCall(productId, arrayToStringWithComma(variants));
    }
  };

  const checkQty = () => {
    const datas = {
      product_id: Number(productId),
      quantity: qty,
    };

    if (!isArrayIndexEmpty(variants)) {
      datas.variant_id = arrayToStringWithComma(variants);
    }

    callApi("products/checkQuantity")
      .withBody(datas)
      .loadingGroup("checkqty")
      .executeDispatch();
  };

  useEffect(() => {
    if (userInfo?.user_type !== "b2b") {
      checkQty();
    }
  }, [variants, userInfo?.user_type]);

  useEffect(() => {
    isExistWishList(productId, arrayToStringWithComma(variants));
  }, [variants, productId]);

  useEffect(() => {
    if (userInfo?.user_type !== "b2b") {
      smartClick(() => {
        checkQty();
      });
    }
  }, [qty]);

  useEffect(() => {
    if (variants.length > 0 && !isArrayIndexEmpty(variants)) {
      setIsNeedVariant(false);
    }
  }, [variants]);

  const changeViewImg = (image, index) => {
    setViewImage(image);
    setImgName(image);
    setSelectedIndex(index);
  };
  const nextImg = () => {
    console.log("selected index....", selectedIndex);
    if (selectedIndex > 0) {
      setViewImage(photos?.length && photos[selectedIndex - 1]);
      setSelectedIndex(selectedIndex - 1);
    } else {
      setViewImage(photos?.length && photos[photos?.length - 1]);
      setSelectedIndex(photos?.length - 1);
      console.log("End....", end, start);
    }

    if (selectedIndex > 4 && end == photos?.length) {
      start = start - 1;
      end = end - 1;
    } else if (selectedIndex === 0) {
      start = photos?.length - end;
      end = photos?.length;
    } else {
      start = 0;
      end = 5;
    }
  };
  const prevImg = (index) => {
    let i = index + 1;
    if (i < photos?.length) {
      setViewImage(photos[i]);
      setSelectedIndex(i);
    } else {
      setViewImage(photos[0]);
      setSelectedIndex(0);
    }

    if (i > 4 && end != photos?.length) {
      start = start + 1;
      end = end + 1;
    } else {
      start = 0;
      end = 5;
    }
  };

  useLayoutEffect(() => {
    setWidth(refImg?.current?.offsetWidth);
    setHeight(refImg?.current?.offsetHeight);
    setZoomWidth(refInfo?.current?.offsetWidth);
  }, []);

  // for buy now function
  const handleBuyNow = () => {
    if (
      userInfo?.user_type === "b2b" ||
      (productDetail?.in_stock && !neededVariantToProcess(variants))
    ) {
      handleAddToCard("buy");
      // navigate("/checkout")
    }
  };

  const handleAddToCard = (type = "add") => {
    console.log("add to cart");
    const time = new Date().toLocaleDateString("de-DE");
    const isOrderExpire = time == orderInfo?.order_valid_date;
    // console.log(isOrderExpire);
    let datas = {
      product_id: Number(productId),
      quantity: qty,
      source: "web",
      uom_id: selectedUom,
    };

    if (orderInfo?.id) {
      datas.order_id = isOrderExpire ? orderInfo?.id : "";
    }

    if (variants.length > 0) {
      datas.variant_id = arrayToStringWithComma(variants);
    }

    if (
      (userInfo?.user_type === "b2b" ? true : productDetail?.in_stock) &&
      (variants.length > 0 ? !isArrayIndexEmpty(variants) : true)
    ) {
      setIsNeedVariant(false);
      callApi("persistSlice/addToCart")
        .withBody(datas)
        .loadingGroup("product-add")
        .executeDispatch();

      setIsAdd(true);
      setTimeout(() => {
        setIsAdd(false);
      }, 2000);
      if (promotionGroup) {
        setBuy(!buy);
      }
      type == "buy" && navigate("/checkout");
    } else {
      setIsNeedVariant(true);
    }
    // setBuy(!buy);
  };

  // quantity info
  const qtyDatas = {
    qty,
    setQty,
    availableQty: qtyInfo?.available_base_qty,
    isInStock: productDetail?.in_stock,
    minQty: productDetail?.min_buy_limit,
  };

  const LargeProps = {
    width: width,
    // height: height,
    img: viewImage,
    zoomWidth: zoomWidth,
    offset: { vertical: 0, horizontal: 34 },
    zoomStyle: "z-index: 99;",
    zoomLensStyle: "opacity: 0.4;background-color: black;cursor: crosshair;",
  };
  const SmallProps = {
    width: width,
    // height: height,
    img: viewImage,
    zoomPosition: "bottom",
    zoomWidth: zoomWidth,
    offset: { vertical: 20, horizontal: -45 },
    zoomStyle: "z-index: 99999;",
    zoomLensStyle: "opacity: 0.4;background-color: black;cursor: crosshair;",
  };
  // Product Detail
  const ProductDetailCon = () => {
    return (
      <div className={styled.detailCon}>
        {/* View Image */}
        <div className={styled.imgCon}>
          {photos?.length > 0 ? (
            <div ref={ref} className={styled.verticalSlide}>
              {photos?.length > 5 && (
                // <div className={styled.verticalSlide}>
                <button className={styled.next} onClick={(_) => nextImg()}>
                  <BsChevronUp size={18} />
                </button>
              )}
              {/* <div style={{backgroundColor: 'green', overflow: 'scroll'}}> */}
              {photos?.length > 5 &&
                photos?.slice(start, end).map(
                  (img, index) => (
                    // index < 5 && (
                    <button
                      id="btn"
                      className={
                        viewImage === img
                          ? styled["imgBtn"] + " " + styled.active
                          : styled.imgBtn
                      }
                      onClick={(_) => changeViewImg(img, index)}
                      key={index}
                    >
                      <img
                        src={img}
                        alt=""
                        // title={img.name}
                      />
                    </button>
                  )
                  // )
                )}
              {photos?.length <= 5 &&
                photos?.map(
                  (img, index) => (
                    // index < 5 && (
                    <button
                      id="btn"
                      className={
                        viewImage === img
                          ? styled["imgBtn"] + " " + styled.active
                          : styled.imgBtn
                      }
                      onClick={(_) => changeViewImg(img, index)}
                      key={index}
                    >
                      <img
                        src={img}
                        alt=""
                        // title={img.name}
                      />
                    </button>
                  )
                  // )
                )}
              {/* </div> */}
              {photos?.length > 5 && (
                <button
                  className={styled.prev}
                  onClick={(_) => prevImg(selectedIndex)}
                >
                  <BsChevronDown size={18} />
                </button>
              )}
            </div>
          ) : null}

          <div className={styled.view} ref={refImg}>
            {/* <div className={styled.viewImg}> */}
            {window.innerWidth > 1023 ? (
              <DelayHoverImage
                viewImage={viewImage}
                LargeSmallProps={LargeProps}
              />
            ) : (
              <ReactImageZoom {...SmallProps} />
              // <DelayHoverImage viewImage={viewImage} LargeSmallProps={SmallProps} />
            )}
            {/* </div> */}
          </div>
        </div>
        {/* View Info */}
        <div className={styled.infoCon} ref={refInfo}>
          {productDetail?.promotion && (
            <PromotionCard data={productDetail?.promotion} />
          )}
          <ProductInfo product_Info={product_Info} />
        </div>
      </div>
    );
  };

  const ProductInfo = ({ product_Info }) => {
    return (
      <div className="flex flex-col gap-7">
        {product_Info && (
          <>
            <div className="flex items-center justify-between">
              <h4>{productDetail?.name}</h4>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              {productDetail?.promotion?.[0]?.promotion_type == "discount" ? (
                <>
                  <p className={styled.firstPrice}>
                    {formatNumber(pricesList?.promotionPrice, language)}{" "}
                    {translate("ks", "Ks")}
                  </p>
                </>
              ) : null}
              <p
                className={
                  productDetail?.promotion?.[0]?.promotion_type == "discount"
                    ? styled.secondPrice
                    : styled.firstPrice
                }
              >
                {formatNumber(pricesList?.normalPrice, language)}{" "}
                {translate("ks", "Ks")}
              </p>
              {uoms?.length > 1 && (
                <Select
                  className="h-[40px] ml-[30px]"
                  defaultValue={selectedUom}
                  onChange={(e) => setSelectedUom(e.target.value)}
                >
                  {uoms?.map((item) =>
                    item.display ? (
                      <MenuItem value={item.id} className="body" key={item.id}>
                        {item.name}
                      </MenuItem>
                    ) : null
                  )}
                </Select>
              )}

              {uoms?.length === 1 && (
                <div className="px-[12px] py-[7px] ml-[30px] rounded-[4px] border border-black-2">
                  <p>{uoms[0]?.name}</p>
                </div>
              )}

              {productDetail?.recommended_price &&
              userInfo?.user_type == "b2b" ? (
                <p className={styled.thirdPrice}>
                  {translate("recommended-price", "Recommended Price")} :{" "}
                  {formatNumber(productDetail?.recommended_price, language)}{" "}
                  {translate("ks", "Ks")}
                </p>
              ) : null}
            </div>
            {productDetail?.summary ? (
              <div className="flex flex-col gap-5">
                <p className="body">{productDetail?.summary}</p>
              </div>
            ) : null}

            {productDetail?.brand?.name || productDetail?.code ? (
              <div className="flex flex-col gap-3">
                {productDetail?.brand?.name ? (
                  <div className="flex gap-3">
                    <p className="w-[40px] body">
                      {translate("brand", "Brand")}
                    </p>
                    <p
                      className="title2 cursor-pointer"
                      onClick={() =>
                        navigate(`/filter?brand_id=${productDetail?.brand?.id}`)
                      }
                    >
                      : {productDetail?.brand?.name}
                    </p>
                  </div>
                ) : null}
                {productDetail?.code ? (
                  <div className="flex gap-3">
                    <p className="w-[40px] body">{translate("sku", "SKU")}</p>
                    <p className="title2">: {productDetail?.code}</p>
                  </div>
                ) : null}
              </div>
            ) : null}

            {/* for variant  */}
            {productDetail?.attributes?.length > 0 ? (
              <div>
                {isNeedVariant ? (
                  <p className=" text-red-3 mb-2">
                    * Please select{" "}
                    {toVariantRequireMessage(productDetail?.attributes)}
                  </p>
                ) : (
                  <p className=" mb-2">
                    * Please select{" "}
                    {toVariantRequireMessage(productDetail?.attributes)}
                  </p>
                )}
                {productDetail?.attributes?.map((attribute, id) => (
                  <ProductVariant
                    key={id}
                    data={attribute}
                    variants={variants}
                    setVariants={setVariants}
                    variantPriceLists={variantPriceLists}
                    setVariantPriceLists={setVariantPriceLists}
                    index={id}
                  />
                ))}
              </div>
            ) : null}

            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                {/* <p className="body uppercase">{translate("qty", "QTY")}:</p> */}
                <FavButton
                  onClick={() => handleAddToWishList(productId)}
                  checked={check}
                />
                <QuantityCard {...qtyDatas} />
                {userInfo?.user_type !== "b2b" && (
                  <>
                    {!productDetail?.in_stock ? (
                      <p className=" text-red-2 text-sm sm:text-base">
                        {translate("out_of_stock", "Out of stock")} !
                      </p>
                    ) : (
                      qtyInfo?.available_base_qty > 0 &&
                      userInfo?.user_type !== "b2b" && (
                        <p className=" text-red-2 text-sm sm:text-base">
                          {language === "en"
                            ? `Only ${qtyInfo?.available_base_qty} left`
                            : `${formatNumber(
                                qtyInfo?.available_base_qty,
                                language
                              )} ခုသာကျန်တော့သည်`}
                        </p>
                      )
                    )}
                  </>
                )}
              </div>
              <div className="flex items-center gap-2">
                <Button
                  className={`${
                    !productDetail?.in_stock && userInfo?.user_type !== "b2b"
                      ? "bg-teal-100 cursor-default"
                      : " bg-sky_blue cursor-pointer hover:bg-[#02c9bf]"
                  } ${styled.buyBtn}`}
                  onClick={() => handleBuyNow()}
                  children={translate("buy-now", "Buy Now")}
                  disabled={
                    userInfo?.user_type === "b2b"
                      ? false
                      : !productDetail?.in_stock
                  }
                />
                <Button
                  className={`${
                    !productDetail?.in_stock && userInfo?.user_type !== "b2b"
                      ? "bg-red-1 cursor-default"
                      : " bg-red-3 cursor-pointer hover:bg-[#c8040b]"
                  } ${styled.addToCartBtn}`}
                  onClick={() => handleAddToCard()}
                  children={translate("add-to-cart", "Add to Cart")}
                  disabled={
                    userInfo?.user_type === "b2b"
                      ? false
                      : !productDetail?.in_stock
                  }
                />
                {buy && (
                  <RelatedPromoPop
                    data={promotionGroup?.group_applied_product_template_ids}
                    fixedPrice={promotionGroup?.fixed_price_amount}
                    title={translate("group-promotion", "Group Promotion")}
                    //   language === "en"
                    //     ? `${promotionGroup?.reward_type} ( Buy any ${promotionGroup?.minimum_buy_qty} pcs , save Ks ${promotionGroup?.fixed_price_amount} )`
                    //     : `${promotionGroup?.reward_type} (${formatNumber(
                    //         promotionGroup?.minimum_buy_qty,
                    //         language
                    //       )}ခုဝယ်လျှင် ${formatNumber(
                    //         promotionGroup?.fixed_price_amount,
                    //         language
                    //       )}ကျပ် သက်သာ)`
                    // }
                    desc={
                      language === "en"
                        ? `Make a purchase for at least ${
                            promotionGroup?.minimum_buy_qty
                          } quantity from any ${
                            promotionGroup?.minimum_buy_product_types
                          } of the following get ${
                            promotionGroup?.reward_type === "special_price"
                              ? `with special price (${promotionGroup?.fixed_price_amount} Ks)`
                              : promotionGroup?.gift_product[0]?.product_name
                          }`
                        : `အောက်တွင်ဖော်ပြထားသော ပစ္စည်းများမှ အနည်းဆုံး ${formatNumber(
                            promotionGroup?.minimum_buy_qty,
                            language
                          )} မျိုးကို အနည်းဆုံး ${formatNumber(
                            promotionGroup?.minimum_buy_product_types,
                            language
                          )} ခုဝယ်ယူ ပြီး ${
                            promotionGroup?.reward_type === "special_price"
                              ? `အထူးစျေးနှုန်း ${formatNumber(
                                  promotionGroup?.fixed_price_amount,
                                  language
                                )} ကျပ်ဖြင့် ရယူလိုက်ပါ`
                              : `${promotionGroup?.gift_product[0]?.product_name} 1 ခု လက်ဆောင်ရယူလိုက်ပါ`
                          }`
                    }
                  />
                )}
              </div>
              {productDetail?.in_stock &&
              qtyInfo?.error &&
              userInfo?.user_type !== "b2b" ? (
                <p className=" text-red-2">{qtyInfo?.error}</p>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
  };
  const RelatedPromoPop = ({ data, title, desc, fixedPrice }) => {
    useEffect(() => {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `${window.scrollY}px`;
      return () => {
        document.body.style.overflow = "unset";
        document.body.style.position = "";
        document.body.style.top = "";
      };
    }, []);
    return (
      <>
        <div
          style={{ background: "rgba(0,0,0,0.4)" }}
          className="w-[100%] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          {isSuccess && (
            <CartSuccess
              onClick={() => {
                navigate("/add-to-card");
                setIsSuccess(false);
              }}
            />
          )}{" "}
          <div className="container">
            {/*content*/}
            <div className="w-full gap-4 pb-5 sm:pb-10 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
              <button className={styled.closeBtn} onClick={() => setBuy(false)}>
                <AiFillCloseCircle size={24} />
              </button>
              <div className={styled.highLight}>
                <HiTag size={20} />
                <p className="w-[calc(100%-30px)]">{title}</p>
              </div>
              <p className="title2 px-5 sm:px-10">{desc}</p>
              <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 px-5 py-5 sm:px-10 gap-4 max-h-[50vh] xl:max-h-[60vh] overflow-y-scroll">
                {data?.map((promo, index) => (
                  <ProductCard item={promo} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  };

  const relatedProps = {
    alternativeProducts: productDetail?.alternative_products,
    gallery: productDetail?.gallery,
  };

  const descriptionProps = {
    description: productDetail?.description,
    videoUrl,
    youtubeUrl,
    isActive,
    setIsActive,
  };

  return (
    <LoadingComponent loadingGroup={"product"}>
      {showAlert && <AlertPopup btnLabel={"Login"} route={"/login"} />}
      {isSuccess && !buy && (
        <CartSuccess
          onClick={() => {
            navigate("/add-to-card");
            setIsSuccess(false);
          }}
        />
      )}
      {viewImage && (
        <>
          <Breadcrumb item={result?.path} title={translate("product", "Product")} />
          <div className="container-con product-detail bg-black-0">
            <div className="container">
              <div className={styled.productDetailCon}>
                <ProductDetailCon />
                <ProductDescriptionCon {...descriptionProps} />
                <ProductRelatedCon {...relatedProps} />
              </div>
            </div>
          </div>
        </>
      )}
    </LoadingComponent>
  );
};

// Product Description, Ingredients and How To Use
const ProductDescriptionCon = (props) => {
  const { description, isActive, setIsActive, videoUrl, youtubeUrl } = props;
  const { translate } = useDictionary();
  return (
    <div className={styled.descCon}>
      {description?.description &&
      description?.ingredients &&
      description?.how_to_use ? (
        <div className={styled["header"] + " " + "title1"}>
          <button
            className={
              styled["buttonCon"] + " " + `${isActive == 0 && styled.active}`
            }
            onClick={() => setIsActive(0)}
          >
            <p className="title1">{translate("description", "Description")}</p>
            <hr />
          </button>
          <button
            className={
              styled["buttonCon"] + " " + `${isActive == 1 && styled.active}`
            }
            onClick={() => setIsActive(1)}
          >
            <p className="title1">{translate("ingredients", "Ingredients")}</p>
            <hr />
          </button>
          <button
            className={
              styled["buttonCon"] + " " + `${isActive == 2 && styled.active}`
            }
            onClick={() => setIsActive(2)}
          >
            <p className="title1">{translate("howtouse", "How To Use")}</p>
            <hr />
          </button>
        </div>
      ) : null}
      <div className="flex flex-col gap-[20px] mb-[20px]">
        {isActive == 0 ? (
          <p className="body">{description?.description}</p>
        ) : isActive == 1 ? (
          <p className="body">{description?.ingredients}</p>
        ) : isActive == 2 ? (
          <p className="body">{description?.how_to_use}</p>
        ) : null}
      </div>
      {videoUrl && <ProductVideo videoUrl={videoUrl} />}
      {youtubeUrl && <ProductVideo videoUrl={youtubeUrl} />}
    </div>
  );
};

// Related Products
const ProductRelatedCon = (props) => {
  const { alternativeProducts: alternative_products, gallery } = props;
  const { translate } = useDictionary();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <div className={styled.relateCon}>
      {alternative_products?.length > 0 ? (
        <h4>{translate("related-products", "Related Products")}</h4>
      ) : null}
      <Swiper
        slidesPerView={
          window.innerWidth > 1024 ? 4 : window.innerWidth > 992 ? 3 : 2
        }
        modules={[Navigation]}
        spaceBetween={30}
        navigation={gallery?.length > 5 ? true : false}
        slidesPerGroup={1}
        loop={true}
        className={styled.mySwiper}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {alternative_products?.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <ProductCard item={slideContent} type="related" />
          </SwiperSlide>
        ))}
        {alternative_products?.length > 5 && (
          <>
            <div className={styled.swiperButtonPrev} ref={prevRef}></div>
            <div className={styled.swiperButtonNext} ref={nextRef}></div>
          </>
        )}
      </Swiper>
    </div>
  );
};

const PromotionCard = ({ data }) => {
  const language = useLanguage();
  const { translate } = useDictionary();
  return (
    <>
      {data?.map((promotion, index) => {
        return promotion?.promotion_type === "discount" ? (
          <div
            className="w-full flex gap-2 bg-[rgba(200,4,11,10%)] rounded-[8px] p-[12px] mb-[15px]"
            key={index}
          >
            <div className={styled.iconColor}>
              <HiTag size={22} />
            </div>
            <div className="flex flex-col gap-2">
              <h5>{promotion?.name}</h5>
              <p className="text-red-4 font-muliBold font-[600]">
                {formatNumber(promotion?.discount_percent, language)}%{" "}
                {translate("off", "OFF")}
              </p>
              {/* <p className="body">{data?.description}</p> */}
            </div>
          </div>
        ) : promotion?.promotion_type === "product" ? (
          <div className="w-full flex gap-2 bg-[rgba(200,4,11,10%)] rounded-[8px] p-[12px] mb-[15px]">
            <div className={styled.iconColor}>
              <HiTag size={22} />
            </div>
            <div className="flex flex-col gap-2">
              <h5>{promotion?.name}</h5>
              <p className="text-red-4 font-muliBold font-[600]">
                {language === "en"
                  ? `Buy ${promotion?.buy_product[0]?.min_qty || 1} Get
              ${promotion?.reward_product[0]?.reward_qty || 1} Free`
                  : `${formatNumber(
                      promotion?.buy_product[0]?.min_qty || 1,
                      language
                    )} ခု ဝယ် ${formatNumber(
                      promotion?.reward_product[0]?.reward_qty || 1,
                      language
                    )} ခု လက်ဆောင်`}
              </p>
              {promotion?.reward_product[0]?.reward_image ? (
                <div className="flex items-start gap-5">
                  <img
                    src={`${promotion?.reward_product[0]?.reward_image}`}
                    alt=""
                    className="w-[60px] h-[60px] rounded-[5px]"
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : promotion?.promotion_type === "group" ? (
          <div
            className="w-full flex gap-2 bg-[rgba(200,4,11,10%)] rounded-[8px] p-[12px] mb-[30px]"
            key={index}
          >
            <div className={styled.iconColor}>
              <HiTag size={22} />
            </div>
            <div className="flex flex-col gap-2">
              <h5>{promotion?.name}</h5>
              {promotion?.group_promotion_details?.map((p, index) => {
                return (
                  <div key={index} className=" flex gap-2">
                    <p className="text-red-4 font-muliBold font-[600]">
                      {language === "en"
                        ? `Buy ${p?.minimum_buy_qty} Get ${
                            p?.reward_type === "special_price"
                              ? `with special price (${p?.fixed_price_amount} Ks)`
                              : `1 ${p?.gift_product[0]?.product_name} free`
                          }`
                        : ` ${formatNumber(
                            p?.minimum_buy_qty,
                            language
                          )} ခုဝယ်ပြီး ${
                            p?.reward_type === "special_price"
                              ? `အထူးစျေးနှုန်း ${formatNumber(
                                  p?.fixed_price_amount,
                                  language
                                )} ကျပ်ဖြင့် ရယူလိုက်ပါ`
                              : `${p?.gift_product[0]?.product_name} 1 ခု လက်ဆောင်ရယူလိုက်ပါ`
                          }`}
                    </p>
                    {p?.reward_image ? (
                      <div className="flex items-start gap-5 mt-[12px]">
                        <img
                          src={`${p?.reward_image || Image.defaultImg}`}
                          alt=""
                          className="w-[60px] h-[60px] rounded-[5px]"
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null;
      })}
    </>
  );
};

export default ProductDetails;
