import { useEffect } from "react";
import InputSelect from "../../components/common/input/InputSelect";
import TextFieldInput from "../../components/common/input/TextField";
import useDictionary from "../../hooks/lang/useDictionary";
import useSelectorStore from "../../hooks/useSelectorStore";
import { useState } from "react";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { CircularProgress } from "@mui/material";

const AddShippingAddressForm = ({ formik }) => {
  const { translate } = useDictionary();
  const { deliverableLocations } = useSelectorStore();
  // states
  const [divisions, setDivisions] = useState([]);
  const [townships, setTownships] = useState([]);
  useEffect(() => {
    if (deliverableLocations) {
      setDivisions(deliverableLocations[0]?.state_ids);
    }
  }, [deliverableLocations]);
  useEffect(() => {
    if (formik.values.division) {
      setTownships(
        divisions?.find((state) => state.id == formik.values.division)
          ?.township_ids
      );
    }
  }, [formik.values.division, divisions]);
  return (
    <form action="" onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px]">
        <div className="col-span-2 md:col-span-1">
          <TextFieldInput
            name={"name"}
            type={"text"}
            label={translate("name", "Name") + "*"}
            className={"w-full"}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik?.touched.name && formik?.errors.name ? (
            <span className=" text-sm text-red-2">{formik.errors.name}</span>
          ) : null}
        </div>
        <div className="col-span-2 md:col-span-1">
          <TextFieldInput
            name={"phone"}
            type={"text"}
            label={translate("phone", "Phone") + "*"}
            className={"w-full"}
            value={formik.values.phone}
            onChange={formik.handleChange}
          />
          {formik?.touched.phone && formik?.errors.phone ? (
            <span className=" text-sm text-red-2">{formik.errors.phone}</span>
          ) : null}
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputSelect
            name={"division"}
            data={divisions}
            label={translate("states-divisions", "State / Division")}
            className={"w-full"}
            value={formik.values.division}
            onChange={formik.handleChange}
          />
          {formik?.touched.division && formik?.errors.division ? (
            <span className=" text-sm text-red-2">
              {formik.errors.division}
            </span>
          ) : null}
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputSelect
            name={"township"}
            data={townships}
            label={translate("township", "Township") + "*"}
            className={"w-full"}
            value={formik.values.township}
            onChange={formik.handleChange}
          />
          {formik?.touched.township && formik?.errors.township ? (
            <span className=" text-sm text-red-2">
              {formik.errors.township}
            </span>
          ) : null}
        </div>
        <div className=" col-span-2">
          <TextFieldInput
            name={"address"}
            type={"text"}
            label={translate("address", "Address") + "*"}
            className={"w-full"}
            value={formik.values.address}
            onChange={formik.handleChange}
          />
          {formik?.touched.address && formik?.errors.address ? (
            <span className=" text-sm text-red-2">{formik.errors.address}</span>
          ) : null}
        </div>
      </div>
      <button className=" border-red-3 border-[1px] h-[52px] w-full rounded-[5px] mt-5">
        <LoadingComponent
          loadingGroup={"add_address"}
          loadingDesign={<CircularProgress color="inherit" size={20} />}
        >
          Save
        </LoadingComponent>
      </button>
    </form>
  );
};

export default AddShippingAddressForm;
