import { AccessTokenSlice } from "../redux/reducers/reducer";

export const SelectAccessTokenEndpoint = () => {};

export const res_modifiers_config = {
  getAccessToken: (res) => {
    return res;
  },
  getOrderId: (res) => {
    let order_id = {};
    if (res?.order_id) {
      order_id.id = res?.order_id;
      order_id.order_valid_date = new Date().toLocaleDateString("de-DE");
      order_id.status = res?.status;
    } else {
      return {error: res?.error};
    }

    // console.log(order_id, "res modifier");
    return order_id;
  },
};
