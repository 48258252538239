import React from "react";
import Button from "../../components/common/button/Button";
import useDictionary from "../../hooks/lang/useDictionary";
import Icons from "../../assets/icons";

const SendMessageSuccess = ({ onClick }) => {
  const { translate } = useDictionary();
  return (
    <div className=" fixed w-full h-screen bg-[#333333c9] top-0 left-0 right-0 z-50 flex justify-center items-center">
      <div className=" w-[90%] md:w-[450px] min-h-[300px] bg-white border-t-[20px] border-red-1 rounded-[5px] p-[20px] md:p-[40px]">
        <div className="">
          <img
            src={Icons.exclamationIcon}
            alt=""
            className=" w-[60px] h-[60px] mx-auto"
          />
          <p className="body text-center mt-[15px]">
            {translate(
              "successfully-send-message",
              "Successfully send message."
            )}
          </p>
        </div>
        <div className=" text-center mt-[20px]">
          <Button
            className={"w-[200px] p-[12px] rounded-[10px] bg-red-4 text-white"}
            onClick={onClick}
          >
            {translate("ok", "OK")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SendMessageSuccess;
