// import LogoImg from './logo.png';
import ProductImg from "./product.png";
import ProductImg1 from "./product_1.png";
import Banner1 from "./Home_banner.png";
import Banner2 from "./Home_banner.png";
import Delivery from "./delivery_banner.png";
import category from "./category.png";
import promo1 from "./promo1.png";
import promo2 from "./promo2.png";
import mb_Delivery from "./mb_delivery_banner.png";
import DogImg from "./dog_img.png";
import img1 from "./img1.png";
import img2 from "./img2.png";
import QrImg from "./npt_sample_qr.png";
import successPet from "./success_pet.png";
import branchImg from "./branch_img.png";
import aboutImg from "./about_img.jpg";
import promotionAdsImg from "./promotion_ads.png";
import mbPromotionAdsImg from "./mb_promotion_ads.png";
import loadingGif from "./loading.gif";
import loadingGifT from "./pet_Loading.gif";
import defaultImg from "./default_img.jpg";
import addressImg from "./address.svg";
import orderSuccessImg from "./order_success.png";
import subCategoryBg from "./subCategory_bg.png";
import detailDefaultImg from "./detail_default.jpg";
import promotionDefaultImg from "./promotion_default.jpg";
import brandDefaultImg from "./brand_default.png";

// Mobile
import MbBanner1 from "./mb.png";
import MbBanner2 from "./mb.png";

export default {
  // LogoImg,
  ProductImg,
  ProductImg1,
  Banner1,
  Banner2,
  Delivery,
  category,
  promo1,
  promo2,
  mb_Delivery,
  DogImg,
  img1,
  img2,
  // Mobile
  MbBanner1,
  MbBanner2,
  QrImg,
  successPet,
  branchImg,
  aboutImg,
  promotionAdsImg,
  mbPromotionAdsImg,
  loadingGif,
  loadingGifT,
  defaultImg,
  addressImg,
  orderSuccessImg,
  subCategoryBg,
  detailDefaultImg,
  brandDefaultImg,
  promotionDefaultImg,
};
