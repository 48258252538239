import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import styled from "./productListing.module.css";
import Breadcrumb from "../../components/common/breadcrumb/Breadcrumb";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Icons from "../../assets/icons";
import ProductCard from "../../components/common/card/ProductCard";
// import Pagination from "../../components/common/pagination/pagination";
import { BiFilterAlt } from "react-icons/bi";
import FilterProduct from "../../components/common/filter/filterProduct";
import { Modal, Ripple, initTE } from "tw-elements";
import useLanguage from "../../hooks/lang/useLanguage";
import callApi from "../../services/api/apiClient";
import { useSelector } from "react-redux";
import useDictionary from "../../hooks/lang/useDictionary";
import LoadingComponent from "../../components/loading/LoadingComponent";
import getBreadcrumb from "../../utils/getBreadcrumb";
import SubCategory from "./subCategory";
import BrandCategory from "../brand/brand_category";
import { Pagination } from "@mui/material";

initTE({ Modal, Ripple });

const ProductListing = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category_id = params.get("category_id");
  const brand_id = params.get("brand_id");
  const firstQueryStringKey = params?.keys()?.next()?.value;
  const categories = useSelector(
    (state) => state?.products?.MyCategories?.data
  );
  const brands = useSelector((state) => state?.products?.getBrandsList?.data);

  if (firstQueryStringKey == "category_id") {
    let CategoryResult = getBreadcrumb(category_id, categories);
    if (CategoryResult?.targetCategoryObj?.sub_category?.length !== 0) {
      return <>{<SubCategory />}</>;
    } else {
      return <>{<Listing {...CategoryResult} />}</>;
    }
  } else if (firstQueryStringKey == "brand_id") {
    const BrandResult = brands?.find((child) => child?.ID == brand_id);
    return <>{<BrandCategory {...BrandResult} />}</>;
  }
};

const Listing = (result) => {
  const language = useLanguage();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryName = "dunno";
  const productName = result?.targetCategoryObj?.category_name;
  const categoryId = params.get("category_id");
  const pageName = params.get("pagename");
  const { translate } = useDictionary();

  const [filterBrand, setFilterBrand] = useState("");
  // console.log("categoryId", result);

  const changeText = (text) => {
    let string = text;
    const filtered = string?.replace("/", " & ");
    return filtered;
  };
  const item = [
    {
      name: "product",
    },
    {
      name: categoryName || "",
    },
    {
      name: changeText(productName),
    },
  ];

  // filter price range for ui
  const uiPriceRange = [
    translate("all"),
    translate("1-50000"),
    translate("50001-100000"),
    translate("100001-200000"),
    translate("200001-300000"),
    translate("300001-400000"),
  ];

  // filter price range
  const realPriceRange = [
    { min_price: "", max_price: "" },
    { min_price: 1, max_price: 50000 },
    { min_price: 50001, max_price: 100000 },
    { min_price: 100001, max_price: 200000 },
    { min_price: 200001, max_price: 300000 },
    { min_price: 300001, max_price: 400000 },
  ];
  const [sort, setSort] = useState("PRICEFROMLOW");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [brandId, setBrandId] = useState("");
  const handleChange = (event) => {
    setSort(event.target.value);
  };
  const [showModal, setShowModal] = useState(false);

  const products = useSelector(
    (state) => state?.products?.getAllProducts?.data
  );
  const brandLists = useSelector(
    (state) => state?.products?.getBrandsList?.data
  );

  // for get products list with data
  const datas = {
    page_number: page,
    per_page: perPage,
    category_id: categoryId || "",
    brand_id: brandId,
    lang: language,
    sort_by: sort,
    min_price: realPriceRange[filterBrand]?.min_price || "",
    max_price: realPriceRange[filterBrand]?.max_price || "",
  };

  // call Product list api
  useEffect(() => {
    callApi("products/getAllProducts")
      .withParam(datas)
      .loadingGroup("p")
      .executeDispatch();
    callApi("products/getBrandsList")
      .withParam({ lang: language })
      .loadingGroup("p")
      .executeDispatch();
  }, [language, categoryId, brandId, sort, filterBrand]);

  useEffect(() => {
    callApi("products/getAllProducts")
      .withParam(datas)
      .loadingGroup("p")
      .executeDispatch();
  }, [page]);

  const Modal = () => {
    return (
      <>
        <div
          style={{ background: "rgba(0,0,0,0.4)" }}
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto container">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <FilterProduct
                item={brandLists}
                range={uiPriceRange}
                realRange={realPriceRange}
                type="product"
                filterBrand={filterBrand}
                setFilterBrand={setFilterBrand}
                setBrandId={setBrandId}
                brandId={brandId}
              />
              {/*footer*/}
              <div className="flex items-center justify-end px-5 md:px-10 pt-5 pb-6 gap-3 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="w-[150px] h-[40px] rounded-[5px] bg-black-1 hover:shadow-sm hover:text-red-4 cursor-pointer text-lg font-muli text-black-4 flex items-center justify-center"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {translate("close", "Close")}
                </button>
                <button
                  className="w-[150px] h-[40px] rounded-[5px] bg-green hover:shadow-sm cursor-pointer text-lg font-muli text-white flex items-center justify-center"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  {translate("save", "Save")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  };

  return (
    <>
      <LoadingComponent loadingGroup={"p"}>
        <Breadcrumb item={result?.path} title="product" />
        <div className="container-con sub-category bg-black-0">
          <div
            className={
              window.innerWidth > 991 ? styled.subContainer : "container"
            }
          >
            <div className="flex lg:flex-row gap-y-[40px]">
              {window.innerWidth > 991 ? (
                <div className="basis-2/5 xl:basis-1/4 mr-[20px]">
                  <FilterProduct
                    item={brandLists}
                    range={uiPriceRange}
                    realRange={realPriceRange}
                    type="product"
                    filterBrand={filterBrand}
                    setFilterBrand={setFilterBrand}
                    setBrandId={setBrandId}
                    brandId={brandId}
                  />
                </div>
              ) : (
                showModal && <Modal />
              )}

              <div
                className={
                  window.innerWidth > 991 ? "basis-3/5 xl:basis-3/4" : "w-full"
                }
              >
                <div className="flex flex-wrap items-center justify-between lg:ml-[20px] gap-y-3">
                  <p className=" text-[25px] font-righteous">
                    {changeText(productName)}
                  </p>
                  <div
                    className={
                      window.innerWidth > 460
                        ? "flex items-center gap-2 md:gap-4"
                        : "w-full flex items-center gap-2 md:gap-4"
                    }
                  >
                    <p className="title2">{translate("sort-by", "Sort By")}</p>
                    <FormControl
                      sx={{
                        minWidth:
                          window.innerWidth > 600
                            ? 200
                            : window.innerWidth < 461
                            ? "calc(100% - 105px)"
                            : 160,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "red",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          height: window.innerWidth > 460 ? 50 : 45,
                          top: window.innerWidth > 460 ? -4 : -1,
                        },
                        "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                          {
                            fontFamily: "MuliRegular",
                          },
                        "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                          {
                            fontFamily: "MuliRegular",
                          },
                      }}
                    >
                      <Select value={sort} onChange={handleChange}>
                        <MenuItem value={"PRICEFROMLOW"} className="body">
                          {translate("low-to-hight", "Price Low to High")}
                        </MenuItem>
                        <MenuItem value={"PRICEFROMHIGH"} className="body">
                          {translate("hight-to-low", "Price High to Low")}
                        </MenuItem>
                        <MenuItem value={"NEWTOOLD"} className="body">
                          {translate("new-to-old", "New to Old")}
                        </MenuItem>
                        <MenuItem value={"OLDTONEW"} className="body">
                          {translate("old-to-new", "Old to New")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {window.innerWidth < 992 && (
                      <button
                        className="w-[30px] h-[30px] md:w-[50px] md:h-[50px] flex items-center justify-center cursor-pointer"
                        type="button"
                        onClick={() => setShowModal(true)}
                      >
                        <BiFilterAlt
                          size={window.innerWidth > 768 ? 30 : 24}
                          color="#555555"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {window.innerWidth < 992 && (
                  <div className="flex flex-col gap-1">
                    {brandId ? (
                      <>
                        <p className="title1">
                          {translate("filter-by", "Filter By")}
                        </p>
                        <p className="font-muli text-[14px] text-black-3">
                          {translate("brand-name", "Brand Name")} :{" "}
                          {brandLists?.filter((b) => b.ID === brandId)[0]?.name}
                        </p>
                      </>
                    ) : null}

                    {pageName != "brand" && (
                      <p className="font-muli text-[14px] text-black-3">
                        {translate("price-range", "Price Range")} :{" "}
                        {uiPriceRange[filterBrand] || "All"}
                      </p>
                    )}
                  </div>
                )}
                {products?.total_products > 0 ? (
                  <div className="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 py-[30px] lg:ml-[20px]">
                    {products?.product_list?.map((data, index) => (
                      <Link
                        // to={`/product/${productName}?product-name=${data.name}`}
                        key={index}
                      >
                        <ProductCard item={data} />
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className=" min-h-[50vh] lg:min-h-[30vh] flex justify-center items-center">
                    <span className="text-black-3 text-center">
                      <img
                        // className="lg:mt-[150px]"
                        className="mx-auto"
                        src={Icons.emptyCartIcon}
                        alt=""
                      />
                      <span>{translate("no-product", "Empty Products")}</span>
                    </span>
                  </div>
                )}
                {products?.total_products >= 12 && (
                  <div className="lg:ml-[20px]">
                    {/* <Pagination
                      value={page}
                      onChange={setPage}
                      total={Math.ceil(products?.total_products / 12)}
                    /> */}
                    <Pagination
                      page={page}
                      onChange={(event, value) => {
                        window.scrollTo({ top: 0, left: 0 })
                        setTimeout(() => {
                          setPage(value)
                        }, 700);
                      }}
                      count={Math.ceil(products?.total_products / 12)}
                      variant="outlined"
                      shape="rounded"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingComponent>
    </>
  );
};
export default ProductListing;
