import React from "react";
import styled from "./auth_wrapper.module.css";
import Images from "../../../assets/images/index";
import Button from "../button/Button";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import useDictionary from "../../../hooks/lang/useDictionary";

// type may be ['login','signup','verify','forgot','change']

const AuthWrapper = ({ children, backBtnLink = -1, type = "" }) => {
  const navigate = useNavigate();
  const { translate } = useDictionary();
  return (
    <div className={styled.main}>
      <div className=" lg:grid grid-cols-1 lg:grid-cols-2 pt-[20%] sm:pt-0">
        <div className="hidden lg:block">
          <img src={Images.DogImg} alt="" className=" h-screen" />
        </div>
        <div className="relative py-[10%] 2xl:py-0">{children}</div>
        <Button
          className={
            "py-2 px-5 bg-white w-fit rounded-[5px] absolute top-[3%] sm:top-[5%] left-[5%] flex items-center gap-2 text-black-3"
          }
          onClick={() => navigate(backBtnLink)}
        >
          <BsChevronLeft />{" "}
          {type === "verify"
            ? translate("back-to-home", "Back to Home")
            : translate("back", "Back")}
        </Button>
      </div>
    </div>
  );
};

export default AuthWrapper;
