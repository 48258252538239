import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE_1 = {
  lang: "",
};
// export const yourSlice = createSlice({
//   name: 'yourSlice',
//   initialState: INITIAL_STATE,
//   reducers: {
//     setLoading: (state, action) => {
//       return state
//     },
//   },
// });

export const languageSlice = createSlice({
  name: "languageSlice",
  initialState: INITIAL_STATE_1,
  reducers: {
    changeLanguage: (state, action) => {
      state.lang = action.payload;
    },
  },
});

const INITIAL_STATE_2 = {
  category_data: {},
};

export const categorySlice = createSlice({
  name: "categorySlice",
  initialState: INITIAL_STATE_2,
  reducers: {
    storeCategory: (state, action) => {
      state.category_data = action.payload;
    },
  },
});

export const wishListSlice = createSlice({
  name: "wishListSlice",
  initialState: {
    data: null,
  },
  reducers: {
    storeWishList: (state, action) => {
      state.data = action.payload;
    },
    editWishStatus: (state, action) => {
      state.data = state.data?.map((item) => item.id);
    },
  },
});

export const orderIdSlice = createSlice({
  name: "orderIdSlice",
  initialState: {
    persistSlice: {
      addToCart: {},
    },
  },
  reducers: {
    removeOrderId: (state, action) => {
      state.persistSlice.addToCart = {};
    },
  },
});

// product detail reducer
const productInitialState = {
  quantity: "",
  size: "",
  color: "",
  uom: "",
  prices: "",
};
export const productDetailSlice = createSlice({
  name: "productDetailSlice",
  initialState: productInitialState,
  reducers: {
    addProductDetail: (state, action) => {
      // console.log(action.payload);
      const { qty, size, color, uom, prices } = action.payload;
      state.quantity = qty;
      state.size = size;
      state.color = color;
      state.uom = uom;
      state.prices = prices;
    },
  },
});

const ProfilePopupState = {
  showMbProfile: false,
};

export const ProfilePopupSlice = createSlice({
  name: "profilePopup",
  initialState: ProfilePopupState,
  reducers: {
    setShowMbProfile: (state, action) => {
      state.showMbProfile = action.payload;
    },
  },
});

export default {
  languageSlice,
  categorySlice,
  orderIdSlice,
  productDetailSlice,
  ProfilePopupSlice,
  wishListSlice,
};
